import { useState, useEffect } from "react";
import { useFormik } from "formik";
import { toast, ToastContainer } from "react-toastify";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import "react-toastify/dist/ReactToastify.css";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import UserService from "../../api-config/user";
import UtilsService from "../../api-config/utils";
import UserProfile from "../User Profile/UserProfile";
import "./MyPoints.css";
import CouponService from "../../api-config/myCoupon";

const MyPoints = () => {
  const [coupon, setCoupon] = useState();

  useEffect(() => {
    let data = JSON.parse(localStorage.getItem("user_info"));
    getCoupon(data)
  }, []);


  const getCoupon = async (data) => {
    await CouponService.getCredit({ id: data.id }).then((res) => {
      setCoupon(res?.data?.data)
    }).catch((err) => {
      console.log("err >>>", err);
    })
  }

  function copyToClipboard(text) {
    navigator.clipboard.writeText(text).then(() => {
      alert(`Coupon code ${text} copied to clipboard!`);
    }).catch(err => {
      console.error('Could not copy text: ', err);
    });
  }




  return (
    <div className="account-details">
      <div className="my-account">
        <div className="container">
          <div className="post-content row">
            <div className="col-xl-3">
              <UserProfile />
            </div>

            <div className="MyAccount-content col-12 col-xl-9">
              <div className="hero-header">
                <div className="regular-title">
                  <div className="container hero-header-container">
                    <div className="row">
                      <header className="col-lg-8 products-header">
                        <h1 className="page-title" itemProp="name headline">
                          My Points
                        </h1>
                      </header>
                    </div>
                  </div>
                </div>
              </div>

              <div className="coupon-table">
                <div className="coupon-table-header">
                  <div className="row">
                    <div className="col-4">
                      <span className="text-uppercase text-dark">Credit Point</span>
                    </div>
                    <div className="col-4">
                      <span className="text-uppercase text-dark">{coupon?.credit_point}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      {/* <ToastContainer/> */}
    </div>
  );
};
export default MyPoints;
