import React, { useEffect, useState } from "react";
import { NavLink, Route, Routes } from "react-router-dom";
import Footer from "../../components/Footer";
import UserProfile from "../User Profile/UserProfile";
// import "../User Profile/UserProfile.scss";

const Dashboard = () => {
  return (
    <>
      <div>
        <div className="my-account">
          <div className="container">
            <div className="post-content row">
              <div className="MyAccount-content col-12 col-md-12">
                <div className="hero-header">
                  <div className="regular-title">
                    <div className="container hero-header-container">
                      <div className="row">
                        <header className="col-lg-8 products-header">
                          <h1 className="page-title" itemProp="name headline">
                            {/* DASHBOARD */}
                            {/* USER LOGIN DETAILS */}
                          </h1>
                        </header>
                      </div>
                      <div className="row">
                        <div className="col-12 col-lg-5 col-md-8">
                          <UserProfile />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
