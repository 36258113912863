import Wishlist from '../Modules/wishlist/Wishlist';
import { connect } from 'react-redux'
import { addToCart, removeToWishlist } from '../services/actions/actions'

const mapStateToProps = state => ({
    wishlistCount: state.wishlistItems
})
const mapDispatchToProps = dispatch => ({
    addToCartHandler: data => dispatch(addToCart(data)),
    removeToWishlistHandler: data => dispatch(removeToWishlist(data))
})
export default connect(mapStateToProps, mapDispatchToProps)(Wishlist)