import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { FaTimes } from "react-icons/fa";
import "./UserProfile.css";
import { IoIosLogOut } from "react-icons/io";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const UserProfile = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const navigate = useNavigate();

  const userInfo = JSON.parse(localStorage.getItem("user_info"));
  const [authKey, setAuthKey] = useState(false);

  useEffect(() => {
    const auth_key = localStorage.getItem("auth_key");
    setAuthKey(!!auth_key);
  }, []);

  const logout = () => {
    localStorage.removeItem("auth_key");
    localStorage.removeItem("user_info");
    // Redirect to login page
    window.location.href = "/home";
  };
  return (
    <div className="container">
      <div className="post-content">
        <nav className="MyAccount-navigation">
          <div className="et-MyAccount-user">
            <div className="et-MyAccount-user-info">
              <span className="et-username">
                <b style={{ color: "#27434f" }}>
                  Hello{" "}
                  <strong>
                    <b style={{ color: "#27434f" }}>
                      {userInfo?.first_name} {userInfo?.last_name}
                    </b>
                  </strong>
                </b>
              </span>
            </div>
          </div>

          <div className="dashboard-list-menu">
            <ul>
              <li className="MyAccount-navigation-link">
                <NavLink to="/order">Orders</NavLink>
              </li>
              <li className="MyAccount-navigation-link">
                <NavLink to="/user/account-details">Account Details</NavLink>
              </li>
              <li className="MyAccount-navigation-link">
                <NavLink to="/my-points">My Points</NavLink>
              </li>
              <li className="MyAccount-navigation-link">
                <NavLink to="/change-password">Change Password</NavLink>
              </li>
              <li className="MyAccount-navigation-link">
                <NavLink to="/address">Addresses</NavLink>
              </li>
              <li className="MyAccount-navigation-link">
                <span
                  onClick={() => {
                    if (show) {
                      logout();
                    } else {
                      handleShow();
                    }
                  }}
                >
                  Logout
                </span>
              </li>
            </ul>

            <Modal
              className="modallogin"
              show={show}
              onHide={handleClose}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header>
                <Modal.Title>Logout!</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <IoIosLogOut />
                <br />
                Are you sure <br />
                you want to Logout ?
              </Modal.Body>
              <Modal.Footer>
                <Button
                  className="btn button-modal"
                  onClick={() => {
                    handleClose(); // Close the modal
                    logout(); // Perform logout
                    localStorage.removeItem("auth_key");
                    localStorage.removeItem("user_info");
                  }}
                >
                  Yes
                </Button>
                <Button
                  className="btn button-modal-white"
                  onClick={handleClose}
                >
                  No
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default UserProfile;
