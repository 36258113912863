import Header from "../components/Header";
import { connect } from "react-redux";
import { addToCart, addToWishlist } from "../services/actions/actions";

const mapStateToProps = (state) => ({
  data: state?.cartItems,
  wishlistCount: state.wishlistItems,
});
const mapDispatchToProps = (dispatch) => ({
  addToCartHandler: (data) => dispatch(addToCart(data)),
  addToWishlistHandler: (data) => dispatch(addToWishlist(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Header);
