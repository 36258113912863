import { ADD_TO_CART, REMOVE_TO_CART } from "../constants";

const initialState = {
    totalCartData: 0,
}
export default function cartItems(state = [], action) {
    switch (action.type) {
        case ADD_TO_CART:
            console.log(action)
            return (action.data?.totalCartItems)
        case REMOVE_TO_CART:
            return (action?.data?.totalCartItems)
        default:
            return state;
    }
}
