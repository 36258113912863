import http from "../environment";

const getAll = () => {
  return http.get(`/product/get?is_show_on_website=true`);
};
const getAllPagination = (current_page) => {
  return http.get(
    `/product/get?is_show_on_website=true&current_page=${current_page}&page_size=${18}`
  );
};

const getTrendingData = () => {
  return http.get(
    `/product/get?is_show_on_website=true&is_trending_product=true`
  );
};

const getById = (id) => {
  return http.get(`/product/get?id=${id}&is_show_on_website=true`);
};

const getByCategoryId = (id) => {
  return http.get(
    `/product/get?is_show_on_website=true&category_id=${JSON.stringify(id)}`
  );
};

const getBySubCategoryId = (id, current_page) => {
  return http.get(
    `/product/get?is_show_on_website=true&sub_category_id=${[
      id,
    ]}&current_page=${current_page}&page_size=${18}`
  );
};

const getByCollectionId = (id, current_page) => {
  return http.get(
    `/product/get?is_show_on_website=true&collection_id=${JSON.stringify(
      id
    )}&current_page=${current_page}&page_size=${18}`
  );
};

const getByBrandId = (id, current_page) => {
  return http.get(
    `/product/get?is_show_on_website=true&brand_id=${id}&current_page=${current_page}&page_size=${18}`
  );
};

const getByColorId = (id) => {
  return http.get(`/product/get?is_show_on_website=true&color_id=${id}`);
};

const getBySizeId = (id) => {
  return http.get(`/product/get?is_show_on_website=true&size_id=${id}`);
};

// get color of products using size_id and product_id
const getColorBySize = (product_id, size_id) => {
  return http.get(`/product/get?id=${product_id}&size_id=${size_id}`);
};

// get size of products using color_id and product_id
const getSizeByColor = (product_id, color_id) => {
  return http.get(`/product/get?id=${product_id}&color_id=${color_id}`);
};

// get variant using product's id and variant's id
const getVariant = (productId, variantId) => {
  return http.get(
    `/product/get?id=${productId}&product_variant_id=${variantId}`
  );
};

const getFilteredData = (
  categoryIdArray,
  colorIdArray,
  sizeIdArray,
  collectionIdArray,
  brandArray
) => {
  return http.get(
    `/product/get?is_show_on_website=true&sub_category_id=${JSON.stringify(
      categoryIdArray
    )}&color_id=${JSON.stringify(colorIdArray)}&size_id=${JSON.stringify(
      sizeIdArray
    )}&collection_id=${JSON.stringify(
      collectionIdArray
    )}&brand_id=${JSON.stringify(brandArray)}`
  );
};

const getByKeyword = (keyword) => {
  return http.get(`product/search/get?search=${keyword}`);
};

const getByPriceFilter = (value) => {
  return http.get(
    `/product/get?is_show_on_website=true&sort_price_wise=${value}`
  );
};
const getByAlphabetFilter = (value) => {
  return http.get(
    `/product/get?is_show_on_website=true&sort_alphabet_wise=${value}`
  );
};

const getByNewArrivalFilter = (value) => {
  return http.get(
    `/product/get?is_show_on_website=true&sort=${value}`
  );
};

const ProductService = {
  getAll,
  getById,
  getByCategoryId,
  getByCollectionId,
  getByBrandId,
  getByColorId,
  getBySizeId,
  getColorBySize,
  getSizeByColor,
  getVariant,
  getFilteredData,
  getAllPagination,
  getBySubCategoryId,
  getTrendingData,
  getByKeyword,
  getByPriceFilter,
  getByAlphabetFilter,
  getByNewArrivalFilter
};

export default ProductService;
