import React from "react";
import { useNavigate } from "react-router-dom";
import "./brand.css";
import logo1 from "../../assets/images/Sugandh Gupta Logo Fashion Designer.jpg";
import handcrafted from "../../assets/images/handcrafted.jpeg";
import speciallyForU from "../../assets/images/specially for u.JPG";
import ourVision from "../../assets/images/our-vision.jpeg";
import promises from "../../assets/images/images.jpeg";
import { Helmet } from "react-helmet";
import logo from "../../assets/images/logo.png";

const SugandhGupta = () => {
  const navigate = useNavigate();

  const shopNow = () => {
    navigate(`/the-shop/${"Sugandh Gupta"}`, { state: { brand_id: 2 } });
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Sugandh Gupta</title>
        <meta
          name="description"
          content="Sugandh Gupta – A couture brand, this label represents rich and finest heritage of Indian culture, through craftsmanship giving elegant and rich silhouettes like lehengas, suits and bridal wear."
        />
        <meta
          name="keywords"
          content="Sugandh Gupta in shop Bhavnagar, Sugandh Gupta "
        />
        <link rel="icon" type="image/png" href={logo} />
      </Helmet>
      <div className="brands sugandh_gupta">
        <div className="container">
          <div className="row">
            <div className="brand-content">
              <div className="row">
                <div className="col-12 col-lg-3">
                  <img src={logo1} className="brand-logo" alt="Sugandh Gupta" />
                </div>
                <div className="col-12 col-lg-9">
                  <h1>Sugandh Gupta</h1>
                  <p>
                    Sugandh Gupta – A couture brand, this label represents rich
                    and finest heritage of Indian culture, through craftsmanship
                    giving elegant and rich silhouettes like lehengas, suits and
                    bridal wear. Each design here, is well thoughtful, ethnic,
                    resembling to great indian heritage and giving to the taste
                    of rich textile and craftsmanship of India.
                  </p>
                  <button
                    type="button"
                    className="btn float-end mt-5"
                    onClick={() => shopNow()}
                  >
                    Shop Sugandh Gupta
                  </button>
                </div>
              </div>
            </div>

            <div className="crafted_specially_for_u">
              <div className="row">
                <div className="col-12 col-md-7">
                  <h3 className="mb-3 mb-sm-3">CRAFTED SPECIALLY FOR YOU </h3>
                  <img
                    src={speciallyForU}
                    className="d-block d-lg-none m-auto mb-3"
                  />

                  <p>
                    SG offers a full custom order service that is designed
                    according to your body shape and is one of a kind just like
                    you are. We believe that everybody shape should be
                    celebrated in its own unique sense, since not every customer
                    fits into the mainstream retail clothing chain. We value our
                    clients and their preferences and that is reflected in the
                    heart and soul of WOS. We understand Indian women and their
                    unique need to look their best in every dress the adorn.
                    Personified with their style and expression, a women’s
                    imagination and creativity knows no bounds. Very diligently
                    we convert your imagination into reality through our bespoke
                    designing and impeccable fit crafted in luxury fabrics and
                    neat craftsmanship, made to your measurements and delivered
                    right in time for you to dazzle the world every single time.
                  </p>
                  {/* <p className="mt-5">#NoWastePolicy #UpcycledProducts</p> */}
                </div>
                <div className="col-12 col-md-5 d-flex">
                  <img src={speciallyForU} className="d-none d-lg-block" />
                </div>
              </div>
            </div>

            <div className="handcrafted">
              <div className="row">
                <div className="col-12 col-md-4">
                  <img
                    src={handcrafted}
                    className="img-fluid d-none d-lg-block"
                    alt="handcrafted"
                  />
                </div>
                <div className="col-12 col-md-8">
                  <h3 className="mb-3">HANDCRAFTED</h3>
                  <img
                    src={handcrafted}
                    className="img-fluid d-block d-lg-none mb-3"
                    alt="handcrafted"
                  />

                  <p>
                    Every single SG piece is uniquely designed, given a personal
                    touch with help of hand embroidery and embellishments done
                    by local artisans with utmost creativity and quality.
                  </p>
                </div>
              </div>
            </div>

            <div className="our-promise-to-u">
              <div className="row">
                <div className="col-12 col-md-7">
                  <h3>OUR PROMISE TO YOU </h3>
                  <p>
                    This is done by creating styles with curiosity, credibility,
                    ambition, and daring to play with shapes and colors. The
                    collections are carefully designed with our main focus: The
                    unique feminine and personal look, Flattering fits,
                    Authenticity, Sustainability.
                  </p>
                </div>
                <div className="col-12 col-md-5">
                  <img src={promises} className="img-fluid" alt="" />
                </div>
              </div>
            </div>

            {/* <div className="our-mission">
      <div className="row">
        <div className="col-5">
          <img src={ourMission} className="img-fluid" alt="our-mission" />
        </div>
        <div className="col-7">
          <h3>Our Mission</h3>
          <p>Our mission is to change the way people shop by educating our growing community about how they are making a positive difference to the world. The future of our planet is vital for all which is why we donate to selected charities with every purchase made in our store, it’s our way of giving back.</p>
        </div>
      </div>
    </div> */}
          </div>
        </div>
      </div>
    </>
  );
};
export default SugandhGupta;
