import "./visit-us.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Accordion from "react-bootstrap/Accordion";

const VisitUs = () => {
  return (
    <div className="address-location">
      <div className="container">
        <div className="row d-none d-xl-block">
          <div className="col-xl-12">
            <Tabs>
              <div className="row">
                <div className="col-xl-4 col-lg-3 col-md-5 col-sm-12">
                  <TabList>
                    <Tab>Flagship Store</Tab>
                    <Tab>Ahmedabad</Tab>
                    <Tab>Surat</Tab>
                    <Tab>Rajkot</Tab>
                    <Tab>Mumbai</Tab>
                    <Tab>Secunderabad</Tab>
                    <Tab>Delhi</Tab>
                    <Tab>Varanasi</Tab>
                  </TabList>
                </div>
                <div
                  className="col-xl-8 col-lg-9 col-md-7 col-sm-12"
                  style={{ background: "#fafafa" }}
                >
                  <div className="back-box">
                    <TabPanel>
                      <div className="col-lg-12">
                        <h2>Rudra House</h2>
                        <p>
                          1st floor, D-60 Kaliyabid, Bhavangar, Gujarat -
                          364002.
                        </p>
                      </div>
                    </TabPanel>
                    <TabPanel>
                      <div className="col-lg-12">
                        <h2>House Of Mohini</h2>
                        <p>
                          Shop no.4, Indraprastha Corporate, Opposite to Shell
                          Petrol Pump, Chinar Bungalows, Prahlad Nagar,
                          Ahmedabad, Gujarat-380015.
                        </p>
                      </div>
                    </TabPanel>
                    <TabPanel>
                      <div className="col-lg-12">
                        <h2>Saanja</h2>
                        <p>
                          Besides Agrawal Vidhya, Vihar School, Canal Rd, Vesu,
                          Surat, Gujarat-395007.
                        </p>
                      </div>
                    </TabPanel>
                    <TabPanel>
                      <div className="col-lg-12">
                        <h2>House of Shivani</h2>
                        <p>
                          Flat, vidhyakunj society, Business Centrum, 07, main
                          road, Amin Marg, opp. Kings Height, Rajkot,
                          Gujarat-360001.
                        </p>
                      </div>
                    </TabPanel>
                    <TabPanel>
                      <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-12">
                          <h2>Mogra</h2>
                          <p>
                            T-3 Palldium, Level 3, Phoenix Palladium, S.B Marg,
                            Lower Parel (w), Mumbai, Maharashtra-400013.
                          </p>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                          <h2>Patchouli</h2>
                          <p>
                            Shop No 15, Grand Galleria, Phoenix mall, Senapati
                            Bapat Marg, Lower Parel, Mumbai, Maharashtra-400013.
                          </p>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                          <h2>Vyoum</h2>
                          <p>
                            G 52, Roop Mangal, 16th Road, Santacruz West, Mumbai
                            - 400054.
                          </p>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel>
                      <div className="col-lg-12">
                        <h2>Taruni</h2>
                        <p>
                          2-1-178, Kandoji Bazar General Bazaar, Kalasiguda,
                          Secunderabad.
                        </p>
                      </div>
                    </TabPanel>
                    <TabPanel>
                      <div className="col-lg-12">
                        <h2>Agashe</h2>
                        <p>
                          Metro Station Ghitorni, 845, Ground & First Floor,
                          Mehrauli-Gurgaon Rd, opposite Metro Pillar - 112, near
                          Ghitorni, Ghitorni, New Delhi, Delhi-110030.
                        </p>
                      </div>
                    </TabPanel>
                    <TabPanel>
                      <div className="col-lg-12">
                        <h2>Gazal Agrawal </h2>
                        <p>
                          D59/105-C, Chandrika Nagar Colony, Sigra, Varanasi,
                          Uttar Pradesh - 221010.
                        </p>
                      </div>
                    </TabPanel>
                  </div>
                </div>
              </div>
            </Tabs>
          </div>
        </div>

        <div className="row d-block d-xl-none">
          <div className="col-xl-12">
            <div className="accordion-visit">
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Flagship Store</Accordion.Header>
                  <Accordion.Body>
                    <div className="col-lg-12">
                      <h2>Rudra House</h2>
                      <p>
                        1st floor, D-60 Kaliyabid, Bhavangar, Gujarat - 364002.
                      </p>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>Ahmedabad</Accordion.Header>
                  <Accordion.Body>
                    {" "}
                    <div className="col-lg-12">
                      <h2>House Of Mohini</h2>
                      <p>
                        Shop no.4, Indraprastha Corporate, Opposite to Shell
                        Petrol Pump, Chinar Bungalows, Prahlad Nagar, Ahmedabad,
                        Gujarat-380015.
                      </p>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>Surat</Accordion.Header>
                  <Accordion.Body>
                    <div className="col-lg-12">
                      <h2>Saanja</h2>
                      <p>
                        Besides Agrawal Vidhya, Vihar School, Canal Rd, Vesu,
                        Surat, Gujarat-395007.
                      </p>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>Rajkot</Accordion.Header>
                  <Accordion.Body>
                    <div className="col-lg-12">
                      <h2>House of Shivani</h2>
                      <p>
                        Flat, vidhyakunj society, Business Centrum, 07, main
                        road, Amin Marg, opp. Kings Height, Rajkot,
                        Gujarat-360001.
                      </p>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>Mumbai</Accordion.Header>
                  <Accordion.Body>
                    <div className="row">
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <h2>Mogra</h2>
                        <p>
                          T-3 Palldium, Level 3, Phoenix Palladium, S.B Marg,
                          Lower Parel (w), Mumbai, Maharashtra-400013.
                        </p>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <h2>Patchouli</h2>
                        <p>
                          Shop No 15, Grand Galleria, Phoenix mall, Senapati
                          Bapat Marg, Lower Parel, Mumbai, Maharashtra-400013.
                        </p>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <h2>Vyoum</h2>
                        <p>
                          G 52, Roop Mangal, 16th Road, Santacruz West, Mumbai -
                          400054.
                        </p>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                  <Accordion.Header>Secunderabad</Accordion.Header>
                  <Accordion.Body>
                    {" "}
                    <div className="col-lg-12">
                      <h2>Taruni</h2>
                      <p>
                        2-1-178, Kandoji Bazar General Bazaar, Kalasiguda,
                        Secunderabad.
                      </p>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                  <Accordion.Header>Delhi</Accordion.Header>
                  <Accordion.Body>
                    {" "}
                    <div className="col-lg-12">
                      <h2>Agashe</h2>
                      <p>
                        Metro Station Ghitorni, 845, Ground & First Floor,
                        Mehrauli-Gurgaon Rd, opposite Metro Pillar - 112, near
                        Ghitorni, Ghitorni, New Delhi, Delhi-110030.
                      </p>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="7">
                  <Accordion.Header>Varanasi</Accordion.Header>
                  <Accordion.Body>
                    <div className="col-lg-12">
                      <h2>Gazal Agrawal </h2>
                      <p>
                        D59/105-C, Chandrika Nagar Colony, Sigra, Varanasi,
                        Uttar Pradesh - 221010.
                      </p>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default VisitUs;
