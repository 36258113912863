import http from "../environment";

const getBlog = (id) => {
    let url = `blog/get`;
    if(id) {
        url += `?id=${id}`;
    }
    return http.get(url);
}

const BlogService = {
    getBlog,
}

export default BlogService;