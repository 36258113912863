import { useState, useEffect } from "react";
import { useFormik } from "formik";
import { toast, ToastContainer } from "react-toastify";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import "react-toastify/dist/ReactToastify.css";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import UserService from "../../api-config/user";
import UtilsService from "../../api-config/utils";
import UserProfile from "../User Profile/UserProfile";
import "./account-details.css";

const AccountDetails = () => {
  const user_info = JSON.parse(localStorage.getItem("user_info"));
  const [country, setCountry] = useState();
  const [province, setProvince] = useState();
  const [city, setCity] = useState();
  const [zipcode, setZipcode] = useState();
  const [togglePassword, setTogglePassword] = useState(false);

  useEffect(() => {
    let data = JSON.parse(localStorage.getItem("user_info"));
    getCountry();
    getProvince(data?.country_id);
    getCity(data?.province_id);
  }, []);

  const getCountry = () => {
    UtilsService.getCountry()
      .then((res) => {
        setCountry(res.data.data);
      })
      .catch((e) => console.log(e));
  };

  const getProvince = (countryId) => {
    UtilsService.getProvince(countryId)
      .then((res) => {
        setProvince(res.data.data);
      })
      .catch((e) => console.log(e));
  };

  const getCity = (provinceId) => {
    UtilsService.getCity(provinceId)
      .then((res) => {
        setCity(res.data.data);
      })
      .catch((e) => console.log(e));
  };

  const getZipcode = (cityId) => {
    UtilsService.getZipcode(cityId)
      .then((res) => {
        setZipcode(res.data.data);
      })
      .catch((e) => console.log(e));
  };

  const formik = useFormik({
    initialValues: {
      id: user_info?.id,
      email_id: user_info?.email_id ?? "",
      password: user_info?.password ?? "",
      is_web_signin: true,
      first_name: user_info?.first_name ?? "",
      last_name: user_info?.last_name ?? "",
      primary_contact_number: user_info?.primary_contact_number ?? "",
      // dob: user_info?.dob ?? "",
      user_role_id: 2,
      address_line_1: user_info?.address_line_1 ?? "",
      address_line_2: user_info?.address_line_2 ?? "",
      country_id: user_info?.country_id ?? "",
      province_id: user_info?.province_id ?? "",
      city_id: user_info?.city_id ?? "",
      zipcode_id: user_info?.zipcode_id ?? "",
      country: user_info?.country ?? "",
      province: user_info?.province ?? "",
      city: user_info?.city ?? "",
      zipcode: user_info?.zipcode ?? "",
    },
    validateOnChange: false,
    onSubmit: (values) => {
      const formData = new FormData();
      formData.append("id", values?.id);
      formData.append("first_name", values.first_name);
      formData.append("last_name", values.last_name);
      formData.append("email_id", values.email_id);
      formData.append("password", values.password);
      // formData.append("dob", values.dob);
      formData.append("primary_contact_number", values.primary_contact_number);
      formData.append("address_line_1", values?.address_line_1);
      formData.append("address_line_2", values?.address_line_2);
      formData.append("country_id", values?.country_id);
      formData.append("province_id", values?.province_id);
      formData.append("city_id", values?.city_id);
      formData.append("zipcode_id", values?.zipcode_id);
      formData.append("country", values?.country);
      formData.append("province", values?.province);
      formData.append("city", values?.city);
      formData.append("zipcode", values?.zipcode);
      formData.append("is_web_signin", values.is_web_signin);
      formData.append("user_role_id", values.user_role_id);
      UserService.updateUserDetails(formData)
        .then((res) => {
          if (res.data.success) {
            localStorage.setItem("user_info", JSON.stringify(res.data.data));
            window.location.reload();
            toast.success(res.data.message);
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((e) => console.log(e));
    },
  });

  const handleOnSelect = (item, name) => {
    if (name === "country") {
      formik.setFieldValue("country_id", item?.id);
      formik.setFieldValue("country", item?.name);
      getProvince(item.id);
    } else if (name === "province") {
      formik.setFieldValue("province_id", item?.id);
      formik.setFieldValue("province", item?.name);
      getCity(item.id);
    } else if (name === "city") {
      formik.setFieldValue("city_id", item?.id);
      formik.setFieldValue("city", item?.name);
      getZipcode(item.id);
    } else if (name === "zipcode") {
      formik.setFieldValue("zipcode_id", item?.id);
      formik.setFieldValue("zipcode", item?.name);
    }
  };

  return (
    <div className="account-details">
      <div className="my-account">
        <div className="container">
          <div className="post-content row">
            <div className="col-xl-3">
              <UserProfile />
            </div>

            <div className="MyAccount-content col-12 col-xl-9">
              <div className="hero-header">
                <div className="regular-title">
                  <div className="container hero-header-container">
                    <div className="row">
                      <header className="col-lg-8 products-header">
                        <h1 className="page-title" itemProp="name headline">
                          ACCOUNT DETAILS
                        </h1>
                      </header>
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-container">
                <form
                  className="login100-form validate-form"
                  onSubmit={formik?.handleSubmit}
                >
                  <div className="row">
                    <div className="col-12 col-md-6 mb-3">
                      <div className="wrap-input100 validate-input">
                        <input
                          type="text"
                          className="input100 has-val"
                          name="first_name"
                          placeholder="First Name"
                          value={formik?.values.first_name}
                          onChange={formik?.handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6 mb-3">
                      <div className="wrap-input100 validate-input">
                        <input
                          type="text"
                          className="input100 has-val"
                          name="last_name"
                          placeholder="Last Name"
                          value={formik?.values.last_name}
                          onChange={formik?.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6 mb-3">
                      <div className="wrap-input100 validate-input">
                        <input
                          type="text"
                          className="input100 has-val"
                          name="primary_contact_number"
                          placeholder="Contact Number"
                          value={formik?.values.primary_contact_number}
                          onChange={formik?.handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6 mb-3">
                      <div className="wrap-input100 validate-input">
                        <input
                          type="email"
                          className="input100 has-val"
                          name="email_id"
                          placeholder="Email"
                          value={formik?.values.email_id}
                          onChange={formik?.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  {/* <div className="row">
                    <div className="col-12 col-md-6 mb-3">
                      <div className="wrap-input100 validate-input">
                        <input
                          type="email"
                          className="input100 has-val"
                          name="email_id"
                          placeholder="Email"
                          value={formik?.values.email_id}
                          onChange={formik?.handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6 mb-3">
                      <div className="wrap-input100 validate-input d-flex">
                        <input
                          type={togglePassword ? "text" : "password"}
                          className="input100 has-val"
                          name="password"
                          placeholder="Password"
                          value={formik?.values.password}
                          onChange={formik?.handleChange}
                        />
                        {togglePassword ? (
                          <FaEyeSlash
                            className="hide-password"
                            onClick={() => setTogglePassword(false)}
                          />
                        ) : (
                          <FaEye
                            className="show-password"
                            onClick={() => setTogglePassword(true)}
                          />
                        )}
                        <p>{togglePassword}</p>
                      </div>
                    </div>
                  </div> */}

                  {/* <div className="row">
                    <div className="col-12 col-md-6 mb-3">
                      <div className="wrap-input100 validate-input">
                        <input
                          type="text"
                          className="input100 has-val"
                          name="address_line_1"
                          placeholder="Address Line 1"
                          value={formik?.values.address_line_1}
                          onChange={formik?.handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6 mb-3">
                      <div className="wrap-input100 validate-input">
                        <input
                          type="text"
                          className="input100 has-val"
                          name="address_line_2"
                          placeholder="Address Line 2"
                          value={formik?.values.address_line_2}
                          onChange={formik?.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6 mb-3">
                      <div className="wrap-input100 validate-input">
                        <select
                          className="form-control"
                          name="country_id"
                          onChange={(event) => [
                            formik?.setFieldValue(
                              "country_id",
                              event.target.value,
                            ),
                            getProvince(event.target.value),
                          ]}
                          value={formik?.values?.country_id}
                        >
                          <option value="">Select Country</option>
                          {country?.map((item, i) => {
                            return (
                              <option value={item?.id}>{item?.name}</option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 mb-3">
                      <div className="wrap-input100 validate-input">
                        <select
                          className="form-control"
                          name="province_id"
                          onChange={(event) => [
                            formik?.setFieldValue(
                              "province_id",
                              event.target.value,
                            ),
                            getCity(event.target.value),
                          ]}
                          value={formik?.values?.province_id}
                        >
                          <option value="" disabled>
                            Select Province
                          </option>
                          {province?.map((item, i) => {
                            return (
                              <option value={item?.id}>{item?.name}</option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6 mb-3">
                      <div className="wrap-input100 validate-input">
                        <select
                          className="form-control"
                          name="city_id"
                          onChange={formik?.handleChange}
                          value={formik?.values?.city_id}
                        >
                          <option value="" disabled>
                            Select City
                          </option>
                          {city?.map((item, i) => {
                            return (
                              <option value={item?.id}>{item?.name}</option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 mb-3">
                      <div className="wrap-input100 validate-input">
                        <input
                          type="text"
                          className="input100 has-val"
                          name="zipcode"
                          placeholder="Zipcode"
                          value={formik?.values.zipcode}
                          onChange={formik?.handleChange}
                        />
                      </div>
                    </div>
                  </div> */}

                  <br />
                  <button type="submit">Save</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <ToastContainer/> */}
    </div>
  );
};
export default AccountDetails;
