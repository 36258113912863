import React, { useEffect, useState } from "react";
import ProductService from "../../api-config/product";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  NavLink,
} from "react-router-dom";
const Product = () => {
  const [productData, setProductData] = useState();

  //   useEffect(() => {
  //     ProductService.getAll().then((res) => {
  //       console.log(res.data);
  //       setProductData(res.data.data);
  //     });
  //   }, [setProductData]);

  return (
    // <section>
    //   <div className="row">
    //     {productData?.map((item) => {
    //       return (
    //         <>
    //           <div className="col"></div>
    //           <div className="col">
    //             <div>
    //               <h2>{item.name}</h2>
    //               <h4>{item.color}</h4>
    //             </div>
    //           </div>
    //         </>
    //       );
    //     })}
    //   </div>
    // </section>
    <div>product</div>
  );
};

export default Product;
