import { useParams } from "react-router-dom";
import "./privacy-policy.css";
import TCMOb from '../../assets/images/t&cMob.jpeg'
import TCTab from '../../assets/images/t&cTab.jpeg'
import TCDesk from '../../assets/images/t&cweb.jpeg'
import RDMOb from '../../assets/images/RNDMob.jpeg'
import RDTab from '../../assets/images/RNDTab.jpeg'
import RDDesk from '../../assets/images/RNDDesk.jpeg'
import React, { useEffect, useState } from "react";
import AboutUs from "../About us/AboutUs";

const PrivacyPolicy = () => {
    const param = window.location.href;
    const last_segment = param.split('/').pop();
    const [tncImage, setTNCImage] = useState('');
    const [dnrImage, setDNRImage] = useState('');
    useEffect(() => {
        const handleResize = () => {
            if (last_segment === 'terms-and-conditions') {
                if (window.innerWidth <= 768) {
                    setTNCImage(TCMOb);
                } else if (window.innerWidth <= 1024) {
                    setTNCImage(TCTab);
                } else {
                    setTNCImage(TCDesk);
                }
            }
            if (last_segment === "delivery-and-returns") {
                if (window.innerWidth <= 768) {
                    setDNRImage(RDMOb);
                } else if (window.innerWidth <= 1024) {
                    setDNRImage(RDTab);
                } else {
                    setDNRImage(RDDesk);
                }
            }
        };

        handleResize(); // Initial call to set the image based on current window size

        window.addEventListener('resize', handleResize); // Listen for window resize events

        return () => {
            window.removeEventListener('resize', handleResize); // Cleanup: remove event listener
        };
    }, []);

    return (
        <div className="privacy-policy container">
            <h2 className="heading">{last_segment === 'privacy-policy' ? "Privacy Policy" : last_segment === 'terms-and-conditions' ? "Terms & Conditions" : last_segment === 'returns' ? "Returns" : last_segment === 'delivery-and-returns' ? "Delivery & Returns" : last_segment === "about-us" ? "" : ""}</h2>

            <br /><br />
            {last_segment === "about-us" && (
                <AboutUs></AboutUs>
            )}
            {last_segment === "delivery-and-returns" && (
                <div className="row">
                    {/* <h4>Delivery Information</h4> */}
                    {/* <p>
                        Sugandh.co will make a maximum of two attempts to deliver your order. In
                        case the customer is not reachable or does not accept delivery of
                        products in these attempts, Sugandh.co reserves the right to cancel the
                        order(s) at its discretion.
                    </p>
                    <p>
                        An estimated delivery time is displayed on the Order Summary page. On
                        placing your order, you will receive an email containing a summary of the
                        order and also the estimated delivery time to your location.
                    </p>
                    <p>Sometimes, delivery may take longer due to</p>
                    <ul>
                        <li>bad weather</li>
                        <li>flight delays</li>
                        <li>political disruptions</li>
                        <li>other unforeseen circumstances</li>
                    </ul>
                    <p>
                        In such cases, we will proactively reach out to you. Please check your
                        emails regularly for such updates.
                    </p>
                    <p>
                        The Buyer agrees that the title to the goods is transferred to the buyer
                        at the time of placing the Order and dispatch confirmation thereof
                        irrespective of Shipping of Goods and/or COD which are to be done in due
                        course being a separate chargeable facilities.
                    </p> */}
                    {/* <h6>Returns &amp; Exchange:</h6>
                    <bf></bf>
                    <p>
                        It is our endeavour to provide you with the best possible customer service and hence we permit returns and exchange as long as the product(s) are unused, unwashed, defective/damaged, and are returned in saleable condition with all the original packaging, tags, invoices.
                    </p>
                    <p>
                        You are required to inform us within 7 days of receiving the package by contacting our customer care team via WhatsApp with your order no. and product(s) you wish to return, else the goods will be considered sold and no further requests will be entertained. We will process your request within 2 business days after reviewing the request.
                    </p>
                    <p>
                        Refund will be issued in the original mode of payment in case of a prepaid order, or will be done via NEFT in case of a cash on delivery order.
                    </p> */}
                    <img src={dnrImage} className="img-fluid mask-img" />
                </div>
            )}
            {last_segment === "terms-and-conditions" && (
                <>
                    <div className="row">
                        <img src={tncImage} className="img-fluid mask-img" />
                    </div>
                </>
            )}
            {/* <div>
                <h4>Services Offered: </h4>
                <p>Sugandh.co  provides a number of Internet-based services through the Web Site (all such services, collectively, the "Service"). One such service enables users to purchase original merchandise such as clothing and accessories. (collectively, "Products"). Upon placing an order, Sugandh.co shall ship the product to you and be entitled to its payment for the Services. </p>
            </div>
            {last_segment === "returns" && (
                <div>
                    <h4>No Compensation Policy: </h4>
                    <p>If the quality of any products, services, information, or other material purchased or obtained by you through the Website did not meet your expectations, no compensation will be given as per the Return and Exchange policy. The customer cannot request compensation for any reason, including but not limited to: any variation in the finish or appearance of the final product; any delay in delivery caused by unforeseen circumstances; any special, incidental, indirect or consequential damage of any kind caused by a product; missed opportunities to avail certain discounts; any issue with our Delivery partners or their personnel; any voucher which got deactivated due to the end of expiry date; unavailability of items which the customer tried to purchase etc. Unreasonable and unlawful requests for compensation will be treated as loss to business cases.  World Of Sugandh  holds the rights to cancel orders and block accounts of such customers without any prior notice.</p>
                </div>
            )} */}
            {/* <div>
                <h4>Exactness Not Guaranteed: </h4>
                <p>Sugandh.co  hereby disclaims any guarantees of exactness as to the finish and appearance of the final Product as ordered by the user, as quality of imagery cannot be taken a final representation. The quality of any products, Services, information, or other material purchased or obtained by you through the Website will be as per the company standards. Alterations to certain aspects of your order such as the brand, size, colour etc. may be required due to limitations caused by availability of product difference in size charts. </p>
            </div>
            <br />
            <div>
                <h4>Links To Third Party Sites: </h4>
                <p>The Website may contain links to other websites ("Linked Sites"). The Linked Sites are not under the control of Sugandh.co or the Website and Sugandh.co  is not responsible for the contents of any Linked Site, including without limitation any link contained in a Linked Site, or any changes or updates to a Linked Site. Sugandh.co is not responsible for any form of transmission, whatsoever, received by you from any Linked Site. </p>
            </div>
            <br />
            <div>
                <h4>Pricing: </h4>
                <p>Prices for products are described on our Website and are incorporated into these Terms by reference. Prices are in Indian rupees for India market and in US dollars for all international markets. Prices, products and Services may change at the discretion of Ritukumar.com. In accordance with brand guidelines, prices might change when the stocks are replenished. Prices may vary for different geographies and this is at the discretion of the brand. </p>
            </div>
            <br />
            <div>
                <h4>Shipping: </h4>
                <p>Title and risk of loss for all products ordered by you shall pass on to you upon Sugandh.co  shipment to the shipping carrier. </p>
            </div><br />
            <div>
                <h4>Delivery: </h4>
                <p>Sugandh.co will make a maximum of two attempts to deliver your order. In case the customer is not reachable or does not accept delivery of products in these attempts Sugandh.co reserves the right to cancel the order(s) at its discretion.  </p>
                <p>An estimated delivery time is displayed on the Order Summary page. On placing your order, you will receive an email containing a summary of the order and also the estimated delivery time to your location. </p>
                <p>Sometimes, delivery may take longer due to </p>
                <ul>
                    <li>bad weather </li>
                    <li>flight delays </li>
                    <li>political disruptions </li>
                    <li>other unforeseen circumstances </li>
                </ul>
                <p>In such cases, we will proactively reach out to you. Please check your emails regularly for such updates.</p>
                <p>The Buyer agrees that the title to the goods is transferred to the buyer at the time of placing the Order and dispatch confirmation thereof irrespective of Shipping of Goods and/or COD which are to be done in due course being a separate chargeable facilities </p>
            </div><br /> */}

            {last_segment === "privacy-policy" ? (
                <div>
                    {/* <h4>PRIVACY POLICY :</h4> */}
                    <p>
                        The User hereby consents, expresses and agrees that he has read and fully
                        understands the Privacy Policy of Sugandh.co. The user further consents
                        that the terms and contents of such Privacy Policy are acceptable to him.
                    </p>
                </div>
            ) : null}
            {last_segment === "contacts" && (
                <div>
                    <h4>CONTACTS :  </h4>
                    <h6>Email : <a href="mailto:worldofsugandh@gmail.com" target="_blank" rel="noreferrer">worldofsugandh@gmail.com</a>  </h6>
                </div>
            )}
        </div >
    )
}
export default PrivacyPolicy;