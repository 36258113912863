/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { FaRegHeart } from "react-icons/fa";
import { FaHeart } from "react-icons/fa";

import ProductService from "../api-config/product";
import CartService from "../api-config/cart";
import "../css/product_info.css";
import "../css/style.css";
import { Navigation, FreeMode, Thumbs } from "swiper/modules";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "swiper/css/free-mode";
import "swiper/css/scrollbar";
import WishlistService from "../api-config/wishlist";
import { Helmet } from "react-helmet";
import logo from "../assets/images/logo.png";
import Notification from "./Notification";

const ProductInfo = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [id, setId] = useState();
  const [product, setProductDetails] = useState();
  const [relatedProduct, setRelatedProduct] = useState();
  const [productCategory, setProductCategory] = useState();
  const [imageList, setImageList] = useState();
  const [colorList, setColorList] = useState();
  const [recentColor, setColor] = useState(null);
  const [recentSize, setSize] = useState(null);
  const [sizeList, setSizeList] = useState();
  const [amount, setAmount] = useState(1);
  const [currentVariant, setVariant] = useState(0);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [message, setMessage] = useState();
  const [loading, setLoading] = useState(true);
  const [wishlistData, setWishlistData] = useState();
  const auth_key = JSON.parse(localStorage.getItem("auth_key"));
  const userData = JSON.parse(localStorage.getItem("user_info"));

  const [notification, setNotification] = useState([]);

  const [showNotification, setShowNotification] = useState(false);

  const handleCloseNotification = () => {
    setShowNotification(false);
  };

  const getProduct = (id) => {
    if (props?.isModal) {
      ProductService.getById(id)
        .then((res) => {
          console.log(res);
          setProductDetails(res.data.data[0]);
          setImageList(res.data.data[0]?.ProductVariants[0]?.image);
          setVariant(res.data.data[0]?.ProductVariants[0]);
          uniqueColor(res.data.data[0]);
          uniqueSize(res.data.data[0]);
          setProductCategory(res.data.data[0]?.sub_category_id);
        })
        .catch((e) => console.log(e));
    } else {
      ProductService.getById(location?.state?.id)
        .then((res) => {
          console.log(res);
          setProductDetails(res.data.data[0]);
          setImageList(res.data.data[0]?.ProductVariants[0]?.image);
          setVariant(res.data.data[0]?.ProductVariants[0]);
          uniqueColor(res.data.data[0]);
          uniqueSize(res.data.data[0]);
          setProductCategory(res.data.data[0]?.sub_category_id);
        })
        .catch((e) => console.log(e));
    }
  };

  // const fetchApiData = async (ProductService) => {
  //   try {
  //     const res = fetch(ProductService);
  //   } catch (error) {}
  // };
  // useEffect(() => {
  //   fetchApiData(ProductService);
  // }, []);

  // Get Related Products
  // @params productCategory id
  useEffect(() => {
    setId(props?.id);
    getProduct(props?.id);
    if (productCategory) {
      ProductService.getBySubCategoryId(productCategory, 1, 5).then((res) => {
        setRelatedProduct(res.data.data);

        setTimeout(() => {
          setLoading(false);
        }, 800);
      });
    }
  }, [
    location?.state?.id,
    props.id,
    props?.isModal,
    setProductDetails,
    setColorList,
    setColor,
    setSize,
    productCategory,
    setRelatedProduct,
  ]);

  useEffect(() => {
    if (productCategory) {
      ProductService.getBySubCategoryId(productCategory, 1, 5).then((res) => {
        setRelatedProduct(res.data.data);
      });
    }
  }, [productCategory, setRelatedProduct]);

  useEffect(() => {
    getWishlist();
    // getNotificationData()
  }, []);

  // Get Wishlist Data
  const getWishlist = () => {
    const token = JSON.parse(localStorage.getItem("auth_key"));
    const userData = JSON.parse(localStorage.getItem("user_info"));

    if (token) {
      WishlistService.getByUserId(userData.id)
        .then((res) => {
          if (res.data.success) {
            setWishlistData(res.data.data?.items);
            props?.addToWishlistHandler({
              totalWishlistItems: res.data.data?.items?.length,
            });
          }
        })
        .catch((e) => console.log(e));
    } else {
      const wishlist = JSON.parse(localStorage.getItem("wishlist_data"));
      setWishlistData(wishlist);
      props?.addToWishlistHandler({ totalWishlistItems: wishlist?.length });
    }
  };

  const gotoCart = () => {
    const token = JSON.parse(localStorage.getItem("auth_key"));
    const userData = JSON.parse(localStorage.getItem("user_info"));
    const data = {
      product_id: product?.id,
      product_name: product?.name,
      quantity: amount,
      product_variants_id: currentVariant?.id,
      mrp: currentVariant?.mrp,
      size: currentVariant?.Size?.name,
      color: currentVariant?.Color?.name,
      image: currentVariant?.image[0],
      gst_value: product?.TaxType?.value,
      hsn_code: product?.HsnCode?.code,
      tax_type_id: product?.tax_type_id,
    };

    if (token) {
      CartService.getByUserId(userData?.id).then((res) => {
        const cart_data = res.data.data;
        let isExist = false;
        let id = -1;
        if (cart_data) {
          cart_data?.items?.forEach((element, index) => {
            if (
              element?.product_id === product?.id &&
              element?.product_variants_id === currentVariant?.id
            ) {
              isExist = true;
              id = index;
              return;
            } else {
              isExist = false;
            }
          });
          if (isExist) {
            cart_data.items[id].quantity += amount;
          } else {
            cart_data.items[cart_data.items.length] = data;
          }
          cart_data.id = cart_data?._id;
          delete cart_data._id;
          CartService.addToCart(cart_data).then((res) => {
            props?.addToCartHandler({
              totalCartItems: res.data.data?.items?.length,
            });
          });
        } else {
          const mainCartData = {
            items: data,
            user_id: userData?.id,
          };
          CartService.addToCart(mainCartData).then((res) => {
            props?.addToCartHandler({
              totalCartItems: res.data.data?.items?.length,
            });
          });
        }
      });
      setTimeout(() => {
        getNotificationData();
      }, 2000);
    } else {
      const cart_data = JSON.parse(localStorage.getItem("cart_data"));
      let isExist = false;
      let id = -1;
      if (cart_data) {
        cart_data.forEach((element, index) => {
          if (
            element?.product_id === product?.id &&
            element?.product_variants_id === currentVariant?.id
          ) {
            isExist = true;
            id = index;
            return;
          } else {
            isExist = false;
          }
        });
        if (isExist) {
          cart_data[id].quantity += amount;
        } else {
          cart_data[cart_data.length] = data;
        }
        localStorage.setItem("cart_data", JSON.stringify(cart_data));
      } else {
        localStorage.setItem("cart_data", JSON.stringify([data]));
      }
      const newCartData = JSON.parse(localStorage.getItem("cart_data"));
      console.log(newCartData, "new cart data");
      props?.addToCartHandler({ totalCartItems: newCartData?.length });
      setTimeout(() => {
        getNotificationData();
      }, 2000);
    }
  };

  const addToWishlist = () => {
    const token = JSON.parse(localStorage.getItem("auth_key"));
    const userData = JSON.parse(localStorage.getItem("user_info"));
    const data = {
      product_id: product?.id,
      product_name: product?.name,
      quantity: amount,
      product_variants_id: currentVariant?.id,
      mrp: currentVariant?.mrp,
      size: currentVariant?.Size?.name,
      color: currentVariant?.Color?.name,
      image: currentVariant?.image[0],
      gst_value: product?.TaxType?.value,
      hsn_code: product?.HsnCode?.code,
      tax_type_id: product?.tax_type_id,
    };
    if (token) {
      WishlistService.getByUserId(userData?.id).then((res) => {
        const wishlist_data = res.data.data;
        let isExist = false;
        let id = -1;
        if (wishlist_data) {
          wishlist_data.items.forEach((element, index) => {
            if (
              element?.product_id === product?.id &&
              element?.product_variants_id === currentVariant?.id
            ) {
              isExist = true;
              id = index;
              return;
            } else {
              isExist = false;
            }
          });
          if (isExist) {
            const item = wishlist_data?.items.splice(id, 1);
            const requestData = {
              id: wishlist_data?._id,
              items: wishlist_data?.items,
              deleted_items: item,
              user_id: wishlist_data?.user_id,
            };
            WishlistService.addToWishlist(requestData).then((res) => {
              if (res.data.success) {
                getWishlist();
              }
            });
          } else {
            wishlist_data.items[wishlist_data?.items.length] = data;
            wishlist_data.id = wishlist_data?._id;
            delete wishlist_data._id;
            WishlistService.addToWishlist(wishlist_data).then((res) => {
              getWishlist();
            });
          }
        } else {
          const mainCartData = {
            items: data,
            user_id: userData?.id,
          };
          WishlistService.addToWishlist(mainCartData).then((res) => {
            getWishlist();
          });
        }
      });
    } else {
      const wishlist_data = JSON.parse(localStorage.getItem("wishlist_data"));
      let isExist = false;
      let id = -1;
      if (wishlist_data) {
        wishlist_data.forEach((element, index) => {
          if (
            element?.product_id === product?.id &&
            element?.product_variants_id === currentVariant?.id
          ) {
            isExist = true;
            id = index;
            return;
          } else {
            isExist = false;
          }
        });
        if (isExist) {
          // wishlist_data[id].quantity += amount;
          wishlist_data.splice(id, 1);
        } else {
          wishlist_data[wishlist_data?.length] = data;
        }
        localStorage.setItem("wishlist_data", JSON.stringify(wishlist_data));
        const newWishlist = JSON.parse(localStorage.getItem("wishlist_data"));
        setWishlistData(newWishlist);
        props?.addToWishlistHandler({
          totalWishlistItems: newWishlist?.length,
        });
      } else {
        localStorage.setItem("wishlist_data", JSON.stringify([data]));
        const newWishlist = JSON.parse(localStorage.getItem("wishlist_data"));
        setWishlistData(newWishlist);
        props?.addToWishlistHandler({
          totalWishlistItems: newWishlist?.length,
        });
      }
    }
    // navigate("/wishlist");
  };

  const selectColor = (item, id) => {
    setColor(item?.id);
    setAmount(1);
    ProductService.getSizeByColor(product?.id, item?.id).then((res) => {
      setProductDetails(res.data.data[0]);
      setImageList(res.data.data[0]?.ProductVariants[0]?.image);
      setVariant(res.data.data[0]?.ProductVariants[0]);
    });
  };

  const selectSize = (item, id) => {
    setSize(item?.id);
    setAmount(1);
    ProductService.getColorBySize(product.id, item.id).then((res) => {
      setProductDetails(res.data.data[0]);
      setImageList(res.data.data[0]?.ProductVariants[0]?.image);
      setVariant(res.data.data[0]?.ProductVariants[0]);
    });
  };

  const clear = () => {
    setColor(null);
    setSize(null);
    getProduct(id);
  };

  const addProduct = () => {
    if (currentVariant?.Stocks && currentVariant?.Stocks?.length !== 0) {
      if (amount >= 0 && currentVariant?.Stocks[0]?.quantity > amount) {
        setAmount((prev) => prev + 1);
      } else {
        setMessage(`Only ${currentVariant?.Stocks[0]?.quantity} is available`);
      }
    }
  };

  const removeProduct = () => {
    if (amount > 1) {
      setAmount((prev) => prev - 1);
    }
    if (amount <= currentVariant?.Stocks[0]?.quantity) {
      setMessage("");
    }
  };

  const uniqueColor = (product) => {
    const data = [];
    data.push(product?.ProductVariants[0]?.Color);

    product?.ProductVariants?.map((variant) => {
      if (!data.find((item) => item.id === variant?.Color?.id)) {
        data.push(variant?.Color);
      }
    });
    setColorList(data);
  };
  const uniqueSize = (product) => {
    const data = [];
    data.push(product?.ProductVariants[0]?.Size);

    product?.ProductVariants?.map((variant) => {
      if (!data.find((item) => item.id === variant?.Size?.id)) {
        data.push(variant.Size);
      }
    });
    const sortOrder = ["XS", "S", "M", "L", "XL", "XXL", "XXXL", "Free Size"];

    data.sort((a, b) => {
      const indexA = sortOrder.indexOf(a.name);
      const indexB = sortOrder.indexOf(b.name);
      return indexA - indexB;
    });
    setSizeList(data);
  };

  const checkWishlist = (data) => {
    return (
      data?.product_variants_id === currentVariant?.id &&
      data?.product_id === product?.id
    );
  };

  const filterPrice = (productVariantArray) => {
    for (let i = 0; i < productVariantArray?.length; i++) {
      const element = productVariantArray[i];
      if (element?.Size?.name === "S") {
        return element?.mrp;
      }
    }
    return null;
  };

  const gotoProductInfo = (product) => {
    navigate(`/product-info/${product?.SubCategory?.name}/${product?.name}`, {
      state: { id: product?.id },
    });
    window.location.reload();
  };

  const getNotificationData = async () => {
    if (auth_key) {
      await CartService.getByUserId(userData?.id).then((res) => {
        setNotification(res?.data?.data.items)
        setShowNotification(true)
      });
    } else {
      const data = JSON.parse(localStorage.getItem("cart_data"));
      data && setNotification(data)
      data && setShowNotification(true)
    }
  };
  return (
    <>
      <Helmet>
        <title>{product?.seo_title}</title>
        <meta name="description" content={product?.seo_description} />
        <meta name="keywords" content={product?.seo_keywords} />
        <link rel="icon" type="image/png" href={logo} />
      </Helmet>
      {/* {!loading ? ( */}
      <div className="App">
        {showNotification && (
          <Notification products={notification} onClose={handleCloseNotification} />
        )}
      </div>
      <div className="container">
        <div
          id="showcase"
          className={props?.isModal ? "showcase-h100 pop-boxes" : ""}
        >
          <div className="row">
            {/* slider  */}
            <div className="col-12 col-lg-5">
              <div className="row">
                <div className="col-sm-9 p-0 col-10 MainImages">
                  <Swiper
                    modules={[Navigation, FreeMode, Thumbs]}
                    navigation
                    thumbs={{ swiper: thumbsSwiper }}
                    //   slidesPerView={1}
                    loop={true}
                    className="mySwiper2"
                  >
                    {imageList?.map((item, i) => {
                      return (
                        <SwiperSlide key={i}>
                          <img
                            src={item}
                            alt={product?.name ?? props?.productName}
                            className="img-fluid"
                          />
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                </div>
                <div className="col-sm-3 p-0 col-2 ThumbsRightPart">
                  <Swiper
                    onSwiper={setThumbsSwiper}
                    // loop={true}
                    spaceBetween={10}
                    slidesPerView={4}
                    freeMode={true}
                    watchSlidesProgress={true}
                    direction="vertical"
                    modules={[FreeMode, Navigation, Thumbs]}
                    className="mySwiper SliderBox"
                  >
                    {imageList?.map((item, i) => {
                      return (
                        <SwiperSlide key={i}>
                          <img
                            src={item}
                            alt={product?.name ?? props?.productName}
                            className="img-fluid"
                          />
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                </div>
              </div>
            </div>
            {/* slider  */}

            {/* product-summary  */}
            <div className="col-12 col-lg-7">
              <div className="product-information">
                <div className="entry-summary">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <a
                          href=""
                          className="text-secondary text-decoration-none"
                        >
                          Home
                        </a>
                      </li>
                      <li className="breadcrumb-item">
                        <a
                          href=""
                          className="text-secondary text-decoration-none"
                        >
                          {product?.SubCategory?.name ?? props?.productCategory}
                        </a>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        {product?.name ?? props?.productName}
                      </li>
                    </ol>
                  </nav>

                  <div className="product-summary-top">
                    {/* product-title  */}
                    <h3 className="mb-2">
                      {product?.name ?? props?.productName}
                    </h3>
                    {/* product-title  */}

                    {/* price  */}
                    <div className="mb-4">
                      <p className="price">
                        <ins>
                          <span className="amount semi-bold">
                            <bdi>
                              <span>₹</span>
                              {filterPrice(product?.ProductVariants) ??
                                product?.ProductVariants[0]?.mrp}
                              {/* {product?.ProductVariants[0].mrp} */}
                            </bdi>
                          </span>
                        </ins>
                      </p>
                    </div>
                    {/* price  */}

                    <div className="price-separator"></div>

                    {/* product short description  */}
                    <div className="short-description">
                      <p className="text-secondary mb-3">
                        {product?.description}
                      </p>
                    </div>
                    {/* product short description  */}
                  </div>

                  {/* size guide start  */}
                  <a
                    href=""
                    className="size-guide d-flex align-items-center text-decoration-none text-secondary mb-4"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                    style={{ width: "fit-content" }}
                  >
                    <div
                      className="d-flex align-items-center"
                      style={{ width: 100 + "%" }}
                    >
                      <span className="title-before"></span>
                      <p className="m-0">Size Guide</p>
                    </div>
                  </a>

                  {/* <!-- Modal  */}
                  <div
                    className="modal fade ModalHight"
                    id="exampleModal"
                    tabIndex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="false"
                    data-backdrop="static"
                  >
                    <div className="modal-dialog modal-sm modal-dialog-centered modal-dialog-scrollable size-chart-modal">
                      <img
                        src={product?.Collection?.size_chart}
                        className="img-fluid size-chart-img"
                      ></img>
                      <div class="modal-header CLoseBtn">
                        <button
                          type="button"
                          class="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                    </div>
                  </div>

                  {/* <!-- modal  */}
                  {/* <!-- size guide end  */}

                  {/* <!-- size variations  */}
                  <div className="variations_form mt-3 mb-1">
                    <div className="d-flex">
                      <div className="variations mb-4">
                        <div className="label mb-3 d-flex align-items-center">
                          <label>Size: </label>
                          <ul className="variable-items-wrapper button-variable-items-wrapper size-list">
                            {sizeList?.map((item, i) => {
                              const isOutOfStock =
                                currentVariant &&
                                (currentVariant?.Stocks[0]?.quantity === 0 ||
                                  currentVariant?.Stocks?.length === 0);

                              const isSelected = recentSize === item?.id;

                              // const itemClassName = isSelected
                              //   ? "variable-item button-variable-item button-variable-item-small selected active"
                              //   : "variable-item button-variable-item button-variable-item-small selected";

                              const itemClassName = isSelected
                                ? "variable-item button-variable-item button-variable-item-small selected active"
                                : isOutOfStock
                                  ? "variable-item button-variable-item button-variable-item-small disabled"
                                  : "variable-item button-variable-item button-variable-item-small selected";
                              // Apply line-through style if out of stock
                              const style = isOutOfStock
                                ? { textDecoration: "line-through" }
                                : {};

                              return (
                                <li
                                  key={i}
                                  className={itemClassName}
                                  style={style}
                                  // onClick={() => selectSize(item, i)}
                                  title={item?.name}
                                  data-title={item?.name}
                                  data-value="small"
                                  onClick={
                                    isOutOfStock
                                      ? undefined
                                      : () => selectSize(item, i)
                                  }
                                >
                                  <div className="variable-item-contents">
                                    <span className="variable-item-span variable-item-span-button">
                                      {item?.name}
                                    </span>
                                  </div>
                                </li>
                              );
                            })}
                          </ul>

                          {/* <ul className="variable-items-wrapper button-variable-items-wrapper size-list">
                              {sizeList?.map((item, i) => {
                                return (
                                  <li
                                    key={i}
                                    className={
                                      recentSize === item?.id
                                        ? "variable-item button-variable-item button-variable-item-small selected active"
                                        : "variable-item button-variable-item button-variable-item-small selected"
                                    }
                                    onClick={() => selectSize(item, i)}
                                    title={item?.name}
                                    data-title={item?.name}
                                    data-value="small"
                                  >
                                    <div className="variable-item-contents">
                                      <span className="variable-item-span variable-item-span-button">
                                        {item?.name}
                                      </span>
                                    </div>
                                  </li>
                                );
                              })}
                            </ul> */}
                        </div>

                        <div className="value">
                          <span
                            className="reset_variations"
                            // href=""
                            onClick={() => clear()}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-x-circle"
                              viewBox="0 0 16 16"
                            >
                              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                            </svg>
                            Clear
                          </span>
                        </div>
                      </div>
                      <div className="variations mb-4">
                        <div className="label mt-1 d-flex align-items-center">
                          <label>Color: </label>
                          <ul className="d-flex mb-1 color-list">
                            {colorList?.map((item, i) => {
                              return (
                                <li
                                  key={i}
                                  href=""
                                  data-key="attra-color"
                                  data-value="81"
                                  data-multiple-filter="1"
                                  className={
                                    recentColor === item?.id
                                      ? "et-color-swatch mr-2 active"
                                      : "et-color-swatch mr-2"
                                  }
                                >
                                  <span
                                    className="et-swatch-circle mr-2"
                                    onClick={() => selectColor(item, i)}
                                  >
                                    <span
                                      style={{
                                        backgroundColor: ` ${item?.hex_code}`,
                                      }}
                                    ></span>
                                  </span>
                                  <span className="name"></span>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div className="single_variation_wrap">
                        <div className="variation-add-to-cart variations_button variation-add-to-cart-enabled">
                          <div
                            className="wishlist-div-open"
                            disabled={
                              currentVariant
                                ? currentVariant?.Stocks[0]?.quantity === 0
                                : false
                            }
                          >
                            <div class="col-sm-3 col-3 MarginBack">
                              <button
                                type="button"
                                className="wishlist-btn alt"
                                onClick={() => addToWishlist()}
                              >
                                <span className="ml-1 mb-1">
                                  {wishlistData ? (
                                    <div>
                                      {wishlistData.findIndex(checkWishlist) >
                                        -1 ? (
                                        <FaHeart />
                                      ) : (
                                        <FaRegHeart />
                                      )}
                                    </div>
                                  ) : (
                                    <FaRegHeart />
                                  )}
                                </span>
                              </button>
                            </div>
                            <div class="col-sm-3 col-3 MarginBack">
                              <div className="quantity">
                                <span className="minus">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-minus"
                                    onClick={() => removeProduct()}
                                  >
                                    <line x1="5" y1="12" x2="19" y2="12"></line>
                                  </svg>
                                </span>
                                <input
                                  // type="number"
                                  id="quantity_6355475f3e874"
                                  className="input-text qty"
                                  step="1"
                                  min="1"
                                  name="quantity"
                                  value={
                                    currentVariant?.Stocks?.length > 0
                                      ? currentVariant?.Stocks[0]?.quantity !==
                                        0
                                        ? amount
                                        : 0
                                      : 0
                                  }
                                  title="Qty"
                                  inputMode="numeric"
                                  autoComplete="off"
                                  max=""
                                />
                                <span className="plus">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-plus"
                                    onClick={() => addProduct()}
                                  >
                                    <line x1="12" y1="5" x2="12" y2="19"></line>
                                    <line x1="5" y1="12" x2="19" y2="12"></line>
                                  </svg>
                                </span>
                              </div>
                            </div>
                            <div class="col-sm-6 col-6 MarginBack">
                              <button
                                type="submit"
                                className="single_add_to_cart_button button alt"
                                onClick={() => gotoCart()}
                                disabled={
                                  currentVariant?.Stocks?.length > 0
                                    ? currentVariant?.Stocks[0]?.quantity === 0
                                      ? true
                                      : false
                                    : true
                                }
                              >
                                Add to cart
                              </button>
                            </div>
                          </div>
                          {currentVariant ? (
                            currentVariant?.Stocks[0]?.quantity === 0 ||
                              currentVariant?.Stocks?.length === 0 ? (
                              <div className="stock-out mt-2">
                                <p>Out of stock</p>
                              </div>
                            ) : null
                          ) : null}
                          <p className="text-red mt-2">{message}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!-- size variations  */}

                  {/* product meta information  */}
                  <hr />
                  <div className="product-meta">
                    <span className="sku_wrapper">
                      <span className="semi-bold">SKU:</span>
                      <span className="sku">{product?.sku}</span>
                    </span>
                    <span className="posten_in">
                      <span className="semi-bold">Category:</span>
                      <span
                        href=""
                        className="d-inline-block position-relative"
                      >
                        {product?.SubCategory.name}
                      </span>
                    </span>
                  </div>
                  <hr />
                  <ul>
                    <li>ADDITIONAL INFORMATION</li>
                    <div className="row">
                      <p className="col-6 col-md-4 mb-0">
                        <span className="semi-bold">Fabric:</span>{" "}
                        {product?.Fabric.name}
                      </p>
                      <p className="mb-0 col-6 col-md-4">
                        <span className="semi-bold">Fabric Care:</span>
                        {product?.FabricCare.name}
                      </p>
                      <p className="mb-0 col-6 col-md-4">
                        <span className="semi-bold">Occasion: </span>
                        {product?.Occasion.name}
                      </p>
                      <p className="mb-0 col-6 col-md-4">
                        <span className="semi-bold">Neck Type: </span>
                        {product?.NeckType.name}
                      </p>
                      <p className="mb-0 col-6 col-md-4">
                        <span className="semi-bold">Sleeve Type: </span>
                        {product?.SleeveType.name}
                      </p>
                      <p className="mb-0 col-6 col-md-4">
                        <span className="semi-bold">No. of Components: </span>
                        {product?.no_of_components ?? 1}
                      </p>
                      <p className="mb-0 col-6 col-md-4">
                        <span className="semi-bold">Garment Length: </span>
                        {product?.garment_length ?? ""}
                      </p>
                      <p className="mb-0 col-6 col-md-4">
                        <span className="semi-bold">Garment Fit: </span>
                        {product?.garment_fit ?? ""}
                      </p>
                    </div>
                  </ul>
                  {/* <hr />
                      <ul>
                        <li>WHAT'S IN YOUR BOX</li>
                        <p>{product?.included_items}</p>
                      </ul> */}
                  <hr />
                  <ul>
                    <li>SHIPPING INFORMATION</li>
                    <p>{product?.Collection?.shipping_information}</p>
                  </ul>
                  <hr />
                  <ul>
                    <li>DISCLAIMER</li>
                    <p>
                      This product will be exclusively handcrafted for you,
                      making the colour/texture/pattern slightly vary from the
                      image shown, due to multiple artisan-led techniques and
                      processes involved.
                    </p>
                  </ul>
                  {/* product meta information  */}
                </div>
              </div>
            </div>
            {/* product-summary  */}
          </div>
        </div>

        {!props.isModal ? (
          <div id="description" className="my-5 pt-3">
            <div className="container">
              <div className="tab-pannel-inner d-flex align-items-center mb-5">
                <h2>Related Products</h2>
                <span className="title-after"></span>
              </div>
              <div className="products">
                <div className="owl-carousel owl-theme">
                  <OwlCarousel
                    className="owl-theme"
                    margin={30}
                    items={4}
                    responsive={{
                      100: { items: 1 },
                      300: { items: 2 },
                      500: { items: 3 },
                      800: { items: 4 },
                    }}
                  >
                    {relatedProduct?.map((item, i) => {
                      return (
                        <div className="product card border-0 item" key={i}>
                          <figure className="product_thumbnail">
                            <img
                              src={item?.thumbnail_image}
                              className="card-img-top primary-image-hover"
                              onClick={() => gotoProductInfo(item)}
                              alt={
                                item.name +
                                " | " +
                                (item.SubCategory?.name || "") +
                                " | Sugandh"
                              }
                            />
                            {/* <img
                              src={item?.thumbnail_image}
                              className="card-img-top secondary-image-hover"
                              onClick={() => gotoProductInfo(item)}
                              alt={
                                item.name +
                                " | " +
                                (item.SubCategory?.name || "") +
                                " | Sugandh"
                              }
                            /> */}
                            <div className="actions-wrapper">
                              <div className="actions-inner">
                                {/* <a href="" className="quickview-btn">
                                  <span>Quick View</span>
                                  <span className="icon">
                                    <span className="et-icon et-maximize-2"></span>
                                  </span> 
                                </a> */}
                              </div>
                            </div>
                          </figure>
                          <div className="card-body ps-0 pe-0">
                            <div className="d-flex justify-content-between align-items-center mb-2">
                              <h5 className="card-title m-0">
                                <a href="">{item.name}</a>
                              </h5>
                              <div className="heart-icon">
                                <a href="">
                                  <i className="bi bi-heart"></i>
                                  <i className="bi bi-heart-fill"></i>
                                </a>
                              </div>
                            </div>

                            <div className="product_after_title">
                              <div className="product_after_shop_loop_price d-flex justify-content-between align-items-center">
                                <span className="price">
                                  {/* <del>
                                      <span className="amount">
                                        <bdi>
                                          <span>₹</span>{item.ProductVariants[0].mrp}
                                        </bdi>
                                      </span>
                                    </del> */}
                                  <ins>
                                    <span className="amount">
                                      <bdi>
                                        <span>₹</span>
                                        {item.ProductVariants[0].mrp}
                                      </bdi>
                                    </span>
                                  </ins>
                                </span>
                                <div className="star-rating">
                                  <i className="bi bi-star-fill"></i>
                                  <i className="bi bi-star-fill"></i>
                                  <i className="bi bi-star-fill"></i>
                                  <i className="bi bi-star-fill"></i>
                                  <i className="bi bi-star-half"></i>
                                </div>
                              </div>
                              <div className="after_shop_loop_actions">
                                <a
                                  href=""
                                  className="button add_to_cart_button"
                                >
                                  <span>Add to cart</span>
                                  <span className="icon">
                                    <span className="et-icon et-shopping-bag"></span>
                                  </span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
      {/* ) : (
        <div className="spinner-container">
          <BeatLoader
            color="#242323"
            loading={loading}
            // cssOverride={override}
            // size={50}
            aria-label="Loading Spinner"
            data-testid="loader"
            className="loader"
          />
        </div>
      )} */}
    </>
  );
};

{
  /*-------------------------- Mobile home------------------------- */
}
{
  /* <div className="d-block d-xl-none">
<div className="col-12 col-lg-5" style={{ height: 100 + "vh" }}>
                <div className="row">
                  
                  <div className="col-9 MainImages">
                    <Swiper
                      modules={[Navigation, FreeMode, Thumbs]}
                      navigation
                      thumbs={{ swiper: thumbsSwiper }}
                      spaceBetween={50}
                      //   slidesPerView={1}
                      loop={true}
                      className="mySwiper2"
                    >
                      {imageList?.map((item, i) => {
                        return (
                          <SwiperSlide key={i}>
                            <img src={item} alt="" className="img-fluid" />
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>
                  </div>
                  <div className="col-3 ThumbsRightPart">
                    <Swiper
                      onSwiper={setThumbsSwiper}
                      // loop={true}
                      spaceBetween={10}
                      slidesPerView={4}
                      freeMode={true}
                      watchSlidesProgress={true}
                      direction="horizontal"
                      modules={[FreeMode, Navigation, Thumbs]}
                      className="mySwiper SliderBox"
                    >
                      {imageList?.map((item, i) => {
                        return (
                          <SwiperSlide key={i}>
                            <img src={item} alt="" className="img-fluid" />
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>
                  </div>
                </div>
              </div>
</div> */
}

export default ProductInfo;
