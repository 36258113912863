import http from "../environment";

const getCountry = () => {
  return http.get(`/country/get`);
};

const getProvince = (countryId) => {
    return http.get(`province/get?country_id=${countryId}`);
}

const getCity = (provinceId) => {
    return http.get(`city/get?province_id=${provinceId}`);
}

const getZipcode = (cityId) => {
    return http.get(`zipcode/get?city_id=${cityId}`);
}

const getLogistics = () => {
  return http.get('/logistics/get');
}

const validateZipcode = (zipcode) => {
  return http.get(`zipcode/data/get?zipcode=${zipcode}`);
}

const getCountryById = (id) => {
  return http.get(`/country/get?country_id=${id}`);
};

const UtilsService = {
  getCountry,
  getProvince,
  getCity,
  getZipcode,
  getLogistics,
  validateZipcode,
  getCountryById
};

export default UtilsService;
