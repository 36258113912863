import React, { useState } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "./Login.css";
import LoginService from "../../api-config/login";
import WishlistService from "../../api-config/wishlist";
import CartService from "../../api-config/cart";
import ForgotPasswordService from "../../api-config/forgot-password";
import { FaEye, FaEyeSlash } from "react-icons/fa";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Register from "../Register/Register";

const Login = (props) => {
  console.log(props, "login props");
  const navigate = useNavigate();
  const { isModal, setIsOpen, setForgotPassword } = props;
  const emailPattern =
    /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+\/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+\/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)$/;

  const [showPasswordField, setPasswordField] = useState(false);
  const [showSignUpForm, setSignUpForm] = useState(false);
  const [beforeValidate, setValidate] = useState(false);
  const [isLogin, setIsLogin] = useState(true);
  const [btnTitle, setbtnTitle] = useState("Login");
  const [forgotPass, setForgotPass] = useState(false);
  const [isOTP, setIsOTP] = useState(false);
  const [otp, setOTP] = useState("");
  const [transform, setTransform] = useState("50%");

  const [tabIndex, setTabIndex] = useState(0);

  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState();

  const formik = useFormik({
    initialValues: {
      email_id: "",
      password: "",
      // is_web_signin: true,
      // first_name: "",
      // last_name: "",
      // primary_contact_number: "",
      // // dob: "",
      // user_role_id: 1,
    },
    validateOnChange: false,
    onSubmit: (values) => {
      console.log("successful login");
      loginAPI(values);
      // navigate("/")
      // if (!beforeValidate) {
      //   handleBlur(values.email_id);
      // } else {
      //   isLogin ? loginAPI(values) : signupAPI(values);
      // }
    },
  });

  const loginAPI = (values) => {
    LoginService.userLogin(values)
      .then((res) => {
        if (res.data.success) {
          // Handle successful login
          setData(res.data);
          toast.success(res.data.message);
          navigate("/home/");
        } else {
          // Handle login failure
          toast.error(res.data.message);
        }
      })
      .catch((error) => {
        // Handle error
        console.error("Login failed:", error);
        toast.error("Login failed. Please try again.");
      });
  };
  // const loginAPI = (values) => {
  //   if (testPattern(values.email_id)) {
  //     const data = {
  //       email_id: values.email_id,
  //       password: values.password,
  //     };
  //     LoginService.userLogin(data)
  //       .then((res) => {
  //         if (res.data.success) {
  //           setData(res.data);
  //           navigate("/")
  //         } else {
  //           toast.error(res.data.message);
  //         }
  //       })
  //       .catch((e) => console.log(e));
  //   } else {
  //     const data = {
  //       primary_contact_number: values.email_id,
  //       password: values.password,
  //     };
  //     LoginService.userLogin(data)
  //       .then((res) => {
  //         if (res.data.success) {
  //           setData(res.data);
  //           navigate("/")

  //         } else {
  //           toast.error(res.data.message);
  //         }
  //       })
  //       .catch((e) => console.log(e));
  //   }
  // };

  // function to store data in session storage after login with email_id or phone number
  const setData = (data) => {
    localStorage.setItem("auth_key", JSON.stringify(data.Authorization));
    localStorage.setItem("user_info", JSON.stringify(data.data));
    localStorage.setItem("is_subscription", JSON.stringify(data.subscribe));
    localStorage.setItem(
      "subscription_data",
      JSON.stringify(data.subscription_data),
    );
    const wishlistData = JSON.parse(localStorage.getItem("wishlist_data"));
    const cartData = JSON.parse(localStorage.getItem("cart_data"));
    console.log(wishlistData);
    console.log(cartData);

    CartService.getByUserId(data?.data?.id).then((res) => {
      if (res.data.success) {
        const existing_cart_data = res.data.data;

        if (existing_cart_data) {
          console.log(existing_cart_data);
          cartData?.forEach((element) => {
            if (
              !existing_cart_data?.items.some(
                (item) => item.product_name === element?.product_name,
              )
            ) {
              existing_cart_data.items[existing_cart_data?.items?.length] =
                element;
            }
          });
          console.log(existing_cart_data, "after");
          existing_cart_data.id = existing_cart_data?._id;
          delete existing_cart_data._id;
          CartService.addToCart(existing_cart_data).then((res) => {
            props?.addToCartHandler({
              totalCartItems: res.data.data?.items?.length ?? 0,
            });
          });
        } else {
          const requestCartData = {
            items: cartData,
            user_id: data?.data?.id,
          };
          CartService.addToCart(requestCartData).then((res) => {
            props?.addToCartHandler({
              totalCartItems: res.data.data?.items?.length ?? 0,
            });
            localStorage.removeItem("cart_data");
          });
        }
      }
    });

    WishlistService.getByUserId(data?.data?.id).then((res) => {
      if (res.data.success) {
        const existing_wishlist_data = res.data.data;

        if (existing_wishlist_data) {
          wishlistData?.forEach((element) => {
            if (
              !existing_wishlist_data?.items.some(
                (item) => item?.product_name === element?.product_name,
              )
            ) {
              existing_wishlist_data.items[
                existing_wishlist_data?.items?.length
              ] = element;
            }
          });
          existing_wishlist_data.id = existing_wishlist_data?._id;
          delete existing_wishlist_data._id;

          WishlistService.addToWishlist(existing_wishlist_data).then((res) => {
            if (res.data.success) {
              WishlistService.getByUserId(data?.data?.id)
                .then((res) => {
                  if (res.data.success) {
                    props?.addToWishlistHandler({
                      totalWishlistItems: res.data.data?.items?.length ?? 0,
                    });
                    localStorage.removeItem("wishlist_data");
                  }
                })
                .catch((e) => console.log(e));
            }
          });
        } else {
          const requestWishlistData = {
            items: wishlistData,
            user_id: data?.data?.id,
          };

          WishlistService.addToWishlist(requestWishlistData).then((res) => {
            if (res.data.success) {
              WishlistService.getByUserId(data?.data?.id)
                .then((res) => {
                  if (res.data.success) {
                    props?.addToWishlistHandler({
                      totalWishlistItems: res.data.data?.items?.length ?? 0,
                    });
                    localStorage.removeItem("wishlist_data");
                  }
                })
                .catch((e) => console.log(e));
            }
          });
        }
      }
    });

    // isModal ? setIsOpen(false) : navigate(-1);
    // toast.success(data.message);
    // setTimeout(function () {
    // window.location.reload(true);
    // }, 1000);
  };

  const testPattern = (value) => {
    if (emailPattern.test(value)) {
      return true;
    } else {
      return false;
    }
  };

  const isUserValid = (data) => {
    LoginService.validateUser(data)
      .then((res) => {
        if (forgotPass) {
          if (res.data.success) {
            sendOTP(res.data.data[0].email_id);
          } else {
            toast.error(res.data.message);
          }
        } else {
          if (res.data.success) {
            setPasswordField(true);
            setTransform("29%");
            setbtnTitle("Login");
            setSignUpForm(false);
            setIsLogin(true);
          } else {
            setSignUpForm(true);
            setbtnTitle("Sign Up");
            setPasswordField(true);
            setIsLogin(false);
          }
        }
      })

      .catch((e) => console.log(e));

    setValidate(true);
  };

  const handleBlur = (value) => {
    if (value) {
      if (testPattern(value)) {
        const data = {
          email_id: value,
        };
        isUserValid(data);
      } else {
        const data = {
          primary_contact_number: value,
        };
        isUserValid(data);
      }
    } else {
      setPasswordField(false);
    }
  };

  const sendOTP = (email_id) => {
    if (email_id) {
      ForgotPasswordService.sendOTPEmail(email_id).then((res) => {
        if (res.data.success) {
          setOTPValue("");
          setIsOTP(true);
        } else {
          toast.error(res.data.message);
        }
      });
    }
  };

  const forgotPassword = () => {
    // setForgotPass(true);
    // setbtnTitle("Send OTP");
    // setPasswordField(false);
    // setSignUpForm(false);
    navigate("/forgot-password");

  };

  const setOTPValue = (e) => {};
  const handleSignUp = () => {
    navigate("/sign-up");
  };
  const { handleChange, handleSubmit } = formik;

  return (
    <>
      <div className="wrap-login100">
        <div className="login-tab">
          <form
            className="login100-form validate-form"
            onSubmit={formik.handleSubmit}
          >
            <div className="mb-3">
              <div className="wrap-input100 validate-input">
                <input
                  className="input100 has-val"
                  placeholder="Email"
                  type="text"
                  autoComplete="Off"
                  name="email_id"
                  value={formik.values.email_id}
                  onChange={formik.handleChange}
                />
              </div>
            </div>
            <div className="mb-3">
              <div
                className="wrap-input100 validate-input"
                data-validate="Enter password"
              >
                <span className="show-eye">
                  {showPassword1 ? (
                    <FaEyeSlash onClick={() => setShowPassword1(false)} />
                  ) : (
                    <FaEye onClick={() => setShowPassword1(true)} />
                  )}
                </span>
                <input
                  type={showPassword1 ? "text" : "password"}
                  className="input100 has-val"
                  placeholder="Password"
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                />
              </div>
            </div>
            {/* forgot password feature  */}
            <div
                  className="forgot-pass"
                  onClick={() => forgotPassword()}
                >
                  <span>Forgot Password?</span>
                </div>
            <div className="container-login100-form-btn">
              <div className="wrap-login100-form-btn">
                <div className="login100-form-bgbtn"></div>
                <button
                  className="login100-form-btn"
                  type="submit"
                  disabled={!formik.values.email_id || !formik.values.password}
                >
                  {btnTitle}
                </button>
              </div>
            </div>
            <div className="account-text">
              Don't have an account?{" "}
              <span onClick={() => handleSignUp()}>Sign Up</span>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Login;
