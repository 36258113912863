import React, { useState, useEffect } from "react";

const Sort = (props) => {
  const [selectedSort, setSelectedSort] = useState("SortFilter");
  const [setProducts] = useState([]);
  let sort
  const handleSortChange = (event) => {
    props.setFilter(event);
    setSelectedSort(event.target.value);
    sort = event.target.value
  };

//https://api.sugandh.co
  useEffect(() => {
    fetch(
      `https://api.sugandh.co/public/api/product/get?current_page=1&page_size=15&is_admin=true&sort=${sort}&is_shown_on_website=true&sort_price_wise=true&sort_alphabet_wise=true${selectedSort}`
    )
      .then((response) => response.json())
      .then((data) => setProducts(data))
      .catch((error) => console.error(error));
  }, [selectedSort]);

  return (
    <div className="sort-selection">
      <form action="#">
        <label htmlFor="sort"></label>
        <select
          name="sort"
          id="sort"
          className="sort-selection--style"
          value={selectedSort}
          onChange={handleSortChange}
        >
          <option value="SortFilter">Sort By</option>
          <option value="lowest">Price (low to high)</option>
          <option value="highest">Price (high to low)</option>
          <option value="new">New Arrivals</option>
          {/* <option value="a-z">Name (a-z)</option>
          <option value="z-a">Name (z-a)</option> */}
        </select>
      </form>
    </div>
  );
};

export default Sort;
