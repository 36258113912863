import http from "../environment";

const updateUserDetails = (requestData) => {
    return http.post(`user/save`, requestData);
}

const getById = (id) => {
  return http.get(`/user/get?id=${id}`);
};

const UserService = {
  getById,
  updateUserDetails
};

export default UserService;
