import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import BlogService from "../../api-config/blog";
import "./blog.css";

const Blog = () => {
  const navigate = useNavigate();
  const [blogData, setBlogData] = useState();

  useEffect(() => {
    BlogService.getBlog().then((res) => {
      if (res.data.success) {
        setBlogData(res.data.data);
      }
    });
  }, []);

  const viewBlog = (blog_data) => {
    navigate("/blogs/view-blog", { state: { blog_data: blog_data } });
  };

  return (
    <div className="blog container">
      <div className="blog_posts">
        {/* {
                    blogData?.map((item, i) => {
                        return (
                            <div className="row blog_item" key={i}>
                                <div className={"col-12 col-md-4 img-container " + (i%2==0 ? 'order-md-1' : 'order-md-2')}>
                                    <img src={item?.thumbnail_image} className="img-fluid" alt={item?.name} />
                                </div>
                                <div className={"col-12 col-md-8 blog-content " + (i%2==0 ? 'order-md-2' : 'order-md-1')}>
                                    <h4>{item?.name}</h4>
                                    <p>{item?.description}</p>
                                </div>
                            </div>
                        )
                    })
                } */}
        <div className="row blog_item">
          {blogData?.map((item, i) => {
            return (
              <div
                className="img-container col-12 col-md-6 col-lg-4 mb-5"
                key={i}
                onClick={() => viewBlog(item)}
              >
                <img
                  src={item?.thumbnail_image}
                  className="img-fluid"
                  alt={item?.name}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default Blog;
