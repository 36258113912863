import { ADD_TO_WISHLIST, REMOVE_TO_WISHLIST } from "../constants";

const initialState = {
    totalWishlistData: 0
}
export default function wishlistReducer(state = [], action) {
    switch (action.type) {
        case ADD_TO_WISHLIST:
            return (action.data?.totalWishlistItems )
        case REMOVE_TO_WISHLIST:
            return (action?.data?.totalWishlistItems )
        default:
            return state;
    }
}
