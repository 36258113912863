import http from "../environment";

const getAll = () => {
  return http.get(`/product/brand/get`);
};

const getById = (id) => {
  return http.get(`/product/brand/get?id=${id}`);
};

const getByKeyword = (keyword) => {
  return http.get(`/product/brand/get?keyword=${JSON.stringify(keyword)}`);
}

const BrandService = {
  getAll,
  getById,
  getByKeyword
};

export default BrandService;
