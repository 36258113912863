import React, { useState } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "./Login.css";
import ForgotPasswordService from "../../api-config/forgot-password";

import "react-tabs/style/react-tabs.css";

const ForgotPassword = (props) => {
  const navigate = useNavigate();
  const emailPattern =
    /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+\/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+\/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)$/;

  const [btnTitle, setbtnTitle] = useState("Send Email");


  const formik = useFormik({
    initialValues: {
      email_id: "",
    },
    validateOnChange: false,
    onSubmit: async (values) => {
      const checkEmail = await testPattern(values?.email_id)
      console.log(checkEmail);
      if (checkEmail) {
        ForgotPasswordService.sendEmail(values).then((res) => {
          if(res.status == 200){
            toast.success("Mail send.");
          }
          if (res?.data?.code === 201) {
            toast.error(res?.data?.message);
          }
        }).catch((err) => {
          console.log("Error >> ", err.response);
        })
      } else {
        toast.error("Please Enter valid email");
      }
    },
  });

  const testPattern = (value) => {
    if (emailPattern.test(value)) {
      return true;
    } else {
      return false;
    }
  };

  const { handleChange, handleSubmit } = formik;

  return (
    <>
      <div className="wrap-login100">
        <div className="login-tab">
          <form
            className="login100-form validate-form"
            onSubmit={formik.handleSubmit}
          >
            <div className="mb-3">
              <div className="wrap-input100 validate-input">
                <input
                  className="input100 has-val"
                  placeholder="Enter your email"
                  type="text"
                  autoComplete="Off"
                  name="email_id"
                  value={formik.values.email_id}
                  onChange={formik.handleChange}
                />
              </div>
            </div>
            <div className="container-login100-form-btn">
              <div className="wrap-login100-form-btn">
                <div className="login100-form-bgbtn"></div>
                <button
                  className="login100-form-btn"
                  type="submit"
                >
                  {btnTitle}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
