import React, { useState, useEffect } from "react";
import { BeatLoader } from "react-spinners";
import WishlistService from "../../api-config/wishlist";
import blankImage from "../../assets/images/about-team.png";
import "./wishlist.css";
import { useNavigate, useLocation } from "react-router-dom";
import CartService from "../../api-config/cart";

const Wishlist = (props) => {
  const [wishlistData, setWishlistData] = useState();
  const [item, setItem] = useState();
  const [loading, setLoading] = useState(true);
  const token = JSON.parse(localStorage.getItem("auth_key"));
  const user_data = JSON.parse(localStorage.getItem("user_info"));

  const navigate = useNavigate();

  const gotoProductInfo = (Collection) => {
    console.log(Collection);
    navigate(`/the-shop/collections/${Collection}`, {
      state: { id: Collection },
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getWishlistData = () => {
    if (token) {
      console.log(token, "token");
      WishlistService.getByUserId(user_data?.id)
        .then(async (res) => {
          if (res.data.success) {
            setWishlistData(res.data.data);
            setItem(res.data.data?.items);
            setLoading(false);
          }
        })
        .catch((e) => console.log(e));
    } else {
      console.log("wishlist");
      const data = JSON.parse(localStorage.getItem("wishlist_data"));
      setWishlistData(data);
      setItem(data);
      setLoading(false);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      getWishlistData();
    }, 800);
  }, []);

  const removeWishlist = (items, index) => {
    if (token) {
      const data = item;
      if (index > -1) {
        data.splice(index, 1);
      }
      const wishlist_data = {
        id: wishlistData._id,
        items: data,
        deleted_items: [items],
        user_id: wishlistData.user_id,
      };
      WishlistService.addToWishlist(wishlist_data).then((res) => {
        WishlistService.getByUserId(user_data.id).then((res) => {
          setWishlistData(res.data.data);
          setItem(res.data.data?.items);
          props?.removeToWishlistHandler({
            totalWishlistItems: res.data.data?.items?.length,
          });
        });
      });
    } else {
      console.log("remove wishlist");
      const wishlist_data = JSON.parse(localStorage.getItem("wishlist_data"));
      console.log(wishlist_data);

      if (index > -1) {
        wishlist_data.splice(index, 1);
        console.log(wishlist_data, "after remove", index);

        console.log(wishlist_data, "inside if");
        localStorage.setItem("wishlist_data", JSON.stringify(wishlist_data));
        const newWishlist = JSON.parse(localStorage.getItem("wishlist_data"));
        console.log(newWishlist, "new wishlist");
        setWishlistData(newWishlist);
        setItem(newWishlist);
        props?.removeToWishlistHandler({
          totalWishlistItems: newWishlist?.length ?? 0,
        });
      }

      // const newWishlist = JSON.parse(localStorage.getItem("wishlist_data"));
    }
  };

  const gotoCart = async (items, index) => {
    delete items?._id
    const data = items

    if (token) {
      CartService.getByUserId(user_data?.id).then((res) => {
        const cart_data = res.data.data;
        if (cart_data) {
          cart_data.id = cart_data?._id;
          cart_data.items[cart_data.items.length] = data;
          delete cart_data._id;
          CartService.addToCart(cart_data).then((res) => {
            props?.addToCartHandler({
              totalCartItems: res.data.data?.items?.length,
            });
            removeWishlist(items, index)
          });
        } else {
          const mainCartData = {
            items: data,
            user_id: user_data?.id,
          };
          CartService.addToCart(mainCartData).then((res) => {
            props?.addToCartHandler({
              totalCartItems: res.data.data?.items?.length,
            });
            removeWishlist(items, index)
          });
        }
      });

    } else {
      navigate("/login");
    }
  }

  return (
    <div className="wishlist">
      {!loading ? (
        <div>
          <div className="container">
            <div className="row mb-4">
              <h1 className="display-6 text-dark mb-4 text-center page-title">
                Wishlist
              </h1>
            </div>
            <div className="row d-flex justify-content-center">
              <div className="col-lg-8">
                {item?.length > 0 ? (
                  <div>
                    <a href="/the-shop" className="cool-link mb-3">
                      Continue Shopping
                    </a>
                    {item?.map((item, i) => {
                      return (
                        <div className="row" key={i}>
                          <hr className="my-3" />
                          <div className="row d-flex justify-content-between align-items-center">
                            <div className="col-1 p-0 ">
                              <a
                                href="#"
                                className="remove"
                                onClick={() => removeWishlist(item, i)}
                              ></a>
                            </div>
                            <div className="col-2 product-thumbnail p-0">
                              <img
                                src={item.image || blankImage}
                                alt={item.title}
                                className="img-fluid"
                                onClick={() =>
                                  gotoProductInfo(item?.Collection.name)
                                }
                              />
                            </div>
                            <div className="col-3">
                              <p className="text-dark ">{item?.product_name}</p>
                              <p className="mb-0">Color: {item?.color}</p>
                              <p className="mb-0">Size: {item?.size}</p>
                            </div>
                            <div className="col-1 p-0">
                              <p className="text-dark">₹ {item?.mrp}</p>
                            </div>
                            <div class="col-sm-3 col-2 MarginBack">
                              <button
                                type="submit"
                                className="single_add_to_cart_button button alt"
                                onClick={() => gotoCart(item, i)}
                              >
                                Add to cart
                              </button>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div className="empty-wishlist">
                    <h5>Empty Wishlist</h5>
                    <p>
                      You have not added any products to your wishlist yet.
                      Start liking products to add them to your wishlist.
                    </p>
                    <br />

                    <a href="/the-shop" className="cool-link">
                      Continue Shopping
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
          <br />
          <br />
        </div>
      ) : (
        <div className="spinner-container">
          <BeatLoader
            color="#242323"
            loading={loading}
            // cssOverride={override}
            // size={50}
            aria-label="Loading Spinner"
            data-testid="loader"
            className="loader"
          />
        </div>
      )}
    </div>
  );
};
export default Wishlist;
