import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import OrderService from "../../api-config/order";
import { cancelOrder } from "../../api-config/shiprocketAPI";
import UserProfile from "../User Profile/UserProfile";
import { toast, ToastContainer } from "react-toastify";
import "./OrderView.css";
import Modal from "react-modal";
import { FaTimes } from "react-icons/fa";
Modal.setAppElement("#root");


const OrderView = () => {
  const location = useLocation();
  const id = location?.state?.id;
  const navigate = useNavigate();

  const [orderData, setOrderData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedReason, setSelectedReason] = useState("Reason 1");


  useEffect(() => {
    OrderService.getById(id)
      .then((res) => {
        setOrderData(res.data.data[0]);
      })
      .catch((e) => console.log(e));
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);

    return formattedDate;
  };
  const toggleModal = () => {
    setIsOpen(!isOpen);
  };
  const handleReasonChange = (event) => {
    setSelectedReason(event.target.value);
  };

  const refund_order = async () => {
    try {
      await OrderService.returnOrder(orderData).then((res) => {
        if (res.status) {
          toast.success("Order Refund successfull.");
          navigate("/order")
        }
      }).catch((err) => {
        toast.error("Something Wrong");

      })
      // await cancelOrder(orderData?.ShipRocketDetails[0]?.order_id).then(
      //   (res) => {
      //     console.log(res, "res");
      //   },
      // ).catch((err)=>{
      //   console.log(err, "err");
      // });
    } catch (error) {
      console.log(error);
    }
  };

  const cancle_order = async () => {
    try {
      orderData.reason = selectedReason
      await OrderService.cancelOrder(orderData).then((res) => {
        if (res.status) {
          toast.success("Order Cancle successfull.");
          navigate("/order")
        }
        toggleModal();

      }).catch((err) => {
        toast.error("Something Wrong");
        toggleModal();
      })
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div style={{ marginTop: "50px" }}>
      <div className="my-account">
        <div className="container">
          <div className="post-content row">
            <div className="col-xl-3">
              <UserProfile />
            </div>

            <div className="MyAccount-content col-12 col-xl-9">
              <div className="hero-header">
                <div className="regular-title">
                  <div className="container hero-header-container">
                    <div className="row">
                      <header className="col-lg-8 products-header">
                        <h1 className="page-title" itemProp="name headline">
                          Order #{orderData?.invoice_number}
                        </h1>
                      </header>
                    </div>
                  </div>
                </div>
              </div>
              <p>
                Order #
                <mark className="order-number">
                  {orderData?.invoice_number}
                </mark>{" "}
                was placed on{" "}
                <mark className="order-date">{orderData?.invoice_date}</mark>{" "}
                and is currently{" "}
                <mark className="order-status">{orderData?.order_status_id == 8 && "Placed" || orderData?.order_status_id == 9 && "Shipped" || orderData?.order_status_id == 10 && "Delivered" || orderData?.order_status_id == 11 && "Refund" || orderData?.order_status_id == 12 && "Cancle"}</mark>.
              </p>

              <section className="order-details">
                <h2 className="order-details__title">Order details</h2>

                <table className="table table--order-details shop_table order_details">
                  {/* <!-- <thead>
                                <tr>
                                    <th className="table__product-name product-name">Product</th>
                                    <th className="table__product-table product-total">Total</th>
                                </tr>
                            </thead> --> */}

                  <tbody>
                    {orderData?.InvoiceDetails?.map((item) => {
                      return (
                        <tr
                          className="table__line-item order_item"
                          key={item.id}
                        >
                          <td className="table__product-name product-name">
                            <a href="">{item?.product_name}</a>
                            <strong className="product-quantity">
                              {item?.quantity}
                            </strong>
                            <ul className="wc-item-meta">
                              <li>
                                <strong className="wc-item-meta-label">
                                  Color:
                                </strong>
                                <p>{item?.color}</p>
                              </li>
                              <li>
                                <strong className="wc-item-meta-label">
                                  Size:
                                </strong>
                                <p>{item?.size}</p>
                              </li>
                            </ul>
                          </td>

                          {/* <td className="table__product-total product-total">
                            <span className="Price-amount amount">
                              <bdi>
                                <img src={item.image} alt={item.product_name} />
                              </bdi>
                            </span>
                          </td> */}

                          <td className="table__product-total product-total">
                            <span className="Price-amount amount">
                              <bdi>
                                <span className="Price-currencySymbol">₹</span>
                                {item?.mrp}
                              </bdi>
                            </span>
                          </td>
                        </tr>
                      );
                    })}

                    {/* <tr className="table__line-item order_item">
                      <td className="table__product-name product-name">
                        <a href="#">Abstract Print Cotton Blouse</a>{" "}
                        <strong className="product-quantity">×&nbsp;1</strong>
                      </td>

                      <td className="table__product-total product-total">
                        <span className="Price-amount amount">
                          <bdi>
                            <span className="Price-currencySymbol">$</span>39.00
                          </bdi>
                        </span>
                      </td>
                    </tr> */}
                  </tbody>

                  <tfoot>
                    <tr>
                      <th scope="row text-dark">Subtotal:</th>
                      <td>
                        <span className="Price-amount amount">
                          <span className="Price-currencySymbol">₹</span>
                          {orderData?.total_gst_inclusive_rate}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row text-dark">Shipping:</th>
                      <td>Free shipping</td>
                    </tr>
                    <tr>
                      <th scope="row text-dark">Payment method:</th>
                      <td>Cash on delivery</td>
                    </tr>
                    <tr>
                      <th scope="row text-dark">Total:</th>
                      <td>
                        <span className="Price-amount amount">
                          <span className="Price-currencySymbol">₹</span>
                          {orderData?.total_gst_inclusive_rate}
                        </span>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </section>
              <section className="order-cancel">
                <div className="row">
                  <button className="btn col-12 col-md-5 track-order-btn">
                    Track Order
                  </button>
                  {orderData?.is_online_payment || orderData?.order_status_id == 10 ?
                    <button
                      className="btn col-12 col-md-5 offset-md-2 cancel-order-btn"
                      onClick={() => refund_order()}
                    // disabled={orderData.order_status_id == 11 ? true : false}
                    >
                      Refund
                    </button>
                    : null}

                  {!orderData?.is_online_payment && orderData?.order_status_id < 10 ?
                    <button
                      className="btn col-12 col-md-5 offset-md-2 cancel-order-btn"
                      onClick={() => setIsOpen(true)}
                      disabled={orderData.order_status_id == 12 ? true : false}
                    >
                      Cancel
                    </button>
                    : null}

                </div>
              </section>

              <section className="billing-address">
                <div className="row">
                  <div className="col-12 col-md-6">
                    <h2 className="mb-4 text-dark">Billing address</h2>
                    <address>
                      {orderData?.billing_address?.first_name +
                        " " +
                        orderData?.billing_address?.last_name}
                      <br />
                      {orderData?.billing_address?.contact_number}
                      <br />
                      {orderData?.billing_address?.address_line_1},
                      <br />
                      {orderData?.billing_address?.address_line_2},{" "}
                      {orderData?.billing_address?.city}
                      <br />
                      {orderData?.billing_address?.province},{" "}
                      {orderData?.billing_address?.country} -{" "}
                      {orderData?.billing_address?.zipcode}
                      {/* <br />
                      temp123@gmail.com */}
                    </address>
                  </div>
                  <div className="col-12 col-md-6">
                    <h2 className="mb-4 text-dark">Shipping address</h2>
                    <address>
                      {orderData?.shipping_address?.first_name ??
                        orderData?.billing_address?.first_name +
                        " " +
                        orderData?.shipping_address?.last_name ??
                        orderData?.billing_address?.last_name}
                      <br />
                      {orderData?.shipping_address?.contact_number ??
                        orderData?.billing_address?.contact_number}
                      <br />
                      {orderData?.shipping_address?.address_line_1 ??
                        orderData?.billing_address?.address_line_1}
                      ,
                      <br />
                      {orderData?.shipping_address?.address_line_2 ??
                        orderData?.billing_address?.address_line_2}
                      ,{" "}
                      {orderData?.shipping_address?.city ??
                        orderData?.billing_address?.city}
                      <br />
                      {orderData?.shipping_address?.province ??
                        orderData?.billing_address?.province}
                      ,{" "}
                      {orderData?.shipping_address?.country ??
                        orderData?.billing_address?.province}{" "}
                      -{" "}
                      {orderData?.shipping_address?.zipcode ??
                        orderData?.billing_address?.zipcode}
                    </address>
                  </div>

                  <div className="App">
                    <Modal
                      isOpen={isOpen}
                      onRequestClose={toggleModal}
                      contentLabel="My dialog"
                      className="mymodal"
                      overlayClassName="myoverlay"
                      closeTimeoutMS={500}
                      shouldCloseOnOverlayClick={false}
                    >
                      <button
                        onClick={toggleModal}
                        style={{ float: "right", paddingRight: 10 + "px" }}
                      >
                        <FaTimes />
                      </button>
                      <div className="reason-form">
                        <h2 className="order-details__title">Reason</h2>
                        <div>
                          <div className="reason-option">
                            <input
                              type="radio"
                              value="Reason 1"
                              id="r1"
                              checked={selectedReason === 'Reason 1'}
                              onChange={handleReasonChange}
                            />
                            <label htmlFor="r1">Reason 1</label>
                          </div>
                          <div className="reason-option">
                            <input
                              type="radio"
                              value="Reason 2"
                              id="r2"
                              checked={selectedReason === 'Reason 2'}
                              onChange={handleReasonChange}
                            />
                            <label htmlFor="r2">Reason 2</label>
                          </div>
                          <div className="reason-option">
                            <input
                              type="radio"
                              value="Reason 3"
                              id="r3"
                              checked={selectedReason === 'Reason 3'}
                              onChange={handleReasonChange}
                            />
                            <label htmlFor="r3">Reason 3</label>
                          </div>
                          <div className="reason-option">
                            <input
                              type="radio"
                              value="Reason 4"
                              id="r4"
                              checked={selectedReason === 'Reason 4'}
                              onChange={handleReasonChange}
                            />
                            <label htmlFor="r4">Reason 4</label>
                          </div>
                        </div>
                        <div className="d-flex justify-content-center"> <button onClick={() => cancle_order()} className="save-button">Save</button></div>
                      </div>
                    </Modal>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default OrderView;
