/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  NavLink,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";

import Home from "../DefaultLayout/DefaultLayout";
import TheShop from "../Modules/The Shop/TheShop";
import ContactUs from "../Modules/Contact Us/ContactUs";
import Login from "../containers/LoginContainer";
import ProductInfo from "../containers/ProductInfoContainer";
import Cart from "../containers/CartContainer";
import Product from "../Modules/Product/product";
import Search from "./Search";
import Lookbook from "../Modules/Lookbook/Lookbook";
import Checkout from "../Modules/Checkout/Checkout";
import AccountDetails from "../Modules/account-details/AccountDetails";
import PrivacyPolicy from "../Modules/privacy-policy/PrivacyPolicy";

import "../css/component.css";
import "../css/header.css";

import logo from "../assets/images/logo.png";
import cart from "../assets/images/shopping-cart.png";
import wishlist from "../assets/images/heart.png";
import search from "../assets/images/magnifiying-glass.png";
import user from "../assets/images/user.png";
import PaymentSuccess from "./PaymentSuccess";
import PaymentFailed from "../Modules/PaymentFailed/PaymentFailed";
import ScrollToTop from "./ScrollTop";

import { BsXLg } from "react-icons/bs";
import Dashboard from "../Modules/Dashboard/dashboard";
import Order from "../Modules/Order/Order";
import Address from "../Modules/Address/Address";
import Add_Address from "../Modules/Address/Add_Address";
import Edit_Address from "../Modules/Address/Edit_Address";
import ChangePassword from "../Modules/ChangePassword/ChangePassword";
import MyCoupon from "../Modules/MyPoints/MyPoints";

import OrderView from "../Modules/Order/OrderView";

import img1 from "../assets/images/Sugandh Gupta Logo Fashion Designer.jpg";
import img2 from "../assets/images/Sugandh Urban Appereal Logo - Sugandh Gupta.png";
import rozanaImg from "../assets/images/rozanan-image.png";
import Ramo2023 from "../assets/images/Ramo-2023-Images.jpg";
import Wishlist from "../containers/WishlistContainer";
import Collections from "../Modules/Collection/Collection";
import ViewPdf from "./ViewPdf";
import CollectionService from "../api-config/collection";
import Sale from "../Modules/Sale/sale";
import Brand from "../Modules/Brand/SugandhGupta";
import Blog from "../Modules/Blog/Blog";
import ViewBlog from "../Modules/Blog/ViewBlog";
import SugandhUrbaneApparels from "../Modules/Brand/SugandhUrbaneApparels";
import SugandhGupta from "../Modules/Brand/SugandhGupta";
import UserService from "../api-config/user";
import CartService from "../api-config/cart";
import WishlistService from "../api-config/wishlist";
import Register from "../Modules/Register/Register";
import ForgotPassword from "../Modules/ForgotPassword/ForgotPassword";
import ResetPassword from "../Modules/ResetPassword /ResetPassword";

const Header = (props) => {
  console.log(props);
  const [sugandhSubMenu, setSugandhSubMenu] = useState();
  const [urbanSubMenu, setUrbanSubMenu] = useState();
  const [isBrandMenuOpen, setIsBrandMenuOpen] = useState(false);
  const [isShopMenuOpen, setIsShopMenuOpen] = useState(false);
  const [isCollectionMenuOpen, setIsCollectionMenuOpen] = useState(false);
  const [collectionHeaderImg, setCollectionHeaderImg] = useState(rozanaImg);
  const [shopHeaderImg, setShopHeaderImg] = useState(rozanaImg);
  const auth_token = JSON.parse(sessionStorage.getItem("auth_key"));

  /* ----------------------------------------------------------------------------------------------------- */
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  // useEffect to check login status when component mounts
  useEffect(() => {
    const userInfo = localStorage.getItem("user_info");
    setIsLoggedIn(!!userInfo); // Convert userInfo to a boolean value
    getCollectionHeader()
    getShopHeader()
  }, []); // Empty dependency array ensures the effect runs only once

  /* ----------------------------------------------------------------------------------------------------- */

  useEffect(() => {
    getSugandhSubMenu();
    getUrbanSubMenu();

    const userData = JSON.parse(localStorage.getItem("user_info"));
    if (userData) {
      CartService.getByUserId(userData?.id).then((res) => {
        props?.addToCartHandler({
          totalCartItems: res.data.data?.items?.length ?? 0,
        });
      });

      WishlistService.getByUserId(userData?.id)
        .then((res) => {
          if (res.data.success) {
            props?.addToWishlistHandler({
              totalWishlistItems: res.data.data?.items?.length ?? 0,
            });
          }
        })
        .catch((e) => console.log(e));
    } else {
      const wishlistData = JSON.parse(localStorage?.getItem("wishlist_data"));
      const cartData = JSON.parse(localStorage.getItem("cart_data"));
      if (!wishlistData) {
        localStorage.setItem("wishlist_data", JSON.stringify([]));
      }
      if (!cartData) {
        localStorage.setItem("cart_data", JSON.stringify([]));
      }
      console.log(wishlistData);
      props?.addToWishlistHandler({
        totalWishlistItems: wishlistData?.length ?? 0,
      });
      props?.addToCartHandler({ totalCartItems: cartData?.length ?? 0 });
    }
  }, [auth_token]);

  const getSugandhSubMenu = () => {
    CollectionService.getByBrandId(2)
      .then((res) => {
        console.log("res", res);
        if (res.data.success) {
          setSugandhSubMenu(res.data.data);
        }
      })
      .catch((e) => console.log("error", e));
  };

  const getUrbanSubMenu = () => {
    CollectionService.getByBrandId(1)
      .then((res) => {
        console.log("res2", res);
        if (res.data.success) {
          setUrbanSubMenu(res.data.data);
        }
      })
      .catch((e) => console.log("error", e));
  };

  const getCollectionHeader = () => {
    CollectionService.getCollectionHeaderImg().then((res) => {
      setCollectionHeaderImg(res?.data?.data[0]?.image)
    })
      .catch((e) => console.log("error", e));
  }

  const getShopHeader = () => {
    CollectionService.getShopHeaderImg().then((res) => {
      setShopHeaderImg(res?.data?.data[0]?.image)
    })
      .catch((e) => console.log("error", e));
  }

  const toggleDropdown = (e) => {
    e.stopPropagation();
    setIsBrandMenuOpen(!isBrandMenuOpen);
  };

  const toggleShopDropdown = (e) => {
    e.stopPropagation();
    setIsShopMenuOpen(!isShopMenuOpen);
  };

  const toggleCollectionDropdown = (e) => {
    e.stopPropagation();
    setIsCollectionMenuOpen(!isCollectionMenuOpen);
  };

  // const handleClick = () => {
  //   const dropdown_menu = document.querySelector(".dropdown-menu-custom");
  //   dropdown_menu.classList.add('no-hover');
  // }

  const handleClick = () => {
    // Redirect to API endpoint
    // history.push('/api/endpoint');
    // Reload the website
    window.location.reload();
  };
  return (
    <>
      {/* <div className="application"></div> */}

      <div className="Pramocode">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              Join the Sugandh Family and avail 10% Discount on First Purchase.
              || USE COUPON : NEW10
            </div>
          </div>
        </div>
      </div>

      <div className="wrapper">
        <Router>
          {/* <Helmet>
            <meta charSet="utf-8" />
            <title>Sugandh Urban Apparels</title>
            <meta
              name="description"
              content="Sugandh Gupta, our founder firmly believes in the joy of doing what you love!Loves white. Loves minimalism. Her brain is a sustainable fibre dictionary."
            />
            <meta
              name="keywords"
              content="Sugandh Gupta in shop Bhavnagar, Sugandh Gupta "
            />
            <link rel="icon" type="image/png" href={logo} />
          </Helmet> */}

          <ScrollToTop>
            <nav className="navbar navbar-expand-lg fixed-top" id="navbarMain">
              <div className="container-fluid">
                <NavLink to="home">
                  <img
                    src={logo}
                    alt="brand-logo"
                    height={"40px"}
                    className="brand-logo"
                  />
                </NavLink>
                <button
                  className="navbar-toggler me-sm-5"
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasNavbar"
                  aria-controls="offcanvasNavbar"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div
                  className="offcanvas offcanvas-start"
                  data-bs-backdrop="false"
                  tabIndex="-1"
                  id="offcanvasNavbar"
                  aria-labelledby="offcanvasNavbarLabel"
                >
                  <div className="offcanvas-header pb-0 pt-3">
                    <NavLink to="home">
                      <img
                        src={logo}
                        alt=""
                        className="img-fluid"
                        id="offcanvasNavbarLabel"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                      />
                    </NavLink>
                    <div
                      className="container-fluid
                   d-flex justify-content-end align-items-center"
                    >
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                      >
                        <BsXLg />
                      </button>
                    </div>
                  </div>
                  <hr className="d-block d-lg-none" />

                  <div className="offcanvas-body py-0">
                    <ul className="d-block d-lg-none mb-5">
                      <li className="mx-2">
                        <Search />
                      </li>
                    </ul>
                    <ul className="navbar-nav justify-content-end flex-grow-1">
                      <li className="nav-item mx-2">
                        <span data-bs-dismiss="offcanvas" aria-label="Close">
                          <NavLink
                            to="home/"
                            className="nav-link fs-6 cool-link"
                          >
                            Home
                          </NavLink>
                        </span>
                      </li>

                      <li className="nav-item mx-2 our-brands-menu d-none d-lg-flex">
                        <span data-bs-dismiss="offcanvas" aria-label="Close">
                          <button
                            // to={`our-brands/sugandh-urbane-apparels`}
                            className="nav-link fs-6 cool-link"
                          >
                            Our Brands
                          </button>
                        </span>
                        <div className="our-brands-sub-menu">
                          <div className="row">
                            <div className="col-2 animate-fadeInUp">
                              <ul>
                                <li>
                                  <a
                                    href={`/our-brands/sugandh-urbane-apparels`}
                                  >
                                    <img
                                      src={img2}
                                      alt="SUGANDH URBANE APPARELS"
                                    />
                                  </a>
                                </li>
                              </ul>
                              <p>SUGANDH URBANE APPARELS</p>
                            </div>
                            <div className="col-2 animate-fadeInUp">
                              <ul>
                                <li>
                                  <a
                                    href={`/our-brands/sugandh-gupta`}
                                    state={{ brand_id: 2 }}
                                  >
                                    <img src={img1} alt="SUGANDH GUPTA" />
                                  </a>
                                </li>
                              </ul>
                              <p>SUGANDH GUPTA</p>
                            </div>
                          </div>
                        </div>
                      </li>

                      <li className="nav-item mx-2 our-brands-menu d-block d-lg-none">
                        <span className="d-flex justify-content-between">
                          <button
                            // to={`our-brands/sugandh-urbane-apparels`}
                            className="nav-link fs-6 cool-link"
                          >
                            Our Brands
                          </button>
                          <p
                            onClick={toggleDropdown}
                            style={{
                              fontSize: "30px",
                              margin: "0px 13px",
                              lineHeight: "32px",
                              letterSpacing: "-1px",
                            }}
                          >
                            {isBrandMenuOpen ? "--" : "+"}
                          </p>
                        </span>

                        {isBrandMenuOpen && (
                          <div className="dropdown-list mobile-view animate-fadeInUp">
                            <ul>
                              <li data-bs-dismiss="offcanvas">
                                <NavLink
                                  to={`our-brands/sugandh-urbane-apparels`}
                                >
                                  <img
                                    src={img2}
                                    alt="SUGANDH URBANE APPARELS"
                                  />
                                </NavLink>
                              </li>
                              <li className="ms-5" data-bs-dismiss="offcanvas">
                                <NavLink
                                  // className="cool-link"
                                  to={`/our-brands/sugandh-gupta`}
                                  state={{ brand_id: 2 }}
                                >
                                  <img src={img1} alt="SUGANDH GUPTA" />
                                </NavLink>
                              </li>
                            </ul>
                          </div>
                        )}
                      </li>

                      <li className="nav-item mx-2 shop-menu d-none d-lg-flex">
                        <span data-bs-dismiss="offcanvas" aria-label="Close">
                          <NavLink
                            to="the-shop"
                            className="nav-link fs-6 cool-link"
                          >
                            Shop
                          </NavLink>
                        </span>
                        <div className="shop-sub-menu">
                          <div className="row">
                            <div className="col-3 animate-fadeInUp">
                              <h6>Sugandh Urbane Apparels</h6>
                              <ul>
                                <li>
                                  <a
                                    className="cool-link"
                                    href={`/the-shop/${"Co-Ords"}`}
                                  >
                                    Co-Ords
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className="cool-link"
                                    href={`/the-shop/${"Kurta Set"}`}
                                  >
                                    Kurta Set
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className="cool-link"
                                    href={`/the-shop/${"Dresses & Maxi"}`}
                                  >
                                    Dresses & Maxi
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className="cool-link"
                                    href={`/the-shop/${"Tops"}`}
                                  >
                                    Tops
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className="cool-link"
                                    href={`/the-shop/${"Kurta"}`}
                                  >
                                    Kurta
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <div className="col-2 animate-fadeInUp">
                              <h6>SUGANDH GUPTA</h6>
                              <ul>
                                <li>
                                  <a
                                    className="cool-link"
                                    href={`/the-shop/${"Anarkali Set"}`}
                                  >
                                    Anarkali Set
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className="cool-link"
                                    href={`/the-shop/${"Kaftans"}`}
                                  >
                                    Kaftans
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className="cool-link"
                                    href={`/the-shop/${"Kurtas Set"}`}
                                  >
                                    Kurtas Set
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className="cool-link"
                                    href={`/the-shop/${"Gowns"}`}
                                  >
                                    Gowns
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className="cool-link"
                                    href={`/the-shop/${"Lehengas"}`}
                                  >
                                    Lehengas
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className="cool-link"
                                    href={`/the-shop/${"Sarees"}`}
                                  >
                                    Sarees
                                  </a>
                                </li>
                              </ul>
                            </div>

                            <div className="col-3 animate-fadeInUp">
                              <ul>
                                <li>
                                  <a href={`/the-shop/collections/Rozana`}>
                                    <img src={shopHeaderImg} alt="Rozana" />
                                  </a>
                                  {/* <NavLink
                                    className="link"
                                    to={`/the-shop/collections/Rozana`}
                                    state={{ collection_id: 14 }}
                                  >
                                    <img src={rozanaImg} alt="Rozana" />
                                    Rozana
                                  </NavLink> */}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </li>

                      <li className="nav-item mx-2 shop-menu d-block d-lg-none">
                        <span className="d-flex justify-content-between">
                          <NavLink
                            to="the-shop"
                            className="nav-link fs-6 cool-link"
                          >
                            <span
                              data-bs-dismiss="offcanvas"
                              aria-label="Close"
                            >
                              Shop
                            </span>
                          </NavLink>
                          <p
                            onClick={toggleShopDropdown}
                            style={{
                              fontSize: "30px",
                              margin: "0px 13px",
                              lineHeight: "32px",
                              letterSpacing: "-1px",
                            }}
                          >
                            {isShopMenuOpen ? "--" : "+"}
                          </p>
                        </span>
                        {isShopMenuOpen && (
                          <div className="dropdown-list mobile-view animate-fadeInUp">
                            <ul>
                              <li>
                                <div>
                                  <h6>Sugandh Urbane Apparels</h6>
                                  <ul>
                                    <li>
                                      <a
                                        className="cool-link"
                                        href={`/the-shop/${"Dresses & Maxi"}`}
                                      >
                                        Dresses & Maxi
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="cool-link"
                                        href={`/the-shop/${"Tops"}`}
                                      >
                                        Tops
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="cool-link"
                                        href={`/the-shop/${"Kurta"}`}
                                      >
                                        Kurta
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="cool-link"
                                        href={`/the-shop/${"Kurta Set"}`}
                                      >
                                        Kurta Set
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="cool-link"
                                        href={`/the-shop/${"Co-Ords"}`}
                                      >
                                        Co-Ords
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                              <li>
                                <div className="mt-3">
                                  <h6>SUGANDH GUPTA</h6>
                                  <ul>
                                    <li>
                                      <a
                                        className="cool-link"
                                        href={`/the-shop/${"Anarkali Set"}`}
                                      >
                                        Anarkali Set
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="cool-link"
                                        href={`/the-shop/${"Kaftans"}`}
                                      >
                                        Kaftans
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="cool-link"
                                        href={`/the-shop/${"Kurtas Set"}`}
                                      >
                                        Kurtas Set
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="cool-link"
                                        href={`/the-shop/${"Gowns"}`}
                                      >
                                        Gowns
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="cool-link"
                                        href={`/the-shop/${"Lehengas"}`}
                                      >
                                        Lehengas
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="cool-link"
                                        href={`/the-shop/${"Sarees"}`}
                                      >
                                        Sarees
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                            </ul>
                          </div>
                        )}
                        {/* <div className="shop-sub-menu">
                          <div className="row">
                            <div className="col-3 animate-fadeInUp">
                              <h6>
                                <a
                                  className="cool-link"
                                  href={`/the-shop/${1}`}
                                >
                                  Sugandh Urbane Apparels
                                </a>
                              </h6>
                              <ul>
                                <li>
                                  <a
                                    className="cool-link"
                                    href={`/the-shop/${"Dresses & Maxi"}`}
                                  >
                                    Dresses & Maxi
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className="cool-link"
                                    href={`/the-shop/${"Tops"}`}
                                  >
                                    Tops
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className="cool-link"
                                    href={`/the-shop/${"Kurta"}`}
                                  >
                                    Kurta
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className="cool-link"
                                    href={`/the-shop/${"Kurta Set"}`}
                                  >
                                    Kurta Set
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className="cool-link"
                                    href={`/the-shop/${"Co-Ords"}`}
                                  >
                                    Co-Ords
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <div className="col-2 animate-fadeInUp">
                              <h6>
                                <a
                                  className="cool-link"
                                  href={`/the-shop/${2}`}
                                >
                                  SUGANDH GUPTA
                                </a>
                              </h6>
                              <ul>
                                <li>
                                  <a
                                    className="cool-link"
                                    href={`/the-shop/${"Anarkali Set"}`}
                                  >
                                    Anarkali Set
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className="cool-link"
                                    href={`/the-shop/${"Kaftans"}`}
                                  >
                                    Kaftans
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className="cool-link"
                                    href={`/the-shop/${"Kurta Set"}`}
                                  >
                                    Kurtas Set
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className="cool-link"
                                    href={`/the-shop/${"Gowns"}`}
                                  >
                                    Gowns
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className="cool-link"
                                    href={`/the-shop/${"Lehengas"}`}
                                  >
                                    Lehengas
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className="cool-link"
                                    href={`/the-shop/${"Sarees"}`}
                                  >
                                    Sarees
                                  </a>
                                </li>
                              </ul>
                            </div>

                            <div className="col-3 animate-fadeInUp">
                              <ul>
                                <li>
                                  <NavLink
                                    className="link"
                                    to={`/the-shop/collections/Rozana`}
                                    state={{ collection_id: 14 }}
                                  >
                                    <img src={rozanaImg} alt="Rozana" />
                                    Rozana
                                  </NavLink>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div> */}
                      </li>

                      <li className="nav-item mx-2 collection-menu d-none d-lg-flex">
                        <span data-bs-dismiss="offcanvas" aria-label="Close">
                          <NavLink
                            to="collections"
                            className="nav-link fs-6 cool-link"
                          >
                            Collections
                          </NavLink>
                        </span>
                        <div className="collection-sub-menu">
                          <div className="row">
                            <div className="col-3 animate-fadeInUp">
                              <h6>Sugandh Urbane Apparels</h6>
                              <ul>
                                {urbanSubMenu?.map((item, i) => {
                                  return (
                                    <li key={i}>
                                      <a
                                        className="cool-link"
                                        href={`/the-shop/collections/${item?.name}`}
                                        // state={{ collection_id: item?.id }}
                                        hidden={
                                          item?.name === "Rozana" ||
                                          item?.name === "Rozana 2.0"
                                        }
                                      >
                                        {item?.name}
                                      </a>
                                    </li>
                                  );
                                })}
                                <li>
                                  <a
                                    className="cool-link"
                                  >
                                    Rozana
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <div className="col-2 animate-fadeInUp">
                              <h6>Sugandh Gupta</h6>
                              <ul>
                                {sugandhSubMenu?.map((item, i) => {
                                  <div>{item}</div>;
                                  return (
                                    <li key={i}>
                                      <a
                                        className="cool-link"
                                        href={`/the-shop/collections/${item?.name}`}
                                      // state={{ collection_id: item?.id }}
                                      // onClick={handleClick}
                                      >
                                        {item?.name}
                                      </a>
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>
                            <div className="col-3 animate-fadeInUp">
                              <ul>
                                <li>
                                  <a href={`/the-shop/collections/Rozana`}>
                                    <img src={collectionHeaderImg} alt="Rozana" />
                                    {/* Rozana */}
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </li>

                      <li className="nav-item mx-2 collection-menu d-block d-lg-none">
                        <span className="d-flex justify-content-between">
                          <NavLink
                            to="collections"
                            className="nav-link fs-6 cool-link"
                          >
                            <span
                              data-bs-dismiss="offcanvas"
                              aria-label="Close"
                            >
                              Collections
                            </span>
                          </NavLink>
                          <p
                            onClick={toggleCollectionDropdown}
                            style={{
                              fontSize: "30px",
                              margin: "0px 13px",
                              lineHeight: "32px",
                              letterSpacing: "-1px",
                            }}
                          >
                            {isCollectionMenuOpen ? "--" : "+"}
                          </p>
                        </span>
                        {isCollectionMenuOpen && (
                          <div className="dropdown-list mobile-view animate-fadeInUp">
                            <ul>
                              <li>
                                <div className="animate-fadeInUp">
                                  <h6>Sugandh Urbane Apparels </h6>
                                  <ul>
                                    {urbanSubMenu?.map((item, i) => {
                                      return (
                                        <li key={i}>
                                          <NavLink
                                            className="cool-link"
                                            to={`/the-shop/collections/${item?.name}`}
                                            state={{ collection_id: item?.id }}
                                            hidden={
                                              item?.name === "Rozana" ||
                                              item?.name === "Rozana 2.0"
                                            }
                                          >
                                            <span
                                              data-bs-dismiss="offcanvas"
                                              aria-label="Close"
                                            >
                                              {item?.name}
                                            </span>
                                          </NavLink>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </div>
                              </li>
                              <li className="mt-3">
                                <div className="animate-fadeInUp">
                                  <h6>Sugandh Gupta</h6>
                                  <ul>
                                    {sugandhSubMenu?.map((item, i) => {
                                      return (
                                        <li key={i}>
                                          <NavLink
                                            className="cool-link"
                                            to={`/the-shop/collections/${item?.name}`}
                                            state={{ collection_id: item?.id }}
                                          >
                                            <span
                                              data-bs-dismiss="offcanvas"
                                              aria-label="Close"
                                            >
                                              {item?.name}
                                            </span>
                                          </NavLink>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </div>
                              </li>
                              <li className="mt-3">
                                <div className="animate-fadeInUp">
                                  <ul>
                                    <li>
                                      <NavLink
                                        className="link"
                                        to={`/the-shop/collections/Rozana`}
                                        state={{ collection_id: [14, 13] }}
                                      >
                                        <h6>Rozana</h6>
                                        <img src={rozanaImg} alt="Rozana" />
                                      </NavLink>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                            </ul>
                          </div>
                        )}
                      </li>

                      <li className="nav-item mx-2">
                        <span data-bs-dismiss="offcanvas" aria-label="Close">
                          <NavLink
                            to="sale"
                            className="nav-link fs-6 cool-link"
                          >
                            Sale
                          </NavLink>
                        </span>
                      </li>

                      <li className="nav-item mx-2 blog-menu">
                        <span data-bs-dismiss="offcanvas" aria-label="Close">
                          <NavLink
                            to="/blogs"
                            className="nav-link fs-6 cool-link"
                          >
                            Blog
                          </NavLink>
                        </span>
                      </li>

                      <li className="nav-item mx-2">
                        <span data-bs-dismiss="offcanvas" aria-label="Close">
                          <NavLink
                            to="lookbook"
                            className="nav-link fs-6 cool-link"
                          >
                            LookBook
                          </NavLink>
                        </span>
                      </li>

                      <li className="nav-item mx-2">
                        <span data-bs-dismiss="offcanvas" aria-label="Close">
                          <NavLink
                            to="contact-us"
                            className="nav-link fs-6 cool-link"
                          >
                            Contact Us
                          </NavLink>
                        </span>
                      </li>
                    </ul>

                    <ul className="navbar-nav justify-content-end flex-grow-1 d-none d-lg-flex">
                      <li className="nav-item mx-2 ">
                        <span data-bs-dismiss="offcanvas" aria-label="Close">
                          <Search />
                        </span>
                      </li>

                      <li className="nav-item mx-2">
                        <span data-bs-dismiss="offcanvas" aria-label="Close">
                          {/* NavLink for navigation based on isLoggedIn state */}
                          <a
                            href={
                              isLoggedIn ? "/user/account-details" : "/login"
                            }
                            className="nav-link"
                          >
                            {/* Text or content of NavLink based on isLoggedIn state */}
                            {isLoggedIn ? (
                              <img
                                src={user}
                                className="icon"
                                alt="User Profile"
                              />
                            ) : (
                              <img
                                src={user}
                                className="icon"
                                alt="User Profile"
                              />
                            )}
                          </a>
                        </span>
                      </li>

                      {/* ----------------------------------------------------------------------------------------------------- */}

                      <li className="nav-item mx-2">
                        <span data-bs-dismiss="offcanvas" aria-label="Close">
                          <NavLink to="cart" className="nav-link">
                            <img src={cart} className="icon cart" alt="Cart" />
                            <p className="cart-value mb-0 d-none d-sm-block">
                              {props?.data ?? 0}
                            </p>
                          </NavLink>
                        </span>
                      </li>

                      <li className="nav-item mx-2">
                        <span data-bs-dismiss="offcanvas" aria-label="Close">
                          <NavLink to="wishlist" className="nav-link">
                            <img
                              src={wishlist}
                              className="icon "
                              alt="Wishlist"
                            />
                            <p className="wishlist-value mb-0">
                              {props?.wishlistCount ?? 0}
                            </p>
                          </NavLink>
                        </span>
                      </li>
                    </ul>

                    <ul className="navbar-nav mobile-nav-icons justify-content-end flex-grow-1 d-flex d-lg-none mt-4">
                      {/* <li className="mx-2">
                        <span data-bs-dismiss="offcanvas" aria-label="Close">
                          <NavLink to="login" className="nav-link">
                            <span className="d-flex justify-content-between">
                              <span>User Profile</span>
                              <img
                                src={user}
                                className="icon cart"
                                alt="User Profile"
                              />
                            </span>
                          </NavLink>
                        </span>
                      </li> */}

                      {/* ----------------------------------------------------------------------------------------------------- */}

                      <li className="mx-2">
                        <span data-bs-dismiss="offcanvas" aria-label="Close">
                          <a
                            href={
                              isLoggedIn ? "/user/account-details" : "/login"
                            }
                            className="nav-link"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <span>User Profile</span>

                            <span>
                              {isLoggedIn ? (
                                <img
                                  src={user}
                                  className="icon"
                                  alt="User Profile"
                                />
                              ) : (
                                <img
                                  src={user}
                                  className="icon"
                                  alt="User Profile"
                                />
                              )}
                            </span>
                          </a>
                        </span>
                      </li>

                      {/* ----------------------------------------------------------------------------------------------------- */}

                      <li className="mx-2">
                        <span data-bs-dismiss="offcanvas" aria-label="Close">
                          <NavLink to="cart" className="nav-link">
                            <span className="d-flex justify-content-between">
                              <span>Cart</span>
                              <img
                                src={cart}
                                className="icon cart"
                                alt="Cart"
                              />
                              {/* <p className="cart-value d-none d-sm-block" >
                                {props?.data?.totalCartData}
                              </p> */}
                            </span>
                          </NavLink>
                        </span>
                      </li>

                      <li className="mx-2">
                        <span data-bs-dismiss="offcanvas" aria-label="Close">
                          <NavLink to="wishlist" className="nav-link">
                            <span className="d-flex justify-content-between">
                              <span>Wishlist</span>
                              <img
                                src={wishlist}
                                className="icon cart"
                                alt="Wishlist"
                              />
                            </span>
                          </NavLink>
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </nav>

            <Routes>
              <Route path="" element={<Home />} />
              <Route path="home" element={<Home />} />
              <Route path="about-us" element={<PrivacyPolicy />} />
              <Route path="the-shop" element={<TheShop />} />
              <Route path="the-shop/:id" element={<TheShop />} />
              <Route path="the-shop/:brand_name" element={<TheShop />} />
              <Route
                path="the-shop/collections/:collection_name"
                element={<TheShop />}
              />
              <Route path="/collections" element={<Collections />} />
              <Route path="contact-us" element={<ContactUs />} />
              <Route path="Login" element={<Login />} />
              <Route path="sign-up" element={<Register />} />
              <Route path="Search" element={<Search />} />
              <Route path="Product" element={<Product />} />
              <Route path="lookbook" element={<Lookbook />} />
              <Route path="lookbook/:collection_name" element={<Lookbook />} />
              <Route
                path="product-info/:sub_category/:product_name"
                element={<ProductInfo />}
              />
              <Route
                path="product-info/:product_name"
                element={<ProductInfo />}
              />
              <Route path="cart" element={<Cart />} />
              <Route path="paymentsuccess" element={<PaymentSuccess />} />
              <Route path="paymentfailed" element={<PaymentFailed />} />
              <Route path="checkout" element={<Checkout />} />
              <Route path="userProfile" element={<Dashboard />} />
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="order" element={<Order />} />
              <Route path="order-view" element={<OrderView />} />
              <Route path="user/account-details" element={<AccountDetails />} />
              <Route path="address" element={<Address />} />
              <Route path="add-address" element={<Add_Address />} />
              <Route path="edit-address" element={<Edit_Address />} />
              <Route path="change-password" element={<ChangePassword />} />
              <Route path="my-points" element={<MyCoupon />} />

              <Route path="privacy-policy" element={<PrivacyPolicy />} />
              <Route path="terms-and-conditions" element={<PrivacyPolicy />} />
              <Route path="returns" element={<PrivacyPolicy />} />
              <Route path="delivery-and-returns" element={<PrivacyPolicy />} />
              <Route path="wishlist" element={<Wishlist />} />
              <Route
                path="lookbook/:collection_name/view-pdf"
                element={<ViewPdf />}
              />
              <Route path="sale" element={<Sale />} />
              {/* <Route path="our-brands" element={<Brand/>} /> */}
              <Route
                path="our-brands/sugandh-urbane-apparels"
                element={<SugandhUrbaneApparels />}
              />
              <Route
                path="forgot-password"
                element={<ForgotPassword />}
              />
              <Route
                path="reset-password"
                element={<ResetPassword />}
              />
              <Route
                path="our-brands/sugandh-gupta"
                element={<SugandhGupta />}
              />
              <Route path="blogs" element={<Blog />} />
              <Route path="blogs/view-blog" element={<ViewBlog />} />
            </Routes>
          </ScrollToTop>
        </Router>
      </div>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default Header;
