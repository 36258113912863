import http from "../environment";

const sendOTPEmail = (email_id) => {
  console.log(email_id);
  return http.get(`user/email/send/otp?email_id=${email_id}`);
};

const verifyOTPEmail = (email_id, code) => {
  return http.get(`user/email/verify/otp?email_id=${email_id}&code=${code}`);
};

const sendOTPPhone = (number) => {
  return http.get(`user/send/otp?channel=sms&contact_num=${number}`);
};

const verifyOTPPhone = (number, otp) => {
  return http.get(`user/sms/verify/otp?code=${otp}&contact_num=${number}`);
};

const resetPassword = (data) => {
  return http.post(`user/password/reset`, data);
};

const sendEmail = (data) => {
  return http.post(`/user/password/forgot`, data);
};

const resetPasswordV2 = (data) => {
  return http.post(`user/password/resetV2`, data);
};

const ForgotPasswordService = {
  sendOTPEmail,
  verifyOTPEmail,
  sendOTPPhone,
  verifyOTPPhone,
  resetPassword,
  sendEmail,
  resetPasswordV2
};

export default ForgotPasswordService;
