// src/components/Notification.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import "../css/notification.css";

const Notification = ({ products, onClose }) => {
    const totalAmount = products.reduce((total, product) => total + product.mrp, 0);
    const navigate = useNavigate();

    return (
        <div className="notification">
            <div className='close-btn-perent'><button className="close-btn" onClick={onClose}>×</button></div>
            <div className='notification_child'>
            {products.map((product, index) => (
                <div key={index} className="product-item">
                    <img src={product.image} alt={product.product_name} />
                    <div className="product-details">
                        <div className='product_title'>{product.product_name}</div>
                        <div className='product_detail'><span className='color_text_of_detail'>Size :</span> {product.size}</div>
                        <div className='product_detail'><span className='color_text_of_detail'>Quantity :</span> {product.quantity}</div>
                        <div className='product_detail'><span className='color_text_of_detail'>Price :</span> ₹{product.mrp}</div>
                    </div>
                </div>
            ))}
            </div>
            {/* <div className="total">
        <h3>Total: ₹{totalAmount}</h3>
      </div> */}
            <div className="proceed-to-checkout">
                <p
                    className="checkout-button button alt wc-forward bg-dark"
                    onClick={() => navigate("/cart")}
                >
                    View
                </p>
            </div>    </div>
    );
};

export default Notification;
