import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./brand.css";
import logo2 from "../../assets/images/Sugandh Urban Appereal Logo - Sugandh Gupta.png";
import handcrafted from "../../assets/images/handicraft.png";
import recycle1 from "../../assets/images/recycle1.png";
import recycle2 from "../../assets/images/recycle2.jpeg";
import ourMission from "../../assets/images/our mission ua.5ad18cf5985f87613a1b.jpeg";
import ourVision from "../../assets/images/our vision ua.jpeg";
import { Helmet } from "react-helmet";
import logo from "../../assets/images/logo.png";

const SugandhUrbaneApparels = () => {
  const navigate = useNavigate();

  const shopNow = () => {
    navigate(`/the-shop/${"Sugandh Urbane Apparels"}`, {
      state: { brand_id: 1 },
    });
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Sugandh Urban Apparels</title>
        <meta
          name="description"
          content="Sugandh Urbane Apparels is a one-stop-shop for ethical and sustainable brands from all corners of the world."
        />
        <meta
          name="keywords"
          content="Sugandh Gupta in shop Bhavnagar, Sugandh Gupta "
        />
        <link rel="icon" type="image/png" href={logo} />
      </Helmet>
      <div className="brands sugandh_urbane_apparels">
        <div className="container">
          <div className="row">
            <div className="brand-content">
              <div className="row">
                <div className="col-12 col-lg-3">
                  <img
                    src={logo2}
                    className="brand-logo"
                    alt="Sugandh Urban Appereal"
                  />
                </div>
                <div className="col-12 col-lg-9">
                  <h1>Sugandh Urbane Apparels</h1>
                  <p>
                    Urbane Apparels is a one-stop-shop for ethical and
                    sustainable brands from all corners of the world. We pride
                    ourselves on being transparent about our brands with our
                    customers whilst providing high-quality clothes that are
                    made to last, using earth-friendly and biodegradable
                    materials. We are also a zero-waste and plastic free
                    business aiming to be an example for other businesses within
                    the fashion industry. It is also driven by her need to prove
                    that style has no size and to make ethically produced
                    clothing of all sizes available to people everywhere .
                  </p>
                  <button
                    type="button"
                    className="btn float-end mt-5"
                    onClick={() => shopNow()}
                  >
                    Shop Sugandh Urbane Apparels
                  </button>
                </div>
              </div>
            </div>

            <div className="outcuts">
              <div className="row">
                <div className="col-12 col-md-7 order-lg-0 order-">
                  <h3>ZERO-WASTE INITIATIVE </h3>
                  <p>
                    We are currently working on some projects to give our
                    offcuts a new life. When cutting the fabrics there happen to
                    be some offcuts. Also, sometimes we use less fabric than we
                    thought we would need. Why not use it? Therefore, we decided
                    that it would be cool to make accessories or notebook covers
                    or scrunchies out of the leftovers of our fabrics. It’s the
                    little things that sum up to big results in reducing impact.
                  </p>
                  <p className="mt-5">#NoWastePolicy #UpcycledProducts</p>
                </div>
                <div className="col-12 col-md-5 d-flex">
                  <img src={recycle1} />
                  <img src={recycle2} />
                </div>
              </div>
            </div>

            <div className="handcrafted">
              <div className="row">
                <div className="col-12 col-md-4">
                  <h3 className="d-block d-lg-none mb-4">HANDCRAFTED</h3>
                  <img
                    src={handcrafted}
                    className="img-fluid"
                    alt="handcrafted"
                  />
                </div>
                <div className="col-12 col-md-8">
                  <h3 className="d-none d-lg-block">HANDCRAFTED</h3>
                  <p className="mt-4 mt-sm-0">
                    Unique by definition, handcrafted pieces are where fashion
                    gets personal. With quality workmanship front and centre,
                    these items help foster an appreciation for craft and
                    artistry, as well as illuminate the connection between the
                    things we wear and the people who made them. More
                    environmentally friendly than mass-produced items and not
                    nearly as ubiquitous, handmade goods are exquisite tools in
                    the slow fashion arsenal. When you buy a handcrafted
                    product, you’re supporting a family directly, that’s what it
                    is all about.
                  </p>
                </div>
              </div>
            </div>

            <div className="our-vision">
              <div className="row">
                <div className="col-12 col-md-7">
                  <h3>OUR VISION</h3>
                  <p>
                    When you choose to buy from us, you support a business with
                    a soul, a business that cares about more than just profit, a
                    business that has been making people lives better at its
                    core. Oh and also, every time you buy from us, we do a happy
                    dance. That's our only vision and that's all we care about.
                  </p>
                </div>
                <div className="col-12 col-md-5">
                  <img src={ourVision} className="img-fluid" alt="our-vision" />
                </div>
              </div>
            </div>

            <div className="our-mission">
              <div className="row">
                <div className="col-12 col-md-5">
                  <h3 className="d-block d-lg-none mb-4">OUR MISSION</h3>
                  <img
                    src={ourMission}
                    className="img-fluid"
                    alt="our-mission"
                  />
                </div>
                <div className="col-12 col-md-7">
                  <h3 className="d-none d-lg-block">OUR MISSION</h3>
                  <p className="mt-4 mt-sm-0">
                    Our mission is to change the way people shop by educating
                    our growing community about how they are making a positive
                    difference to the world. The future of our planet is vital
                    for all which is why we donate to selected charities with
                    every purchase made in our store, it’s our way of giving
                    back.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SugandhUrbaneApparels;
