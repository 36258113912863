import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { FaQuoteLeft, FaQuoteRight, FaAngleLeft } from "react-icons/fa";
import './blog.css';

const ViewBlog = () => {
    const location = useLocation();
    const [blogData, setBlogData] = useState();

    useEffect(() => {
        setBlogData(location?.state?.blog_data);
    }, [location?.state?.blog_data]);

    return (
        <div className="view-blog">
            {/* <h1>View Blog</h1> */}
            <div className="container">
            <div className="back-to">
                        <a
                            className="button outlined btn-sm"
                            href="/blogs"
                            title="Back to Shop"
                        >
                            <i className="bi bi-chevron-left">
                                <FaAngleLeft className="mb-2" style={{ fontSize: 20 + "px" }} />
                            </i>
                            Back to Blog List
                        </a>
                    </div>
                    <br/>
                <div className="blog-content">
                    
                    <img src={blogData?.thumbnail_image} className="img-fluid" alt={blogData?.name} />

                    <div className="row mt-5">
                        <FaQuoteLeft className="col-1" />
                        <p className="col-10">
                            {blogData?.description}
                        </p>
                        <FaQuoteRight className="col-1" />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ViewBlog;