import React, { useEffect, useState } from "react";
import { useNavigate, useHistory } from "react-router-dom";
import { NavLink, Route, Routes } from "react-router-dom";
import "../User Profile/UserProfile.css";
import UserProfile from "../User Profile/UserProfile";
import UserService from "../../api-config/user";
import { toast, ToastContainer } from "react-toastify";
import { IoIosLogOut } from "react-icons/io";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import "./Address.css";
import UtilsService from "../../api-config/utils";
const Address = () => {
  const user_info = JSON.parse(localStorage.getItem("user_info"));
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const handClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [province, setProvince] = useState();
  const [city, setCity] = useState();
  const [zipcode, setZipcode] = useState();

  // const [addressData, setAddressData] = useState();

  const getProvince = (countryId, provinceId) => {
    UtilsService.getProvince(countryId, provinceId)
      .then((res) => {
        res.data.data.map((data) => {
          if (data.id == provinceId) {
            setProvince(data.name);
          }
        })
      })
      .catch((e) => console.log(e));
  };

  const getCity = (provinceId, city_id) => {
    UtilsService.getCity(provinceId)
      .then((res) => {
        res.data.data.map((data) => {
          if (data.id == city_id) {
            setCity(data.name);
          }
        })
      })
      .catch((e) => console.log(e));
  };

  // const getZipcode = (cityId) => {
  //   UtilsService.getZipcode(cityId)
  //     .then((res) => {
  //       setZipcode(res.data.data);
  //     })
  //     .catch((e) => console.log(e));
  // };

  useEffect(() => {
    // UserService.getById().then((res) => {
    //   setAddressData(res.data.data);
    // });
    user_info?.province_id && user_info?.city_id && getCity(user_info?.province_id, user_info?.city_id)
    user_info?.country_id && getProvince(user_info?.country_id, user_info?.province_id)
    console.log("hello", user_info);
  }, []);

  const addAddress = () => {
    navigate("/add-address");
  };

  const editAddress = () => {
    navigate("/edit-address");
  };

  const deleteAddress = () => {
    const formData = new FormData();
    formData.append("id", user_info?.id);
    formData.append("first_name", user_info.first_name);
    formData.append("last_name", user_info.last_name);
    formData.append("email_id", user_info.email_id);
    formData.append("password", user_info.password);
    // formData.append("dob", user_info.dob);
    formData.append("primary_contact_number", user_info.primary_contact_number);
    formData.append("address_line_1", null);
    formData.append("address_line_2", null);
    formData.append("country_id", null);
    formData.append("province_id", null);
    formData.append("city_id", null);
    formData.append("zipcode_id", null);
    formData.append("country", null);
    formData.append("province", null);
    formData.append("city", null);
    formData.append("zipcode", null);
    formData.append("is_web_signin", user_info.is_web_signin);
    formData.append("user_role_id", user_info.user_role_id);
    UserService.updateUserDetails(formData)
      .then((res) => {
        if (res.data.success) {
          localStorage.setItem("user_info", JSON.stringify(res.data.data));
          window.location.reload();
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((e) => console.log(e));
  }

  return (
    <>
      <div className="account-details">
        <div className="my-account">
          <div className="container">
            <div className="post-content row">
              <div className="col-xl-3">
                <UserProfile />
              </div>

              <div className="col-xl-9">
                <div className="row">
                  <div className="hero-header-container">
                    <div className="col-lg-12 products-header">
                      <h1 className="page-title">Addresses</h1>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12">
                    <div className="order-table-body">
                      <button className="view-button" onClick={addAddress}>
                        Add Address
                      </button>
                    </div>
                  </div>
                </div>

                <div className="addresses-content">
                  <div className="row">
                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                      <div className="card">
                        <div className="card-body">
                          <h4>{user_info?.first_name} {user_info?.last_name}</h4>
                          <div className="card-text">
                            {user_info?.address_line_1 && <p>{user_info?.address_line_1},</p>}
                            {user_info?.address_line_2 && <p>{user_info?.address_line_2},</p>}
                            {user_info?.address_line_1 && <p>{city} , {province} - {user_info?.zipcode}.</p>}
                            <p>Phone No : {user_info?.primary_contact_number}</p>
                          </div>
                          <div className="cart-but-edit">
                            <button onClick={editAddress} className="btn">
                              Edit
                            </button>
                            {user_info?.address_line_1 && <button onClick={handleShow} className="btn">
                              Delete
                            </button>}
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                      <div className="card">
                        <div className="card-body">
                          <h4>Anisha Ratwani</h4>
                          <div className="card-text">
                            <p>4 vrajbhumi Bunglow,</p>
                            <p>Near Raj Farms,</p>
                            <p>Bhat, Gandhinagar - 382428.</p>
                            <p>Phone No : +91 7016014811</p>
                          </div>
                          <div className="cart-but-edit">
                            <button onClick={editAddress} className="btn">
                              Edit
                            </button>
                            <button onClick={handleShow} className="btn">
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>

                <Modal
                  className="modallogin"
                  show={show}
                  onHide={handClose}
                  backdrop="static"
                  keyboard={false}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Delete!</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <IoIosLogOut />
                    <br />
                    Are you sure <br />
                    you want to Delete ?
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      className="btn button-modal"
                      closeButton
                      onClick={() => {
                        deleteAddress()
                      }}
                    >
                      Yes
                    </Button>
                    <Button
                      className="btn button-modal-white"
                      onClick={handClose}
                    >
                      No
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Address;
