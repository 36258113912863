import http from "../environment";

const getAll = () => {
  return http.get(`/product/collection/get`);
};

const getByKeyword = (keyword, current_page) => {
  return http.get(`/product/collection/get?keyword=${keyword}`);
}

const getByBrandId = (brand_id) => {
  return http.get(`/product/collection/get?brand_id=${brand_id}`);
}

const getCollectionHeaderImg = () => {
  return http.get(`/product/collection/getCollectionHeaderImg`);
}

const getShopHeaderImg = () => {
  return http.get(`/product/collection/getShopHeaderImg`);
}

const CollectionService = {
  getAll,
  getByKeyword,
  getByBrandId,
  getCollectionHeaderImg,
  getShopHeaderImg
};

export default CollectionService;
