import http from "../environment";

const addToWishlist = (requestData) => {
    return http.post('wishlist/save', requestData);
  }

  const getByUserId = (user_id) => {
    return http.get(`wishlist/get?user_id=${user_id}`);
  }

  const WishlistService = {
  addToWishlist,
  getByUserId
  }

  export default WishlistService;