import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import "../Lookbook/Lookbook.css";
import "../../js/main";
import CollectionService from "../../api-config/collection";
import blankImage from "../../assets/images/about-team.png";
import HomeService from "../../api-config/home";

const Lookbook = (props) => {
  const navigate = useNavigate();
  const [collectionData, setCollectionData] = useState();
  const [lookbookData, setLookbookData] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const collectionResponse = await CollectionService.getAll();
        const filteredData = collectionResponse.data.data.filter(
          (item) => item.look_book !== null,
        );
        setCollectionData(filteredData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // view pdf
  const viewPdf = (item) => {
    const pdfUrl = item.look_book; // Use the correct URL from item.look_book
    window.open(pdfUrl, "_blank");
  };
  // ----
  if (loading) {
    return <div>Loading...</div>;
  }

  if (collectionData.length === 0) {
    return <div>No data available</div>;
  }

  return (
    <section className="lookbook">
      <div className="container">
        <div className="row">
          <div className="main-title">
            <h3>LookBook</h3>
          </div>

          <div className="collection-list">
            <div className="row">
              {console.log(">>>", collectionData)}
              {collectionData.map((item, i) => (
                <div className="col-6 col-md-4 col-lg-3 mb-3" key={i}>
                  <div
                    className="card text-white seasons"
                    onClick={() => viewPdf(item)}
                  >
                    <div className="hover-overlay"></div>
                    <img
                      src={item.thumbnail_image || blankImage}
                      className="card-img"
                      alt="..."
                    />
                    <div className="card-img-overlay"></div>
                  </div>
                  <h5 className="text-align-center">{item.name}</h5>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Lookbook;
