import React from "react";
import Accordion from "react-bootstrap/Accordion";
import "../css/component.css";
import "../css/footer.css";

const Footer = () => {
  return (
    <>
      <div className="footer-top d-block d-lg-none">
        <div className="container">
          <div className="row">
            <Accordion flush>
              <Accordion.Item eventKey="0">
                <Accordion.Header className="shadow-none text-secondary border-0 footer-menu-title">
                  THE SHOP
                </Accordion.Header>
                <Accordion.Body className="py-1">
                  <ul className="border-center list-unstyled text-white mb-0">
                    <li className="mb-2">
                      <a href="/">Home</a>
                    </li>
                    <li className="mb-2">
                      <a href="/dashboard">My Account</a>
                    </li>
                    <li className="mb-2">
                      <a href="/checkout">Checkout</a>
                    </li>
                    <li className="mb-2">
                      <a href="/cart">Cart</a>
                    </li>
                    <li className="mb-2">
                      <a href="/order-view">Order Tracking</a>
                    </li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header className="shadow-none text-secondary border-0 footer-menu-title">
                  INFORMATION
                </Accordion.Header>
                <Accordion.Body>
                  <ul className="border-center list-unstyled text-white mb-0">
                    <li className="mb-2">
                      <a href="/about-us">About Us</a>
                    </li>
                    <li className="mb-2">
                      <a href="/delivery-and-returns">Delivery & Returns</a>
                    </li>
                    {/* <li className="mb-2">
                      <a href="/returns">Returns</a>
                    </li> */}
                    <li className="mb-2">
                      <a href="/terms-and-conditions">Terms & Conditions</a>
                    </li>
                    {/* <li className="mb-2">
                      <a href="/privacy-policy">Privacy Policy</a>
                    </li> */}
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header className="shadow-none text-secondary border-0 footer-menu-title">
                  EXTRAS
                </Accordion.Header>
                <Accordion.Body>
                  <ul className="border-center list-unstyled text-white mb-0">
                    {/* <li className="mb-2">
                      <a href="/our-brands">Our Brands</a>
                    </li> */}
                    {/* <li className="mb-2">
                      <a href="#">Special Offers</a>
                    </li>
                    <li className="mb-2">
                      <a href="#">Gift Vouchers</a>
                    </li>
                    <li className="mb-2">
                      <a href="#">Affiliates</a>
                    </li> */}
                    <li className="mb-2">
                      <a href="/contact-us">Contacts</a>
                    </li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </div>
      <div className="footer-top pt-5 pb-5 d-none d-lg-block">
        <div className="container">
          <div className="row g-lg-3 gy-3">
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="footer-menu">
                <h5 className="footer-menu-title">THE SHOP</h5>
                <div className="d-flex align-items-center">
                  <ul className="border-center list-unstyled text-white">
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>
                      <a href="/dashboard">My Account</a>
                    </li>
                    <li>
                      <a href="/checkout">Checkout</a>
                    </li>
                    <li>
                      <a href="/cart">Cart</a>
                    </li>
                    <li>
                      <a href="/order-view">Order Tracking</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="footer-menu">
                <h5 className="footer-menu-title">INFORMATION</h5>
                <div className="d-flex align-items-center">
                  <ul className="border-center list-unstyled text-white">
                    <li>
                      <a href="/about-us">About Us</a>
                    </li>
                    <li>
                      <a href="/delivery-and-returns">Delivery & Returns</a>
                    </li>
                    {/* <li>
                      <a href="/returns"></a>
                    </li> */}
                    <li>
                      <a href="/terms-and-conditions">Terms & Conditions</a>
                    </li>
                    {/* <li>
                      <a href="/privacy-policy">Privacy Policy</a>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="footer-menu">
                <h5 className="footer-menu-title">EXTRAS</h5>
                <div className="d-flex align-items-center">
                  <ul className="border-center list-unstyled text-white">
                    {/* <li>
                      <a href="/our-brands">Our Brands</a>
                    </li> */}
                    {/* <li>
                      <a href="#">Special Offers</a>
                    </li>
                    <li>
                      <a href="#">Gift Vouchers</a>
                    </li>
                    <li>
                      <a href="#">Affiliates</a>
                    </li> */}
                    <li>
                      <a href="/contact-us">Contacts</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="footer-menu footer-follow-us">
                <h5 className="footer-menu-title">CUSTOMER CARE</h5>
                Feel free to reach us and get your queries resolved.
                <ul className="mt-3 list-unstyled d-flex align-items-center m-0">
                  <li className="ps-1 pe-3">
                    <a
                      href="https://www.facebook.com/Worldofsugandh"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-facebook"
                        viewBox="0 0 16 16"
                      >
                        <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                      </svg>
                    </a>
                  </li>

                  <li className="ps-3 pe-3">
                    <a
                      href="https://www.instagram.com/worldofsugandh/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-instagram"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z" />
                      </svg>
                    </a>
                  </li>
                  <li className="ps-3 pe-3">
                    <a
                      href="https://wa.me/919638442222"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-whatsapp"
                        viewBox="0 0 16 16"
                      >
                        <path d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232" />
                      </svg>
                    </a>
                  </li>
                  {/* <li className="ps-3 pe-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-twitter"
                      viewBox="0 0 16 16"
                    >
                      <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
                    </svg>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="container">
          <div className="footer-sign-up d-flex flex-column align-items-center justify-content-center">
            <h2 className="mb-4">Sign up now & get 10% off</h2>
            Be the first to know about our new arrivals and exclusice offers.
            <form className="mt-2 row g-3 footer-sign-up">
              <div className="col-auto">
                <input type="email" placeholder="Your email address" required />
              </div>
              <div className="col-auto">
                <input type="submit" value="Sign up" />
              </div>
            </form>
          </div>
        </div> */}
      </div>
      <div className="container d-flex justify-content-between mb-4">
        <ul className="list-unstyled d-flex align-items-center m-0">
          {/* <li className="ps-3 pe-3">Privacy Policy</li> */}
          {/* <li className="ps-3 pe-3">Terms & Conditions</li> */}
        </ul>
        © 2021 Sugandh
      </div>
    </>
  );
};

export default Footer;
