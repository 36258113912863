import http from "../environment";

const getAll = () => {
  return http.get(`/home-slider/get`);
};

const CmsService = {
  getAll,
};

export default CmsService;
