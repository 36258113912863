import http from "../environment";

const getAll = () => {
    return http.get(`/product/sub-category/get`);
};

const getByKeyword = (keyword) => {
    console.log(keyword)
    return http.get(`product/sub-category/get?keyword=${keyword}`);
}

const SubCategoryService = {
    getAll,
    getByKeyword
};

export default SubCategoryService;
