import http from "../environment";


const getDesktopSlider = () => {
    return http.get(
      `/home-slider-desktop/get`
    );
  };

  const getMobileSlider = () => {
    return http.get(
      `/home-slider-mobile/get`
    );
  };

  const getSec3 = () => {
    return http.get(
      `/home-section-three/get`
    );
  };

  const getCulture = () => {
    return http.get(
      `/home-culture-section/get`
    );
  };


  // rozana slider 
  const getSliderRight = () => {
    return http.get(
      `/home-section-five-right/get`
    );
  };

  const getSliderLeft = () => {
    return http.get(
      `/home-section-five-left/get`
    );
  };

  const getSliderCenter = () => {
    return http.get(
      `/home-section-five-center/get`
    );
  };

  // lookbook 
  const getLookbook = () => {
    return http.get(
      `/home-look-book-section/get`
    );
  };

  const getCelebrity = () => {
    return http.get(
      `/home-celebrity-choice/get`
    );
  };


const HomeService = {
    getDesktopSlider,getMobileSlider,getSec3,getCelebrity,getLookbook,getCulture,getSliderRight,getSliderLeft,getSliderCenter
}

export default HomeService;
