import axios from 'axios';
import http from "../environment";

const API_BASE_URL = 'https://apiv2.shiprocket.in/v1';
const TOKEN = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOjMxMzk1OTcsImlzcyI6Imh0dHBzOi8vYXBpdjIuc2hpcHJvY2tldC5pbi92MS9leHRlcm5hbC9hdXRoL2xvZ2luIiwiaWF0IjoxNjg1MzQxNTk4LCJleHAiOjE2ODYyMDU1OTgsIm5iZiI6MTY4NTM0MTU5OCwianRpIjoiNGx6RlBpVEJoQ0NraGpoayJ9.7w8lDE_3CtGkgiZzcFjPYnOm9uWGvGiS_2Aj9WJcD2Y'

const shiprocketAPI = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${TOKEN}`,
  },
});


export const getOrders = async () => {
    try {
      const response = await shiprocketAPI.get('/orders');
      return response.data;
    } catch (error) {
      console.error('Error fetching orders:', error);
      throw error;
    }
};

export const createOrder = async (orderData) => {
    // return await shiprocketAPI.post('/external/orders/create/adhoc', orderData);
    return await http.post('/shiprocket/order/save', orderData);
};

export const cancelOrder = async (orderIdArray) => {
    const requestData = {
        ids: [orderIdArray]
    }
    // return await shiprocketAPI.post('/external/orders/cancel', requestData);
    return await http.post('/shiprocket/order/cancel', requestData);
}

export const getOrder = async () => {
    return await shiprocketAPI.get('/external/orders');
} 

export const trackingUsingShippingId = async (shipping_id) => {
    return await shiprocketAPI.get(`/external/courier/track/shipment/${shipping_id}`);
}

export const trackingUsingOrderId = async (order_id, channel_id) => {
    return await shiprocketAPI.get(`/external/courier/track?order_id=${order_id}&channel_id=${channel_id}`)
}