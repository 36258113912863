import React from "react";
import "./payment-failed.css";
import { IoIosCloseCircleOutline } from "react-icons/io";

const PaymentFailed = () => {
  return (
    <>
      <div>
        <div className="PaymentFailed">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-7">
                <div className="failed">
                  <IoIosCloseCircleOutline />
                  <p>Payment Failed</p>
                  <h3>Oh No, Your Payment Failed!</h3>
                  <p>
                    We Were Unable To Process The Payment.{" "}
                    <span>Try Again</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentFailed;
