import http from "../environment";

const getCredit = (data) => {
  return http.post(`/invoice/shipping/details/getCredit`, data);
};

const getCoupon = (data) => {
  return http.post(`/invoice/shipping/details/getCoupon`, data);
};

const CouponService = {
  getCredit,
  getCoupon
};

export default CouponService;
