import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { FaChevronLeft } from "react-icons/fa";
import { BeatLoader } from "react-spinners";
import { Document, Page, pdfjs } from "react-pdf";
import "../css/style.css";
import { MdKeyboardArrowLeft } from "react-icons/";
import { MdKeyboardArrowRight } from "react-icons/md";
import { FaAngleLeft } from "react-icons/fa";
import { FaAngleRight } from "react-icons/fa";
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//     'pdfjs-dist/build/pdf.worker.min.js',
//     import.meta.url,
// ).toString();

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const ViewPdf = () => {
  const location = useLocation();
  const [url, setUrl] = useState();
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [numPages, setNumPages] = useState(null);

  useEffect(() => {
    fetch(location.state.pdf_url).then((response) => {
      setUrl(response.url);
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    });
  }, [location.state.pdf_url]);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setLoading(false);
  }

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < numPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <>
      {!loading ? (
        <div className="view-pdf" style={{ marginTop: "100px" }}>
          <div className="container">
            <div className="link-to-back mb-3">
              <a href="/lookbook" className="cool-link">
                <FaChevronLeft className="left-chevron" /> Back to Lookbook
              </a>
            </div>
          </div>
          {/* <Document className="react-document" file={url} onLoadSuccess={onDocumentLoadSuccess} options={{ workerSrc: "/pdf.worker.js" }} style={{ textAlign: 'center' }}>
                            {Array.from(new Array(numPages), (el, index) => (
                                <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                            ))}
                        </Document> */}
          <div className="container d-flex justify-content-center align-items-center">
            <div className="document-main-container">
              <Document
                className="react-document"
                file={url}
                onLoadSuccess={({ numPages }) => setNumPages(numPages)}
                options={{
                  loading: "hii",
                }}
              >
                <Page pageNumber={currentPage} />
              </Document>
              <div>
                <button
                  className="pdf-prev"
                  onClick={handlePreviousPage}
                  disabled={currentPage === 1}
                >
                  <FaAngleLeft />
                </button>
                <button
                  className="pdf-next"
                  onClick={handleNextPage}
                  disabled={currentPage === numPages}
                >
                  <FaAngleRight />
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="spinner-container">
          <BeatLoader
            color="#242323"
            loading={loading}
            // cssOverride={override}
            // size={50}
            aria-label="Loading Spinner"
            data-testid="loader"
            className="loader"
            align-item="center"
          />
        </div>
      )}
    </>
  );
};
export default ViewPdf;
