import { ADD_TO_CART, REMOVE_TO_CART, ADD_TO_WISHLIST, REMOVE_TO_WISHLIST } from "../constants";

export const addToCart = (data) => {
    return {
        type: ADD_TO_CART,
        data: data
    }
}

export const removeToCart = (data) => {
    return {
        type: REMOVE_TO_CART,
        data: data
    }
}

export const addToWishlist = (data) => {
    return {
        type: ADD_TO_WISHLIST,
        data: data
    }
}

export const removeToWishlist = (data) => {
    return {
        type: REMOVE_TO_WISHLIST,
        data: data
    }
}
