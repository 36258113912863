import http from "../environment";

const getColor = () => {
  return http.get(`/product/color/get`);
};

const getSize = () => {
    return http.get(`/product/size/get`);
}

const ShopService = {
  getColor,
  getSize
};

export default ShopService;
