import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";

import Slider from "../Modules/Slider/Slider";
import "../css/brand.css";
import "../css/component.css";

import logo1 from "../assets/images/Sugandh Gupta Logo Fashion Designer.jpg";
import logo2 from "../assets/images/Sugandh Urban Appereal Logo - Sugandh Gupta.png";

import "../../src/css/DefaultLayout.css";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Grid,
  Autoplay,
  EffectFade,
} from "swiper/modules";
import "swiper/css/effect-fade";
import "swiper/css/grid";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import sugandhImg from "../assets/images/logo.png";

import blankImage from "../assets/images/about-team.png";
import getTheLookImg from "../assets/images/get-the-look.png";
import ProductService from "../api-config/product";

import { Helmet } from "react-helmet";
import logo from "../assets/images/logo.png";
import VisitUs from "../Modules/Visit Us/VisitUs";
import WebPop from "../assets/images/Website Modal_GlobalDesi.jpg";
import HomeService from "../api-config/home";
import http from "../environment";

const containerStyle = {
  width: "100%",
  height: "100%",
};

const center = {
  lat: 21.7728,
  lng: 72.15356,
  lat: 21.7728,
  lng: 72.15356,
};

const DefaultLayout = (props) => {
  /* ----------------------------------------------------------------------------------------------------- */
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  useEffect(() => {
    const userInfo = localStorage.getItem("user_info");
    setIsLoggedIn(!!userInfo);
  }, []);

  /* ----------------------------------------------------------------------------------------------------- */

  const navigate = useNavigate();
  const [showForm, setShowForm] = useState(false);

  const [productList, setProductList] = useState([]);
  const [celebrityList, setCelebrityList] = useState([]);
  const [imageGallery, setImageGallery] = useState([]);
  const [imageData, setImageData] = useState([]);

  const [id, setId] = useState();
  const [lookbookData, setLookbookData] = useState();
  const [sec3Data, setSec3Data] = useState();
  const [cultureData, setCultureData] = useState();
  const [sliderRightData, setSliderRightData] = useState([]);
  const [sliderLeftData, setSliderLeftData] = useState([]);
  const [sliderCenterData, setsliderCenterData] = useState();

  useEffect(() => {
    // sec 3

    HomeService.getSec3().then((res) => {
      setSec3Data(res.data.data);
    });

    // culture section

    HomeService.getCulture().then((res) => {
      setCultureData(res.data.data);
    });

    // rozana slider

    // HomeService.getSliderRight().then((res) => {
    //   setSliderRightData(res.data.data)
    // })

    HomeService.getSliderRight()
      .then((res) => {
        if (res.data.success) {
          setSliderRightData(res.data.data);
          console.log(res.data.data);
        }
      }, [])
      .catch((e) => console.log(e));

    HomeService.getSliderLeft()
      .then((res) => {
        if (res.data.success) {
          setSliderLeftData(res.data.data);
          console.log(res.data.data);
        }
      }, [])
      .catch((e) => console.log(e));

    // HomeService.getSliderLeft().then((res) => {
    //   setSliderLeftData(res.data.data)
    // })

    HomeService.getSliderCenter().then((res) => {
      setsliderCenterData(res.data.data);
    });

    // lookbook section

    HomeService.getLookbook().then((res) => {
      setLookbookData(res.data.data);
    });

    // celebrity choice
    HomeService.getCelebrity()
      .then((res) => {
        if (res.data.success) {
          setCelebrityList(res.data.data);
          console.log(res.data.data);
        }
      })
      .catch((e) => console.log(e));

    // trending product
    ProductService.getTrendingData()
      .then((res) => {
        if (res.data.success) {
          setProductList(res.data.data);
          console.log(res.data.data);
        }
      })
      .catch((e) => console.log(e));
  }, []);

  const redirecttoShop = (item, type) => {
    if (type === "brand-id") {
      navigate(`/the-shop/${item}`);
    } else if (type === "product-keyword") {
      console.log(item, "item");
      navigate("/the-shop", { state: { search_keyword: item?.name } });
    } else if (type === "collection") {
      navigate("/the-shop/collections/Rozana", {
        state: { collection_id: 14 },
      });
    }
  };

  const redirecttoBrand = (brand_id, brand_name) => {
    if (brand_id === 1) {
      navigate(`/our-brands/sugandh-urbane-apparels`);
    } else {
      navigate(`/our-brands/sugandh-gupta`);
    }
  };

  const redirectToProductInfoPage = (product_id, product_name) => {
    navigate(`/product-info/${product_name}`, { state: { id: product_id } });
  };

  const gotoProductInfo = (product) => {
    navigate(`/product-info/${product?.SubCategory?.name}/${product?.name}`, {
      state: { id: product?.id },
    });
  };

  useEffect(() => {
    const hasFormBeenDisplayed = localStorage.getItem("popUpFormDisplayed");

    if (!hasFormBeenDisplayed) {
      setShowForm(true);
      localStorage.setItem("popUpFormDisplayed", "true");
    }
  }, []);

  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.removeItem("popUpFormDisplayed");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const closePopup = () => {
    setShowForm(false);
  };

  // redirect to the product id
  const gotoProductInfo1 = (Collection) => {
    console.log(Collection);
    navigate(`/the-shop/collections/${Collection}`, {
      state: { id: Collection },
    });
  };

  const gotoProductInfo2 = (CollectionName) => {
    console.log(CollectionName); // Check if CollectionName is defined and correct
    navigate(`/the-shop/collections/${CollectionName}`, {
      state: { name: CollectionName }, // You can pass additional data if needed
    });
  };

  // redirect to the pdf page
  const viewPdf = (item) => {
    navigate(`/lookbook/${item.name}/view-pdf`, {
      state: { pdf_url: item.look_book },
    });
  };
  return (
    <>
      <Helmet>
        <title>World of Sugandh | Sugandh Urbane Apparel</title>
        <meta
          name="description"
          content="Sugandh Gupta, our founder firmly believes in the joy of doing what you love!Loves white. Loves minimalism. Her brain is a sustainable fibre dictionary."
        />
        <meta
          name="keywords"
          content="Sugandh Gupta in shop Bhavnagar, Sugandh Gupta "
        />
        <link rel="icon" type="image/png" href={logo} />
      </Helmet>

      {showForm && (
        <div id="container">
          <div id="popUpForm">
            <div id="popContainer">
              <div id="myModal">
                <div className="modal-content">
                  <button id="close" onClick={closePopup}>
                    X
                  </button>
                  <div className="popImage">
                    {/* ----------------------------------------------------------------------------- */}

                    {/* <NavLink to={"/login"}>
                      <img src={WebPop} />
                    </NavLink> */}

                    {/* ----------------------------------------------------------------------------- */}
                    <NavLink
                      to={isLoggedIn ? "/user/account-details" : "/login"}
                      className="nav-link"
                    >
                      {/* Text or content of NavLink based on isLoggedIn state */}
                      {isLoggedIn ? <img src={WebPop} /> : <img src={WebPop} />}
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Slider />
      {/* <Brand /> */}
      <section className="home-brand">
        <h1>Our Brands</h1>
        <div className="container">
          <div className="row">
            <Swiper
              modules={[Navigation, Pagination, Scrollbar, A11y, Grid]}
              direction="horizontal"
              spaceBetween={20}
              slidesPerView={2}
              grid={{
                fill: "row",
                rows: 3,
              }}
              breakpoints={{
                400: {
                  slidesPerView: 2,
                  grid: {
                    fill: "row",
                    rows: 1,
                  },
                },
                768: {
                  slidesPerView: 2,
                  grid: {
                    fill: "row",
                    rows: 1,
                  },
                },
              }}
            >
              <SwiperSlide>
                <div
                  className="card-image-container pe-auto"
                  onClick={() => redirecttoBrand(1, "Sugandh Urbane Apparels")}
                >
                  <img
                    src={logo2}
                    className="img-fluid br-10"
                    alt="Sugandh Urbane Apparels"
                  />
                  <h4>Sugandh Urbane Apparels</h4>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div
                  className="card-image-container pe-auto"
                  onClick={() => redirecttoBrand(2, "Sugandh Gupta")}
                >
                  <img
                    src={logo1}
                    className="img-fluid br-10"
                    alt="Sugandh Gupta"
                  />
                  <h4>Sugandh Gupta</h4>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </section>
      {/* enchanting section  */}
      <div className="section-3 home">
        <div className="container content">
          <div>
            <p>
              "An enchanting fusion of creativity from rural and urban women has
              birthed a mesmerising collection, meticulously adorned with
              mirrors."
            </p>
          </div>
        </div>

        <div className="container">
          <div className="row gy-3">
            {sec3Data
              ?.filter((item) => item.id === 1)
              .map((item, i) => (
                <div className="col-6">
                  <div className="card mb-3">
                    <div className="card-image-container-box pe-auto">
                      <img
                        src={item.image}
                        className="img-fluid rounded-start"
                        // alt="Yellow Jacket"
                        alt={`image-${item.id}`}
                        onClick={() =>
                          redirectToProductInfoPage(item.id, item.name)
                        }
                        // onClick={() => gotoProductInfo1(item?.Collection?.name)}
                      />
                    </div>
                  </div>
                </div>
              ))}

            {sec3Data
              ?.filter((item) => item.id === 2)
              .map((item, i) => (
                <div className="col-6">
                  <div className="card mb-3">
                    <div className="card-image-container-box pe-auto">
                      <img
                        src={item.image}
                        className="img-fluid rounded-start"
                        onClick={() =>
                          redirectToProductInfoPage(item.id, item.name)
                        }
                        // onClick={() => gotoProductInfo1(item?.Collection?.name)}

                        // alt="Olive Jardosi Embroidary Anarkali Set"
                        alt={`image-${item.id}`}
                      />
                    </div>
                  </div>
                </div>
              ))}
          </div>

          <div className="row g-3">
            {sec3Data
              ?.filter((item) => item.id === 3)
              .map((item, i) => (
                <div className="col-md-5 col-12">
                  <div className="card">
                    <div className="card-image-container-box pe-auto">
                      <img
                        src={item.image}
                        className="img-fluid"
                        // alt="image"
                        alt={`image-${item.id}`}
                        onClick={() =>
                          redirectToProductInfoPage(item.id, item.name)
                        }

                        // onClick={() => gotoProductInfo1(item?.Collection.name)}

                        // onClick={() =>
                        //   gotoProductInfo(
                        //     item?.product_name
                        //   )
                        // }
                        // onClick={() =>
                        //   redirectToProductInfoPage(
                        //     145,
                        //     "classic black anarkali set"
                        //   )
                        // }
                      />
                    </div>
                  </div>
                </div>
              ))}

            <div className="col-md-7">
              <div className="row">
                {sec3Data
                  ?.filter((item) => item.id === 4)
                  .map((item, i) => (
                    <div className="col-4">
                      <div className="card">
                        <div className="card-image-container-box pe-auto">
                          <img
                            src={item.image}
                            className="img-fluid"
                            // alt="image"
                            alt={`image-${item.id}`}
                            onClick={() =>
                              redirectToProductInfoPage(item.id, item.name)
                            }

                            // onClick={() => gotoProductInfo1(item?.Collection?.name)}

                            // onClick={() =>
                            //   redirectToProductInfoPage(
                            //     249,
                            //     "steel brown straight cut kurta set"
                            //   )
                            // }
                          />
                        </div>
                      </div>
                    </div>
                  ))}

                {sec3Data
                  ?.filter((item) => item.id === 5)
                  .map((item, i) => (
                    <div className="col-4">
                      <div className="card">
                        <div className="card-image-container-box pe-auto">
                          <img
                            src={item.image}
                            className="img-fluid"
                            onClick={() =>
                              redirectToProductInfoPage(item.id, item.name)
                            }
                            // alt="image"
                            alt={`image-${item.id}`}
                          />
                        </div>
                      </div>
                    </div>
                  ))}

                {sec3Data
                  ?.filter((item) => item.id === 6)
                  .map((item, i) => (
                    <div className="col-4">
                      <div className="card">
                        <div
                          className="card-image-container-box pe-auto"
                          onClick={() =>
                            redirectToProductInfoPage(item.id, item.name)
                          }

                          // onClick={() =>
                          //   redirectToProductInfoPage(226, "Mirror Embellishment Dark Green Emb Kaftan%20Set")
                          // }
                        >
                          <img
                            src={item.image}
                            className="img-fluid"
                            // alt="image"
                            alt={`image-${item.id}`}
                            // onClick={() =>
                            //   redirectToProductInfoPage(
                            //     148,
                            //     "steel brown anarkali set"
                            //   )
                            // }
                          />
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* embracing culture  */}
      <div className="section-3 home">
        <div className="container content">
          <div>
            <h3 className="mb-4">Embracing culture</h3>
            <p>
              "Fashion is art, culture and comfort. We at WOS, create perfect
              art for you along with designs that embrace our culture and
              heritage at its best keeping in mind the comfort of every woman in
              the society. "
            </p>
          </div>
        </div>
        <div className="container">
          <div className="row gy-3">
            <div className="col-12 col-md-6">
              <div className="col-12">
                {cultureData
                  ?.filter((item) => item.id === 1)
                  .map((item, i) => (
                    <div className="card mb-3">
                      <div
                        className="card-image-container"
                        // onClick={() =>
                        //   redirectToProductInfoPage(182, "Aquamarine Pm Set")
                        // }
                      >
                        <img
                          src={item.image}
                          className="img-fluid rounded-start"
                          // alt="Aquamarine Pm Set"
                          alt={`image-${item.id}`}
                        />
                      </div>
                    </div>
                  ))}
                <div className="row g-3">
                  {cultureData
                    ?.filter((item) => item.id === 2)
                    .map((item, i) => (
                      <div className="col-6">
                        <div className="card">
                          <div
                            className="card-image-container pe-auto"
                            onClick={() =>
                              gotoProductInfo1(item?.Collection.name)
                            }

                            // onClick={() =>
                            //   redirectToProductInfoPage(177, "Sundown Smoked Dress")
                            // }
                          >
                            <img
                              src={item.image}
                              className="img-fluid"
                              onClick={() =>
                                gotoProductInfo1(item?.Collection.name)
                              }
                              // alt="Sundown Smoked Dress"
                              alt={`image-${item.id}`}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  {cultureData
                    ?.filter((item) => item.id === 3)
                    .map((item, i) => (
                      <div className="col-6">
                        <div className="card">
                          <div
                            className="card-image-container"
                            // onClick={() =>
                            //   redirectToProductInfoPage(75, "Santorini Dress")
                            // }
                          >
                            <img
                              src={item.image}
                              className="img-fluid"
                              // alt="Santorini Dress"
                              alt={`image-${item.id}`}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="col-12">
                {cultureData
                  ?.filter((item) => item.id === 4)
                  .map((item, i) => (
                    <div className="card mb-3">
                      <div
                        className="card-image-container pe-auto"
                        onClick={() => gotoProductInfo1(item?.Collection.name)}

                        // onClick={() =>
                        //   redirectToProductInfoPage(182, "Aquamarine Pm Set")
                        // }
                      >
                        <div
                          className="card-image-container"
                          // onClick={() =>
                          //   redirectToProductInfoPage(
                          //     153,
                          //     "Olive Jardosi Embroidary Anarkali Set"
                          //   )
                          // }
                        >
                          <img
                            src={item.image}
                            className="img-fluid rounded-start"
                            // alt="Olive Jardosi Embroidary Anarkali Set"
                            alt={`image-${item.id}`}
                          />
                        </div>
                      </div>
                    </div>
                  ))}

                <div className="row g-3">
                  {cultureData
                    ?.filter((item) => item.id === 5)
                    .map((item, i) => (
                      <div className="col-6">
                        <div className="card">
                          <div
                            className="card-image-container pe-auto"
                            onClick={() =>
                              gotoProductInfo1(item?.Collection.name)
                            }

                            // onClick={() =>
                            //   redirectToProductInfoPage(75, "Santorini Dress")
                            // }
                          >
                            <img
                              src={item.image}
                              className="img-fluid"
                              // alt="image"
                              alt={`image-${item.id}`}
                            />
                          </div>
                        </div>
                      </div>
                    ))}

                  {cultureData
                    ?.filter((item) => item.id === 6)
                    .map((item, i) => (
                      <div className="col-6">
                        <div className="card">
                          <div className="card-image-container">
                            <img
                              src={item.image}
                              className="img-fluid"
                              // alt="image"
                              alt={`image-${item.id}`}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Rozana slider section */}
      <div className="rozana-section">
        <div className="container">
          <div className="row">
            <div className="col-12 col-xl-3 rozana_section_slider_1">
              <Swiper
                modules={[
                  Navigation,
                  Pagination,
                  Scrollbar,
                  A11y,
                  Grid,
                  Autoplay,
                  EffectFade,
                ]}
                direction="horizontal"
                effect={"fade"}
                spaceBetween={20}
                slidesPerView={1}
                autoplay={{ delay: 3000 }}
                loop
                grid={{
                  fill: "row",
                  rows: 3,
                }}
                navigation={{
                  nextEl: ".review-swiper-button-next",
                  prevEl: ".review-swiper-button-prev",
                }}
                breakpoints={{
                  300: {
                    slidesPerView: 1,
                    grid: {
                      fill: "row",
                      rows: 1,
                    },
                  },
                  420: {
                    slidesPerView: 1,
                    grid: {
                      fill: "row",
                      rows: 1,
                    },
                  },
                  768: {
                    slidesPerView: 1,
                    grid: {
                      fill: "row",
                      rows: 1,
                    },
                  },
                }}
              >
                {sliderLeftData?.map((item, i) => {
                  return (
                    <SwiperSlide
                    // onClick={() =>
                    //   redirectToProductInfoPage(item?.id, item?.name)
                    // }
                    >
                      <div className="card-image-container" key={i}>
                        <img
                          src={item?.image[0]}
                          className="img-fluid"
                          // alt="SUGANDH GUPTA"
                        />
                      </div>
                    </SwiperSlide>
                  );
                })}

                {sliderLeftData?.map((item, i) =>
                  item?.image.map((imageSrc, j) => (
                    <SwiperSlide key={`${i}-${j}`}>
                      <div className="card-image-container">
                        <img src={imageSrc} className="img-fluid" alt="" />
                      </div>
                    </SwiperSlide>
                  )),
                )}
              </Swiper>
            </div>

            {sliderCenterData?.map((item, i) => {
              return (
                <div className="col-12 col-xl-6 text-center">
                  <img src={item.image} className="img-fluid tab-img" alt="" />
                  <br />

                  <button
                    type="button"
                    style={{ border: "1px solid" }}
                    className="btn float-center"
                    onClick={() => gotoProductInfo1(item?.Collection.name)}

                    // onClick={() => redirecttoShop("rozana", "collection")}
                  >
                    Shop Now
                  </button>
                </div>
              );
            })}
            <div className="col-12 col-xl-3 rozana_section_slider_2">
              <Swiper
                modules={[
                  Navigation,
                  Pagination,
                  Scrollbar,
                  A11y,
                  Grid,
                  Autoplay,
                  EffectFade,
                ]}
                direction="horizontal"
                effect={"fade"}
                spaceBetween={20}
                slidesPerView={1}
                autoplay={{ delay: 2000 }}
                loop
                grid={{
                  fill: "row",
                  rows: 3,
                }}
                navigation={{
                  nextEl: ".review-swiper-button-next",
                  prevEl: ".review-swiper-button-prev",
                }}
                breakpoints={{
                  300: {
                    slidesPerView: 1,
                    grid: {
                      fill: "row",
                      rows: 1,
                    },
                  },
                  420: {
                    slidesPerView: 1,
                    grid: {
                      fill: "row",
                      rows: 1,
                    },
                  },
                  768: {
                    slidesPerView: 1,
                    grid: {
                      fill: "row",
                      rows: 1,
                    },
                  },
                }}
              >
                {sliderRightData?.map((item, i) =>
                  item?.image.map((imageSrc, j) => (
                    <SwiperSlide key={`${i}-${j}`}>
                      <div className="card-image-container">
                        <img src={imageSrc} className="img-fluid" alt="" />
                      </div>
                    </SwiperSlide>
                  )),
                )}
              </Swiper>
            </div>
          </div>
        </div>
      </div>
      {/* lookbook section  */}
      <div className="featured-lookbook-section">
        <div className="container">
          <div className="row gy-4">
            <div className="col-12 col-xl-3 d-flex flex-column justify-content-center">
              <div className="d-block">
                <p className="btn-home px-3 py-1 mb-3 d-inline-block">
                  <div className="fs-6">Featured</div>
                </p>
                <h2
                  className="mb-3"
                  style={{ fontWeight: "400", marginBottom: "1px" }}
                >
                  Look Book
                </h2>
                <p className="mb-3">
                  Our latest collections are ready for you to have a look
                </p>
                <a href="/lookbook" className="fbutton">
                  All Looks
                </a>
              </div>
            </div>

            <div className="col-12 col-xl-9">
              <div className="row">
                {lookbookData
                  ?.filter((item) => item.id === 1)
                  .map((item, i) => (
                    <div key={i} className="col-sm-3">
                      <div className="card h-100">
                        <div className="card-image-container pe-auto">
                          <img
                            src={item.image}
                            className="img-fluid"
                            alt={`image-${item.id}`}
                            onClick={() => viewPdf(item)}
                          />
                        </div>
                      </div>
                    </div>
                  ))}

                <div className="col-sm-9">
                  <div className="row g-3">
                    {lookbookData
                      ?.filter((item) => item.id === 2)
                      .map((item, i) => (
                        <div className="col-8">
                          <div className="card h-100">
                            <div className="card-image-container pe-auto">
                              <img
                                src={item.image}
                                className="img-fluid"
                                alt={`image-${item.id}`}
                                onClick={() => viewPdf(item)}
                              />
                            </div>
                          </div>
                        </div>
                      ))}

                    {lookbookData
                      ?.filter((item) => item.id === 3)
                      .map((item, i) => (
                        <div className="col-4">
                          <div className="card h-100">
                            <div className="card-image-container pe-auto">
                              <img
                                src={item.image}
                                className="img-fluid"
                                alt={`image-${item.id}`}
                                onClick={() => viewPdf(item)}
                              />
                            </div>
                          </div>
                        </div>
                      ))}

                    {lookbookData
                      ?.filter((item) => item.id === 4)
                      .map((item, i) => (
                        <div className="col-4">
                          <div className="card h-100">
                            <div className="card-image-container pe-auto">
                              <img
                                src={item.image}
                                className="img-fluid"
                                alt={`image-${item.id}`}
                                onClick={() => viewPdf(item)}
                              />
                            </div>
                          </div>
                        </div>
                      ))}

                    {lookbookData
                      ?.filter((item) => item.id === 5)
                      .map((item, i) => (
                        <div className="col-4">
                          <div className="card h-100">
                            <div className="card-image-container pe-auto">
                              <img
                                src={item.image}
                                className="img-fluid"
                                alt={`image-${item.id}`}
                                onClick={() => viewPdf(item)}
                              />
                            </div>
                          </div>
                        </div>
                      ))}

                    {lookbookData
                      ?.filter((item) => item.id === 6)
                      .map((item, i) => (
                        <div className="col-4">
                          <div className="card h-100">
                            <div className="card-image-container pe-auto">
                              <img
                                src={item.image}
                                className="img-fluid"
                                alt={`image-${item.id}`}
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* trending outfit section  */}
      <section className="trending-outfits">
        <div className="container-fluid">
          <div className="text-center">
            <h1 className="display-6 mb-4" style={{ fontWeight: "400" }}>
              Trending Outfits
            </h1>
            <p className="text-secondary mb-0">
              "Fashion is a trend , style is within a person"
            </p>
          </div>

          <Swiper
            spaceBetween={20}
            navigation={true}
            modules={[Navigation, Scrollbar, A11y, Autoplay, EffectFade]}
            slidesPerView={4}
            scrollbar={{ draggable: true }}
            loop={true}
            autoplay={{ delay: 2000 }}
            breakpoints={{
              300: {
                spaceBetween: 3,
                slidesPerView: 1,
              },
              480: {
                spaceBetween: 5,
                slidesPerView: 2,
              },
              768: {
                spaceBetween: 10,
                slidesPerView: 3,
              },
              1200: {
                spaceBetween: 12,
                slidesPerView: 4,
              },
              1500: {
                spaceBetween: 15,
                slidesPerView: 5,
              },
              1600: {
                spaceBetween: 20,
                slidesPerView: 6,
              },
            }}
          >
            {productList?.map((item, i) => {
              return (
                <SwiperSlide key={i}>
                  <div
                    className="card my-2 my-lg-4"
                    onClick={() => gotoProductInfo(item)}
                  >
                    <div className="card-image-container pe-auto">
                      <img
                        src={item.thumbnail_image || blankImage}
                        className="img-fluid br-10"
                        alt={item?.name}
                      />
                    </div>
                    <div className="card-body">
                      <h6 className="card-title">{item?.name}</h6>
                      <p className="card-text">
                        ₹ {item.ProductVariants[0].mrp}
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </section>
      {/* celebrity choice section  */}
      <div className="celibrity-section">
        <div className="container">
          <div className="header">
            <img src={sugandhImg} className="img-fluid" alt="" />
            <span className="border_bottom"></span>
            <h3>Celebrity's Choice</h3>
            <p>Get your daily dose of style inspiration</p>
          </div>
          <div className="content mt-4">
            <div className="row justify-content-center">
              {celebrityList?.map((item, i) => {
                return (
                  <div className="col-12 col-md-6 col-lg-6 mb-4">
                    <img src={item.image} className="img-fluid" alt="" />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      {/* Get the look */}
      <div className="get-the-look">
        <div className="container">
          <div className="row gy-4 ">
            <div className="col-12 col-sm-6 ms-0">
              <img src={getTheLookImg} className="img-fluid" alt="" />
            </div>
            <div className="col-12 col-sm-4 d-flex flex-column justify-content-center info">
              <div className="content">
                <h1 className="display-6 mb-2" style={{ fontWeight: "400" }}>
                  Get the look
                </h1>
                <p className="fs-6 mb-4 mb-sm-5">
                  We accept custom orders and will be happy to make a piece
                  specially designed for you..
                </p>
                <a
                  href="/contact-us"
                  type="button"
                  className="btn btn-home px-4 py-2"
                >
                  <div className="fs-3">Let's Talk</div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="visit-us">
        <div className="container">
          <div className="row">
            <div className="text-center">
              <h1 className="display-6 mb-3" style={{ fontWeight: "400" }}>
                Visit Us
              </h1>
              <p className="text-secondary mb-4">
                We'll love to share the products which our customers has
                appreceated
              </p>
            </div>
            <VisitUs />
          </div>
        </div>
      </div>
    </>
  );
};

export default DefaultLayout;
