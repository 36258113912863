/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { toast, ToastContainer } from "react-toastify";
import { FaAngleLeft } from "react-icons/fa";
import CartService from "../api-config/cart";
import ProductService from "../api-config/product";
import "../css/cart.css";
import emptyBag from "../assets/images/empty-shopping-bag-white-advertising-branding_1284-48173.png";
import OrderService from "../api-config/order";
import CouponService from "../api-config/myCoupon";

const Cart = (props) => {
  const navigate = useNavigate();
  const [cartData, setCartData] = useState();
  const [items, setItems] = useState();
  const [subAmount, setSubAmount] = useState();
  const [discountAmount, setDiscountAmount] = useState(0);
  const [mainAmount, setMainAmount] = useState(0);
  const [checkCoupon, setCheckCoupon] = useState(false);
  const [totalAmount, setTotalAmount] = useState();
  const [loading, setLoading] = useState(true);
  const [coupon, setCoupon] = useState('');
  const [isEmpty, setEmpty] = useState(); // to check whether cart list is empty or not
  const auth_key = JSON.parse(localStorage.getItem("auth_key"));
  const userData = JSON.parse(localStorage.getItem("user_info"));
  const credit_point = localStorage.getItem("credit_point");
  const [point, setPoint] = useState(credit_point || '');
  const [creditBtn, setCreditBtn] = useState(false)
  const flatAmount = 0;

  useEffect(() => {
    getCartData();
  }, [subAmount, userData?.id, setItems, checkCoupon]);

  const getCartData = () => {
    if (auth_key) {
      setTimeout(() => {
        CartService.getByUserId(userData?.id).then((res) => {
          setCartData(res.data.data);
          setItems(res.data.data?.items);
          res.data.data?.items?.length < 1 ? setEmpty(true) : setEmpty(false);

          let sum = 0;
          let sum2 = 0
          res.data.data?.items.forEach((element) => {
            sum += element.quantity * parseInt(element.mrp);
            sum2 += element.quantity * parseInt(element.main_mrp || element.mrp);
            // sum = element?.coupon == "NEW10" ? sum * 0.9 : sum
          });
          // sum = checkCoupon ? sum * 0.9 : sum
          setSubAmount(sum);
          setDiscountAmount(sum2 - sum)
          setMainAmount(sum2)
          const total = parseInt(subAmount) + parseInt(flatAmount);
          setTotalAmount(parseInt(total));
          setTimeout(() => {
            setLoading(false);
          }, 2000);
        });
      }, 2000);
    } else {
      const data = JSON.parse(localStorage.getItem("cart_data"));
      setCartData(data);
      setItems(data);
      data?.length < 1 ? setEmpty(true) : setEmpty(false);

      let sum = 0;
      data?.forEach((element) => {
        sum += element.quantity * parseInt(element.main_mrp || element.mrp);
        // sum = element?.coupon == "NEW10" ? sum * 0.9 : sum
      });
      sum = checkCoupon ? sum * 0.9 : sum
      setSubAmount(sum);
      const total = parseInt(subAmount) + parseInt(flatAmount);
      setTotalAmount(parseInt(total));
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    }
  };

  const removeCart = (item, index) => {
    if (auth_key) {
      const data = items;
      let amount = subAmount - item?.mrp * item?.quantity;
      // sum = element?.coupon == "NEW10" ? sum * 0.9 : sum
      setSubAmount(amount);
      if (index > -1) {
        data.splice(index, 1);
      }
      data.coupon = coupon
      const cart_data = {
        id: cartData._id,
        items: data,
        deleted_items: [item],
        user_id: cartData.user_id,
      };
      CartService.addToCart(cart_data).then((res) => {
        CartService.getByUserId(userData?.id).then((res) => {
          setCartData(res.data.data);
          setItems(res.data.data?.items);
          res.data.data?.items?.length < 1 ? setEmpty(true) : setEmpty(false);
          props?.removeToCartHandler({
            totalCartItems: res.data.data?.items?.length,
          });
        });
      });
    } else {
      const localCartData = JSON.parse(localStorage.getItem("cart_data"));
      console.log(localCartData);
      console.log("remove");
      if (index > -1) {
        localCartData.splice(index, 1);
        localStorage.setItem("cart_data", JSON.stringify(localCartData));
        const newCartData = JSON.parse(localStorage.getItem("cart_data"));
        setCartData(newCartData);
        setItems(newCartData);
        newCartData?.length < 1 ? setEmpty(true) : setEmpty(false);
        props?.removeToCartHandler({
          totalCartItems: newCartData?.length ?? 0,
        });
      }
    }
  };

  const increaseQuantity = (item, index) => {
    const data = items;
    ProductService.getVariant(item.product_id, item.product_variants_id).then(
      (res) => {
        const original_quantity =
          res.data.data[0]?.ProductVariants[0]?.Stocks[0]?.quantity;
        // if(original_quantity > item.quantity) {
        data[index].quantity = data[index]?.quantity + 1;
        data[index].coupon = coupon

        const requestData = {
          id: cartData?._id,
          items: data,
          user_id: cartData?.user_id,
        };

        setTimeout(() => {
          CartService.addToCart(requestData)
            .then((res) => {
              setCartData(res.data.data);
              setItems(res.data.data?.items);

              let sum = 0;
              res.data.data?.items?.forEach((element) => {
                sum += element?.quantity * parseInt(element?.main_mrp);
                // sum = element?.coupon == "NEW10" ? sum * 0.9 : sum
              });
              // sum = checkCoupon ? sum * 0.9 : sum
              setSubAmount(sum);
              const total = parseInt(subAmount) + parseInt(flatAmount);
              setTotalAmount(parseInt(total));
            })
            .catch((e) => console.log(e));
        }, 500);
        // }
      },
    );
  };

  const decreaseQuantity = (item, index) => {
    const data = items;
    if (data[index].quantity - 1 > 0) {
      data[index].quantity -= 1;
      data[index].coupon = coupon
      const requestData = {
        id: cartData?._id,
        items: data,
        user_id: cartData?.user_id,
      };

      CartService.addToCart(requestData)
        .then((res) => {
          setCartData(res.data.data);
          setItems(res.data.data?.items);

          let sum = 0;
          res.data.data?.items.forEach((element) => {
            sum += element.quantity * parseInt(element.main_mrp || element.mrp);
            // sum = element?.coupon == "NEW10" ? sum * 0.9 : sum
          });
          // sum = checkCoupon ? sum * 0.9 : sum
          setSubAmount(sum);
          const total = parseInt(subAmount) + parseInt(flatAmount);
          setTotalAmount(parseInt(total));
        })
        .catch((e) => console.log(e));
    }
  };

  const applyCoupon = async () => {

    if (checkCoupon) {
      toast.error("At a time only one coupon use");
      return
    }
    console.log("Helllll");
    if (coupon === "NEW10") {
      OrderService.getDataByUserId(userData?.id).then((res) => {
        if (res?.data?.success && res?.data?.data?.count > 0) {
          toast.error("This coupon is valid for first order");
        }
        else {
          setCheckCoupon(true);
          const data = items.map(item => {
            return {
              ...item,
              main_mrp: item.mrp,
              mrp: item.mrp * 0.9,
              coupon: "NEW10"
            };
          });

          const requestData = {
            id: cartData?._id,
            items: data,
            user_id: cartData?.user_id,
          };

          CartService.addToCart(requestData)
            .then((res) => {
              setCartData(res.data.data);
              setItems(res.data.data?.items);

              let sum = 0;
              res.data.data?.items.forEach((element) => {
                sum += element.quantity * parseInt(element.mrp);
              });
              setSubAmount(sum);
              const total = parseInt(subAmount) + parseInt(flatAmount);
              setTotalAmount(parseInt(total));
            })
            .catch((e) => console.log(e));
        }
        // const decreasedAmount = subAmount * 0.9;
        // setSubAmount(decreasedAmount);
      }).catch((error) => {
        toast.error("Something Wrong");
      })
    } else {
      const reqObj = {
        id: userData?.id,
        coupon: coupon
      }
      await CouponService.getCoupon(reqObj).then((res) => {
        const couponData = res?.data?.data
        console.log("getCoupon >>", couponData);

        if (res?.status === 201) {
          toast.error("Invalid Coupon");
        }

        if (res?.status === 202) {
          toast.error("Coupon is expired");
        }

        setCheckCoupon(true);
        const data = items.map(item => {
          // Calculate the discounted price
          const discountedPrice = item.mrp - (couponData?.amount || 0);

          // Ensure the price is not less than 1
          const finalPrice = discountedPrice < 1 ? 1 : discountedPrice;

          return {
            ...item,
            main_mrp: item.mrp,
            mrp: finalPrice,
            coupon: couponData?.coupon_code
          };
        });

        const requestData = {
          id: cartData?._id,
          items: data,
          user_id: cartData?.user_id,
        };

        CartService.addToCart(requestData)
          .then((res) => {
            setCartData(res.data.data);
            setItems(res.data.data?.items);

            let sum = 0;
            res.data.data?.items.forEach((element) => {
              sum += element.quantity * parseInt(element.mrp);
            });
            setSubAmount(sum);
            const total = parseInt(subAmount) + parseInt(flatAmount);
            setTotalAmount(parseInt(total));
          })
          .catch((e) => console.log(e));

      }).catch((err) => {
        toast.error("Something Wrong");
      })
    }
  }

  function applyPointsToProducts(products, points) {
    let remainingPoints = points;

    const updatedProducts = products.map(product => {
      const productMRP = parseInt(product.mrp, 10);
      const usedPoints = Math.min(remainingPoints, productMRP);

      product.used_points = usedPoints;
      product.mrp = productMRP - usedPoints;
      remainingPoints -= usedPoints;

      return product;
    });

    return { updatedProducts, remainingPoints };
  }

  const applyCredit = async () => {
    if (userData) {
      setCreditBtn(true)
      await CouponService.getCredit({ id: userData.id }).then((res) => {
        const userCredit = res?.data?.data
        if (point > userCredit?.credit_point) {
          toast.error("Please Add valid points.");
          return
        }

        if (subAmount < point) {
          toast.error("Please Add less than total price points.");
          return
        }

        console.log(items, "items");
        const product = [...items]
        console.log(product, "product");

        const updatedProducts = applyPointsToProducts(product, point);

        console.log(updatedProducts, "updatedProducts");

        const requestData = {
          id: cartData?._id,
          items: updatedProducts?.updatedProducts,
          user_id: cartData?.user_id,
          isApplyCredit: true
        };

        CartService.addToCart(requestData)
          .then((res) => {
            setCartData(res.data.data);
            setItems(res.data.data?.items);

            let sum = 0;
            res.data.data?.items.forEach((element) => {
              sum += element.quantity * parseInt(element.mrp);
            });
            setSubAmount(sum);
            const total = parseInt(subAmount) + parseInt(flatAmount);
            setTotalAmount(parseInt(total));
          })
          .catch((e) => console.log(e));

      }).catch((err) => {
        console.log("err >>>", err);
      })
    }
  }

  return (
    <>
      {!loading ? (
        <div className="cart-w">
          {!isEmpty ? (
            <div className="container">
              <div class="row mb-4">
                <h1 class="display-6 text-dark mb-4 text-center page-title">
                  Cart
                </h1>
              </div>

              <div className="container">
                <div className="post-content">
                  <div className="back-to">
                    <a
                      className="button outlined btn-sm"
                      href="/the-shop"
                      title="Back to Shop"
                    >
                      <i className="bi bi-chevron-left">
                        <FaAngleLeft
                          className="mb-2"
                          style={{ fontSize: 20 + "px" }}
                        />
                      </i>
                      Continue Shopping
                    </a>
                  </div>

                  <div className="row g-lg-4 gy-4 shopping-cart-content">
                    <div className="cart-items col-lg-7 col-xl-8">
                      {items?.map((item, index) => {
                        console.log("item ", items);
                        return (
                          <div key={index}>
                            <hr className="pb-4" />

                            <div className="d-flex">
                              <div className="product-thumbnail">
                                <a href="">
                                  <img src={item.image} alt="" />
                                </a>
                              </div>

                              <div
                                className="product-details"
                                style={{ width: "500px" }}
                              >
                                <div className="row">
                                  <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="d-flex">
                                      <div className="product-name-price">
                                        <a href=""> {item.product_name} </a>
                                        <div className="d-flex justify-content-between align-items-center">
                                          <span className="price">
                                            <ins className="p-0">
                                              <span className="amount">
                                                <bdi>
                                                  <span>₹</span>
                                                  {item.mrp}
                                                </bdi>
                                              </span>
                                            </ins>
                                            <p className="mb-0 mt-2">
                                              <span>Color: </span>
                                              {item?.color}
                                            </p>
                                            <p className="mb-0">
                                              <span>Size: </span>
                                              {item?.size}
                                            </p>
                                          </span>
                                        </div>
                                      </div>
                                      {/* <div className="product-color-size mx-2">
                                <div className="d-flex justify-content-between align-items-center">
                                  <span className="price">
                                    <ins className="p-0">
                                      <span className="amount">
                                        <bdi>
                                          <span>Color:</span>
                                          {item.color}
                                        </bdi>
                                        <br />
                                        <bdi>
                                          <span>Size:</span>
                                          {item.size}
                                        </bdi>
                                      </span>
                                    </ins>
                                  </span>
                                </div>
                              </div> */}
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="quantity">
                                      <span
                                        className="minus"
                                        onClick={() =>
                                          decreaseQuantity(item, index)
                                        }
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="24"
                                          height="24"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="currentColor"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          className="feather feather-minus"
                                        >
                                          <line
                                            x1="5"
                                            y1="12"
                                            x2="19"
                                            y2="12"
                                          ></line>
                                        </svg>
                                      </span>
                                      <input
                                        // type="number"
                                        id="quantity_6355475f3e874"
                                        className="input-text qty"
                                        step="1"
                                        min="1"
                                        name="quantity"
                                        value={item.quantity}
                                        title="Qty"
                                        placeholder=""
                                        inputMode="numeric"
                                        autoComplete="off"
                                        max=""
                                      />
                                      <span
                                        className="plus"
                                        onClick={() =>
                                          increaseQuantity(item, index)
                                        }
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="24"
                                          height="24"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="currentColor"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          className="feather feather-plus"
                                        >
                                          <line
                                            x1="12"
                                            y1="5"
                                            x2="12"
                                            y2="19"
                                          ></line>
                                          <line
                                            x1="5"
                                            y1="12"
                                            x2="19"
                                            y2="12"
                                          ></line>
                                        </svg>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="product-subtotal d-flex flex-column justify-content-between align-items-center">
                                <div className="product-subtotal">
                                  <span className="price">
                                    <ins className="p-0">
                                      <span className="amount">
                                        <bdi>
                                          <span>₹</span>
                                          {item.quantity * item.main_mrp || item.mrp}
                                        </bdi>
                                      </span>
                                    </ins>
                                  </span>
                                </div>

                                <div className="close">
                                  <span
                                    href=""
                                    className="remove"
                                    onClick={() => removeCart(item, index)}
                                  ></span>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}

                      <hr className="my-4" />

                      <div className="coupon d-flex justify-content-between align-items-center">
                        <div className="form-row float-label me-2">
                          <input
                            name="credit"
                            type="text"
                            className="input-text"
                            placeholder="Credit point"
                            value={point}
                            onChange={(e) => setPoint(e.target.value)}
                          />
                        </div>
                        <button
                          type="submit"
                          className="button"
                          name="credit_point"
                          value="Credit point"
                          disabled={creditBtn}
                          onClick={() => { applyCredit() }}
                        >
                          Apply credit
                        </button>

                        <div className="form-row float-label me-2">
                          <input
                            name="coupon"
                            type="text"
                            className="input-text"
                            placeholder="Coupon code"
                            value={coupon}
                            onChange={(e) => setCoupon(e.target.value)}
                          />
                        </div>
                        <button
                          type="submit"
                          className="button"
                          name="apply_coupon"
                          value="Apply coupon"
                          onClick={() => { applyCoupon() }}
                        >
                          Apply coupon
                        </button>
                      </div>

                    </div>

                    <div className="cart-collaterals col">
                      <div className="collaterals-inners">
                        <div className="cart-totals">
                          <h5>Cart Totals</h5>

                          <hr />

                          <div className="cart-subtotal d-flex justify-content-between align-items-center">
                            <p className="m-0">Subtotal</p>
                            <span className="price">
                              <ins className="p-0">
                                <span className="amount">
                                  <bdi>
                                    <span>₹</span>
                                    {mainAmount}
                                  </bdi>
                                </span>
                              </ins>
                            </span>
                          </div>

                          <hr />
                          <div className="cart-subtotal pb-0 d-flex justify-content-between align-items-center">
                            <p className="m-0">Discount:</p>
                            <span className="price">
                              <ins className="p-0">
                                <span className="amount">
                                  <bdi>
                                    <span>₹</span>
                                    {discountAmount}
                                  </bdi>
                                </span>
                              </ins>
                            </span>
                          </div>
                          <hr />

                          <div className="shipping pb-0 d-flex justify-content-between align-items-center">
                            <p className="shipping-title m-0">Shipping</p>
                          </div>

                          <div className="cart-subtotal pb-0 d-flex justify-content-between align-items-center">
                            <p className="m-0">Flat Rate:</p>
                            <span className="price">
                              <ins className="p-0">
                                <span className="amount">
                                  <bdi>
                                    <span>₹</span>
                                    {flatAmount}
                                  </bdi>
                                </span>
                              </ins>
                            </span>
                          </div>

                          {/* <div className="shipping-destination d-flex justify-content-between align-items-center p-0">
                    <p className="m-0">
                      Shipping to <span className="shipping-country">CA</span>
                    </p>
                  </div> */}

                          {/* collapsible change address  */}
                          {/* <div className="d-flex align-items-center pb-4 pt-0">
                            <a
                              className="shipping-calculator-button"
                              data-bs-toggle="collapse"
                              href="#collapseExample"
                              aria-expanded="false"
                              aria-controls="collapseExample"
                            >
                              <i className="bi bi-geo-alt"></i>
                              Change address
                              <i className="bi bi-chevron-down"></i>
                            </a>
                          </div> */}

                          {/* <div className="collapse" id="collapseExample">
                            <div className="shipping-calculator p-0">
                              <p className="form-row float-label">
                                <input
                                  name="city"
                                  type="text"
                                  className="input-text w-100"
                                  placeholder="Town / City *"
                                />
                              </p>
                              <p className="form-row float-label">
                                <input
                                  name="zipcode"
                                  type="text"
                                  className="input-text w-100"
                                  placeholder="Postcode / ZIP *"
                                />
                              </p>

                              <p>
                                <button
                                  type="submit"
                                  className="button update-button outlined"
                                >
                                  Update
                                </button>
                              </p>
                            </div>
                          </div> */}
                          {/* collapsible change address  */}

                          <hr />

                          <div className="order-total d-flex justify-content-between align-items-center">
                            <p className="text-uppercase m-0 fw-semibold">
                              TOTAL
                            </p>
                            <span className="price">
                              <ins className="p-0">
                                <span className="amount text-dark fw-semibold">
                                  <bdi>
                                    <span>₹</span>
                                    {subAmount}
                                  </bdi>
                                </span>
                              </ins>
                            </span>
                          </div>

                          <div className="proceed-to-checkout">
                            <p
                              className="checkout-button button alt wc-forward bg-dark"
                              onClick={() => navigate("/checkout")}
                            >
                              Proceed to checkout
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="empty-cart-list">
              <img src={emptyBag} className="img-fluid" alt="Empty Bag" />
              <h6>
                Oops! It seems like your cart is feeling a bit lonely right now.
              </h6>
              <p>
                Feel free to browse our amazing collection and add some items to
                your cart to make it happier!
              </p>
              <br />
              <a href="/the-shop" className="cool-link">
                Continue Shopping
              </a>
            </div>
          )}
        </div>
      ) : (
        <div className="spinner-container">
          <BeatLoader
            color="#242323"
            loading={loading}
            // cssOverride={override}
            // size={50}
            aria-label="Loading Spinner"
            data-testid="loader"
            className="loader"
          />
        </div>
      )}
    </>
  );
};

export default Cart;
