import React from "react";
import "./about-us.css";
import { GiCheckMark } from "react-icons/gi";
import img1 from "../../assets/images/aboutUs1.JPG";
import img2 from "../../assets/images/aboutUs2.JPG";
import img3 from "../../assets/images/social.jpeg";
import img4 from "../../assets/images/aboutUs4 (1).jpg";
import { Helmet } from "react-helmet";
import logo from "../../assets/images/logo.png";
const AboutUs = () => {
  return (
    <section>
      <Helmet>
        <title>About Us : Sugandh Gupata</title>
        <meta
          name="description"
          content="Fashion is art, culture and comfort. We at WOS, create perfect art for you along with designs that embrace our culture and heritage at its best keeping in mind the comfort of every woman in the society. "
        />
        <meta name="keywords" content="Fashion is art, culture and comfort" />
        <link rel="icon" type="image/png" href={logo} />
      </Helmet>

      <div className="container">
        <div className="row about-para">
          {/* <div className="about-designer"> */}
          <h3 style={{ textAlign: "center", margin: "30px 0" }}>
            ABOUT DESIGNER
          </h3>
          <p className="center">
            Sugandh Gupta, our founder firmly believes in the joy of doing what
            you love!Loves white. Loves minimalism. Her brain is a sustainable
            fibre dictionary. Likes to throw in new ideas when something was
            just decided. cares for clean fashion. always on her desk: macbook,
            phone and coffee. She loves designing, which not only makes her a
            significant designer but also a manifestor of doing what she loves.
            She has an aura that's western yet deeply rooted in Indian culture.
            Everyday she is emitting fragrance of purity, positivity and
            contemporary designs through her Label. She began her journey of
            learning with a Business Degree at a reputed Institute of Ahmedabad,
            soon she realised what her true passion was! She completed the
            degree and then joined NIFT Gandhinagar to celebrate her passion
            towards fashion and learn the same. She learnt keeping in mind her
            way of styling which involves tradition and social aspects.
            Following her heart she started her fashion apparel brand with the
            name Sugandh which means Fragrance and standing true to its name it
            Continues to spread fragrance of what the brand believes in.
          </p>
          <p>
            With true entrepreneurial spirit and a serious passion for
            sustainable fashion and female empowerment, Sugandh married these
            two pursuits when founding this brand. Though " abhi kuch missing
            hai" to " abhi aur detailing chaiya", she creates each design with
            the mission to instill confidence in women around the world of all
            groups. With passion for designing, Sugandh captures the most unique
            elements of every outfit before it hits the market.
          </p>
          <img src={img1} alt="image" className="about-image" />
          <p className="p-3">
            GROWING UP I ALWAYS SAW MY PARENTS BEING VERY PARTICULAR ABOUT
            CLOTHING, LOOKING AT THEM I ALWAYS LEARNED THE SENSE OF DRESSING AND
            IMPORTANCE OF DRESSING UP. WITH THOSE QUALITIES RUNNING IN MY GENES
            I THOUGHT TO DRESS UP THE WORLD AROUND ME.. AND HERE I AM WITH WORLD
            OF SUGANDH.
            <br />- SUGANDH GUPTA , CREATIVE DIRECTOR
          </p>
          {/* </div> */}
          {/* <div className="about-brand"> */}
          <h3 style={{ textAlign: "center", margin: "30px 0" }}>
            ABOUT THE BRAND
          </h3>
          <p>
            A brand stands on the Vision of the founder. Our founder believes in
            creating a world where culture, tradition is given its due respect
            and importance in the fashion world. That the world needs to know
            more about the great craft and architecture that we inherit as a
            country.
          </p>
          <p>
            To achieve our vision, there are certain missions we continue to
            fulfil everyday. Every design created has a deep connection with our
            Indian heritage. We use handmade and different handwoven loom fabric
            which are produced by artisans and craftsmen for every piece that
            WOS Delivers. Support and to empower these artisans while working on
            a sustainable environment is our focus.
          </p>
          <img src={img2} alt="image" className="about-image" />
          <p className="p-3">
            We spread the power of traditional fabric while making it look
            fashionable. Also we mix indigenous craft techniques with
            contemporary designs to bring good aesthetic and affordable products
            to pure valued customers. We are more than clothes. With our
            #worldofsugandh initiative we urge people to come join us in various
            ideology’s like holding on to our tradition, respecting and
            empowering women, use of pure and organic fabrics and saving our
            environment.
          </p>
          {/* </div> */}
          {/* <div className="social"> */}
          <h3 style={{ textAlign: "center", margin: "30px 0" }}>
            CONNECTION WITH SOCIAL ASPECT
          </h3>
          <p>
            As a country we are so deeply connected to our traditions that
            everything we do our culture holds tight to us. We decided to hold
            our culture back tightly and tell it that whatever we all are today
            is because of the promises made by our culture and its rich
            heritage. As a country, we are a people’s land, we love loving each
            other and thus social acceptance is a huge part of our lives. As a
            brand of apparels we promise to make designs keeping in mind this
            acceptance. When we do that we bring in comfort and confidence in
            all our women.
          </p>
          <img src={img3} alt="image" className="about-image" />
          <p>
            We are not trying to say that a woman needs to be told what to wear.
            She should wear whatever suits her personality and mindset and we
            will try to create exactly that for her. #BeYou.
            <br />
            Also our attention towards sustainable fashion is immense. We truly
            understand that fashion contributes to being one of the largest
            reasons for waste. Hence use of materials that are pure and organic
            and do not affect the environment Is our first concern.
            <br />
            At the same time we are making our designs modern so that every lady
            that wears them feels as fresh as the fabric and stands fashionable.
          </p>
          {/* </div> */}
          {/* <div className="initiative"> */}
          <h3 style={{ textAlign: "center", margin: "30px 0" }}>
            MAKE IN INDIA INITIATIVE
          </h3>
          <p>
            The “Make In India” initiative launched on 25th September 2014,
            carries the objective of job creation and skill enhancement which
            will make India a global design and a manufacturing hub.
          </p>
          <img src={img4} alt="image" className="about-image" />

          <div className="package">
            This initiative is the base of our brand ideology and matters the
            most to us. We take care of it by doing and following these:
            <br />
            <ul>
              <li>
                Every piece and everything used is made in India, start to end.
              </li>
              <li>
                We focus on job creations for artisans and valuing what they do
                best.
              </li>
              <li>
                Also skill creation is something that we largely support and
                encourage.
              </li>
              <li>
                Our entire process involves people who are truly skilled In this
                industry but are not given their due importance and
                appreciation.
              </li>
              <li>
                Women empowerment is not just a part of our ideology and
                designs, but of production tool. We make women participate in
                the embroidery process and encourage them to do what they are
                good at.
              </li>
            </ul>
          </div>

          {/* <p className="p-3">
                            <GiCheckMark />  Every piece and everything used is made in India, start to end.<br />
                            <GiCheckMark />  We focus on job creations for artisans and valuing what they do best.<br />
                            <GiCheckMark />  Also skill creation is something that we largely support and encourage.<br />
                            <GiCheckMark />  Our entire process involves people who are truly skilled In this industry but
                            are not given their due importance and appreciation.<br />
                            <GiCheckMark />  Women empowerment is not just a part of our ideology and designs, but of production tool. We make women participate in the embroidery process and encourage them to do what they are good at.
                        </p> */}

          {/* </div> */}
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
