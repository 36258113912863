import Cart from '../components/Cart'
import {connect} from 'react-redux'
import { removeToCart } from '../services/actions/actions'

const mapStateToProps=state=>({
    data:state?.cartItems
})
const mapDispatchToProps=dispatch=>({
    removeToCartHandler:data=>dispatch(removeToCart(data))
})
export default connect(mapStateToProps,mapDispatchToProps)(Cart)