import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import search from "../assets/images/magnifiying-glass.png";

const SearchBar = () => {
  const navigate = useNavigate();
  const [searchKeyword, setSearchKeyword] = useState();

  const searchProduct = (event) => {
    setTimeout(() => {
      navigate("/the-shop", {
        state: { search_keyword: event?.target?.value },
      });
    }, 1500);
  };
  return (
    <>
      <div className="search-container d-none d-lg-flex">
        <input
          type="text"
          name="search"
          placeholder="Search..."
          className="search-input"
          onKeyUp={(event) => searchProduct(event)}
        />
        <a href="#" className="search-btn">
          <i className="fas fa-search">
            <img src={search} className="icon search" alt="Search" />
          </i>
        </a>
      </div>

      <div className="search-container mobile-search d-flex d-lg-none">
        <form
          action=""
          // onSubmit={searchProduct()}
          className="d-flex w-100"
        >
          <input
            type="text"
            name="search"
            placeholder="Search..."
            className="search-input"
            onKeyUp={(event) => searchProduct(event)}
          />
          <div
            type="submit"
            className="search-btn d-flex flex-column justify-content-center align-items-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#000"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-search"
            >
              <circle cx="11" cy="11" r="8"></circle>
              <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
            </svg>
          </div>
        </form>
      </div>
    </>
  );
};
export default SearchBar;
