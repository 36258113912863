import "./App.css";
import Header from "./containers/HeaderContainer";
import Footer from "./components/Footer";
import Test from "./Modules/Test/test";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import LoadingSpinner from "./components/LoadingSpinner";
import WhatsAppLogo from "./assets/images/whatsapp.svg";
import { FaArrowUp } from "react-icons/fa";
import React from "react";

function App() {
  const [position, setPosition] = React.useState({ top: 0, left: 0 });
  React.useEffect(() => {
    window.scroll({
      top: position.top,
      left: position.left,
      behavior: "smooth",
    });
  });

  const [visibility, setVisibility] = React.useState(false);
  const scrollTop = React.useRef();
  React.useEffect(() => {
    window.addEventListener("scroll", (e) => {
      window.scrollY > 150
        ? (scrollTop.current.style.display = "inline-block")
        : (scrollTop.current.style.display = "none");
    });
  });

  return (
    <>
      {/* <LoadingSpinner /> */}
      {/* < Test/> */}
      <Header />
      {/* <Lookbook /> */}
      <Footer />

      <>
        <span
          onClick={() =>
            setPosition({ ...position, position: { top: 0, left: 0 } })
          }
          className="circle"
          ref={scrollTop}
        >
          <FaArrowUp />
        </span>
      </>

      <div className="whatsapp_btn">
        <div className="icon_logo">
          <a href="https://wa.me/919638442222" target="_blank">
            <img src={WhatsAppLogo} alt="" />
          </a>
        </div>
      </div>
    </>
  );
}

export default App;
