import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import "../Lookbook/Lookbook.css";
import "../../js/main";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

import { Navigation, Pagination } from "swiper/modules";
import "swiper/css/navigation";
import "swiper/css/pagination";

import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/thumbs";
import "swiper/css/scrollbar";
import CollectionService from "../../api-config/collection";
import ProductService from "../../api-config/product";
import blankImage from "../../assets/images/about-team.png";

const Collections = (props) => {
  const navigate = useNavigate();
  const [collectionData, setCollectionData] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    CollectionService.getAll().then(
      (res) => {
        setCollectionData(res.data.data);
        setTimeout(() => {
          setLoading(false);
        }, 800);
      },
      [setCollectionData],
    );
  }, []);

  const redirectOnShop = (item) => {
    navigate(`/the-shop/collections/${item.name}`, {
      state: { collection_id: item?.id },
    });
  };

  return (
    <>
      {/* {!loading ? ( */}
      <section className="lookbook">
        <div id="main">
          <div className="container">
            <div className="main-title">
              <h1>Collections</h1>
            </div>

            <div className="collection-list">
              <div className="row">
                {collectionData?.map((item, i) => {
                  return (
                    <div className="col-6 col-md-4 col-lg-3 mb-3" key={i}>
                      <div
                        className="card text-white seasons"
                        onClick={() => redirectOnShop(item)}
                      >
                        <div className="hover-overlay"></div>
                        <img
                          src={item.thumbnail_image || blankImage}
                          className="card-img"
                          alt="..."
                        />
                        <div className="card-img-overlay">
                          {/* <aside className="portfolio-categories">
                              {item.name}
                            </aside> */}
                        </div>
                      </div>
                      <h5 className="text-align-center">{item.name}</h5>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>

        {/* <div className="container portfolio mb-5">
            <div
              className="row gx-3 gy-3"
            >
              {productData?.map((item) => {
                return (
                  <div key={item.id} className="col-6 col-md-4 col-lg-4">
                    <a href="#">
                      <div className="card text-white seasons">
                        <div className="hover-overlay"></div>
                        <img
                          src={item.thumbnail_image}
                          className="card-img"
                          alt="..."
                        />
                        <div className="card-img-overlay">
                          <aside className="portfolio-categories">
                            {item.name}
                          </aside>
                        </div>
                      </div>
                    </a>
                  </div>
                );
              })}
            </div>
          </div> */}
      </section>
      {/* ) : (
        <div className="spinner-container">
          <BeatLoader
            color="#242323"
            loading={loading}
            aria-label="Loading Spinner"
            data-testid="loader"
            className="loader"
          />
        </div>
      )} */}
    </>
  );
};

export default Collections;
