import axios from "axios";

export default axios.create({
  // baseURL: "http://localhost:5021/public/api",
  baseURL: "https://api.sugandh.co/public/api",

  headers: {
    "Content-Type": "application/json",
  },
});
