import React, { useState } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "./Login.css";
import ForgotPasswordService from "../../api-config/forgot-password";
import { FaEye, FaEyeSlash } from "react-icons/fa";

import "react-tabs/style/react-tabs.css";

const ResetPassword = (props) => {
  const navigate = useNavigate();
  const [showPassword1, setShowPassword1] = useState(false);
  const [btnTitle, setbtnTitle] = useState("Change Password");


  const formik = useFormik({
    initialValues: {
      password: "",
    },
    validateOnChange: false,
    onSubmit: async (values) => {
      if (values?.password.length > 0) {
        const queryParams = new URLSearchParams(window.location.search);
        const id = queryParams.get("token");
        const payload = {
          id: id,
          password: values?.password,
        };
        ForgotPasswordService.resetPasswordV2(payload).then((res) => {
          if(res.status == 200){
            toast.success("Password reset successfully.")
            navigate("/Login")
          }
        }).catch((err) => {
          toast.error("Something Wrong.");
        })
      } else {
        toast.error("Please enter new password.");
      }
    },
  });


  const { handleChange, handleSubmit } = formik;

  return (
    <>
      <div className="wrap-login100">
        <div className="login-tab">
          <form
            className="login100-form validate-form"
            onSubmit={formik.handleSubmit}
          >
            <div className="mb-3">
              <div
                className="wrap-input100 validate-input"
                data-validate="Enter password"
              >
                <span className="show-eye">
                  {showPassword1 ? (
                    <FaEyeSlash onClick={() => setShowPassword1(false)} />
                  ) : (
                    <FaEye onClick={() => setShowPassword1(true)} />
                  )}
                </span>
                <input
                  type={showPassword1 ? "text" : "password"}
                  className="input100 has-val"
                  placeholder="Enter your new password"
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                />
              </div>
            </div>
            <div className="container-login100-form-btn">
              <div className="wrap-login100-form-btn">
                <div className="login100-form-bgbtn"></div>
                <button
                  className="login100-form-btn"
                  type="submit"
                >
                  {btnTitle}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
