/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Modal from "react-modal";
import { BeatLoader } from "react-spinners";
import Accordion from "react-bootstrap/Accordion";
import { FaTimes } from "react-icons/fa";
import { FaChevronLeft } from "react-icons/fa";
import { BsXLg } from "react-icons/bs";

import SubCategoryService from "../../api-config/sub-category";
import CollectionService from "../../api-config/collection";
import BrandService from "../../api-config/brand";
import ShopService from "../../api-config/shop";
import ProductService from "../../api-config/product";
import ProductInfo from "../../containers/ProductInfoContainer";
import blankImage from "../../assets/images/about-team.png";
import filter from "../../assets/images/filter.png";
import emptyProduct from "../../assets/images/240_F_566237511_Pp2i6sDz4Pr97WrEMgGSGunIWCstce8a.jpg";
import "./shop.css";
import { Helmet } from "react-helmet";
import logo from "../../assets/images/logo.png";
import Sort from "../../components/sort";

Modal.setAppElement("#root");

const TheShop = () => {
  const { id, collection_name } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [product, setProduct] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [productId, setProductId] = useState();
  const [productName, setProductName] = useState();
  const [productCategory, setProductCategory] = useState();
  const [subCategoryData, setSubCategoryData] = useState();
  const [color, setColor] = useState();
  const [size, setSize] = useState();
  const [subCategoryFilter, setSubCategoryFilter] = useState([]);
  const [colorFilter, setColorFilter] = useState([]);
  const [sizeFilter, setSizeFilter] = useState([]);
  const [collectionFilter, setCollectionFilter] = useState([]);
  const [collection, setCollection] = useState();
  const [brandFilter, setBrandFilter] = useState([]);
  const [brand, setBrand] = useState();
  const [openNav, setOpenNav] = useState(true);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPageSize, setTotalPageSize] = useState(1);

  useEffect(() => {
    SubCategoryService.getAll().then((res) => {
      setSubCategoryData(res.data.data);
    });

    ShopService.getColor().then((res) => {
      setColor(res.data.data);
    });

    ShopService.getSize().then((res) => {
      setSize(res.data.data);
    });

    CollectionService.getAll().then((res) => {
      setCollection(res.data.data);
    });

    BrandService.getAll().then((res) => {
      setBrand(res.data.data);
    });

    if (
      subCategoryFilter.length > 0 ||
      colorFilter.length > 0 ||
      sizeFilter.length > 0 ||
      collectionFilter.length > 0 ||
      brandFilter.length > 0
    ) {
      ProductService.getFilteredData(
        subCategoryFilter,
        colorFilter,
        sizeFilter,
        collectionFilter,
        brandFilter,
      )
        .then((res) => {
          setProduct(res.data.data);
        })
        .catch((e) => console.log(e));
    }
  }, [
    colorFilter,
    location.state?.id,
    sizeFilter,
    subCategoryFilter,
    collectionFilter,
    brandFilter,
  ]);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const scrollTop =
  //       (document.documentElement && document.documentElement.scrollTop) ||
  //       document.body.scrollTop;
  //     const scrollHeight =
  //       (document.documentElement && document.documentElement.scrollHeight) ||
  //       document.body.scrollHeight;
  //     const clientHeight = document.documentElement.clientHeight;

  //     if (scrollTop + clientHeight >= scrollHeight - 10 && !isLoading) {
  //       setIsLoading(true);
  //       if (currentPage < totalPageSize) {
  //         setCurrentPage((currentPage) => currentPage + 1);
  //       }
  //     }
  //   };
  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, [isLoading]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentPage,
    id,
    location.state?.collection_id,
    location.state?.search_keyword,
    location?.state?.pathname,
  ]);

  const fetchData = async () => {
    if (location.state?.brand_id) {
      const brand_id = location.state?.id ?? id;
      ProductService.getByBrandId(location?.state?.brand_id, currentPage).then(
        (res) => {
          if (product && currentPage !== 1) {
            setProduct((prevData) => [...prevData, ...res.data.data]);
          } else {
            setProduct(res.data.data);
          }
          setTotalPageSize(res.data?.page_info?.total_pages);
          setTimeout(() => {
            setLoading(false);
          }, 800);
        },
      );
    } else if (id && id.length > 3) {
      SubCategoryService.getByKeyword(id).then((res) => {
        if (res.data.success) {
          ProductService.getBySubCategoryId(
            res.data.data[0]?.id,
            currentPage,
          ).then((res) => {
            if (product && currentPage !== 1) {
              setProduct((prevData) => [...prevData, ...res.data.data]);
            } else {
              setProduct(res.data.data);
            }
            setTotalPageSize(res.data?.page_info?.total_pages);
            setTimeout(() => {
              setLoading(false);
            }, 800);
          });
        }
      });
    } else if (location?.pathname === "/the-shop/collections/Rozana") {
      ProductService.getByCollectionId([13, 14], currentPage).then((res) => {
        if (res.data.success) {
          if (product && currentPage !== 1) {
            setProduct((prevData) => [...prevData, ...res.data.data]);
          } else {
            setProduct(res.data.data);
          }
          setTotalPageSize(res.data?.page_info?.total_pages);
          setTimeout(() => {
            setLoading(false);
          }, 800);
        }
      });
    } else if (collection_name) {
      CollectionService.getByKeyword(collection_name).then((res) => {
        if (res.data.success) {
          ProductService.getByCollectionId(
            res.data.data[0]?.id,
            currentPage,
          ).then((res) => {
            if (res.data.success) {
              if (product && currentPage !== 1) {
                setProduct((prevData) => [...prevData, ...res.data.data]);
              } else {
                setProduct(res.data.data);
              }
              setTotalPageSize(res.data?.page_info?.total_pages);
              setTimeout(() => {
                setLoading(false);
              }, 800);
            }
          });
        }
      });
    } else if (location.state?.search_keyword) {
      ProductService.getByKeyword(location.state?.search_keyword).then(
        (res) => {
          if (res.data.success) {
            if (product && currentPage !== 1) {
              setProduct((prevData) => [...prevData, ...res.data.data]);
            } else {
              setProduct(res.data.data);
            }
            setTotalPageSize(res.data?.page_info?.total_pages);
            setTimeout(() => {
              setLoading(false);
            }, 800);
          }
        },
      );
    } else {
      ProductService.getAllPagination(currentPage).then((res) => {
        if (product && currentPage !== 1) {
          setProduct((prevData) => [...prevData, ...res.data.data]);
        } else {
          setProduct(res.data.data);
        }
        setTotalPageSize(res.data?.page_info?.total_pages);
        setTimeout(() => {
          setLoading(false);
        }, 800);
      });
    }
  };

  const getFilterData = (id, name) => {
    if (name === "category") {
      if (subCategoryFilter.includes(id)) {
        setSubCategoryFilter(
          subCategoryFilter.filter((categoryId) => categoryId !== id),
        );
      } else {
        setSubCategoryFilter([...subCategoryFilter, id]);
      }
    } else if (name === "color") {
      if (colorFilter.indexOf(id) === -1) {
        setColorFilter((prevArray) => [...prevArray, id]);
      }
    } else if (name === "size") {
      if (sizeFilter.indexOf(id) === -1) {
        setSizeFilter((prevArray) => [...prevArray, id]);
      }
    } else if (name === "collection") {
      if (collectionFilter.indexOf(id) === -1) {
        setCollectionFilter((prevArray) => [...prevArray, id]);
      }
    } else if (name === "brand") {
      if (brandFilter.indexOf(id) === -1) {
        setBrandFilter((prevArray) => [...prevArray, id]);
      }
    }

    if (
      subCategoryFilter.length > 0 ||
      colorFilter.length > 0 ||
      sizeFilter.length > 0 ||
      collectionFilter.length > 0 ||
      brandFilter.length > 0
    ) {
      ProductService.getFilteredData(
        subCategoryFilter,
        colorFilter,
        sizeFilter,
        collectionFilter,
        brandFilter,
      )
        .then((res) => {
          setProduct(res.data.data);
        })
        .catch((e) => console.log(e));
    }
  };

  const clearAllFilter = () => {
    if (
      subCategoryFilter.length > 0 ||
      colorFilter.length > 0 ||
      sizeFilter.length > 0 ||
      collectionFilter.length > 0 ||
      brandFilter.length > 0
    ) {
      fetchData();
      setSubCategoryFilter([]);
      setColorFilter([]);
      setSizeFilter([]);
      setCollectionFilter([]);
      setBrandFilter([]);
    }
  };

  const removeFilter = (id, name) => {
    if (name === "category") {
      const index = subCategoryFilter.indexOf(id);
      if (index > -1) {
        subCategoryFilter.splice(index, 1);
      }
    } else if (name === "color") {
      const index = colorFilter.indexOf(id);
      if (index > -1) {
        colorFilter.splice(index, 1);
      }
    } else if (name === "size") {
      const index = sizeFilter.indexOf(id);
      if (index > -1) {
        sizeFilter.splice(index, 1);
      }
    } else if (name === "collection") {
      const index = collectionFilter.indexOf(id);
      if (index > -1) {
        collectionFilter.splice(index, 1);
      }
    } else if (name === "brand") {
      const index = brandFilter.indexOf(id);
      if (index > -1) {
        brandFilter.splice(index, 1);
      }
    }
    ProductService.getFilteredData(
      subCategoryFilter,
      colorFilter,
      sizeFilter,
      collectionFilter,
      brandFilter,
    ).then((res) => {
      setProduct(res.data.data);
    });
  };

  const gotoProductInfo = (product) => {
    navigate(`/product-info/${product?.SubCategory?.name}/${product?.name}`, {
      state: { id: product?.id },
    });
  };

  const toggleModal = (id, name, product_category) => {
    setProductId(id);
    setProductName(name);
    setProductCategory(product_category);
    setIsOpen(!isOpen);
  };

  const filterPrice = (productVariantArray) => {
    for (let i = 0; i < productVariantArray.length; i++) {
      const element = productVariantArray[i];
      if (element?.Size?.name === "S") {
        return element?.mrp;
      }
    }
    return null;
  };

  const setFilter = (event) => {
    setLoading(true);
    if (event.target.value === "highest") {
      ProductService.getByPriceFilter(false)
        .then((res) => {
          if (res.data.success) {
            setProduct(res.data.data);
            setLoading(false);
          }
        })
        .catch((e) => console.log(e));
    } else if (event.target.value === "lowest") {
      ProductService.getByPriceFilter(true)
        .then((res) => {
          if (res.data.success) {
            setProduct(res.data.data);
            setLoading(false);
          }
        })
        .catch((e) => console.log(e));
    } else if (event.target.value === "a-z") {
      ProductService.getByAlphabetFilter(true)
        .then((res) => {
          if (res.data.success) {
            setProduct(res.data.data);
            setLoading(false);
          }
        })
        .catch((e) => console.log(e));
    } else if (event.target.value === "z-a") {
      ProductService.getByAlphabetFilter(false)
        .then((res) => {
          if (res.data.success) {
            setProduct(res.data.data);
            setLoading(false);
          }
        })
        .catch((e) => console.log(e));
    }  else if (event.target.value === "new") {
      ProductService.getByNewArrivalFilter(true)
        .then((res) => {
          if (res.data.success) {
            setProduct(res.data.data);
            setLoading(false);
          }
        })
        .catch((e) => console.log(e));
    } else {
      ProductService.getByPriceFilter(true)
        .then((res) => {
          if (res.data.success) {
            setProduct(res.data.data);
            setLoading(false);
          }
        })
        .catch((e) => console.log(e));
    }
  };

  return (
    <>
      <Helmet>
        <title>Shop World of Sugandh</title>
        <meta
          name="description"
          content="We are more than clothes. With our #worldofsugandh initiative we urge people to come join us in various ideology’s like holding on to our tradition, respecting and empowering women."
        />
        <meta name="keywords" content="Fashion is art, culture and comfort" />
        <link rel="icon" type="image/png" href={logo} />
      </Helmet>
      {!loading ? (
        <section className="banner-content shop">
          <div className="home-banner">
            <div className="main-container">
              <div
                className=""
                style={{ width: 100 + "%", maxWidth: 100 + "%" }}
              >
                <div className="row">
                  <div
                    className={
                      openNav
                        ? "col shop-sidebar-col open-nav"
                        : "col shop-sidebar-col close-nav"
                    }
                  >
                    <div className="side-panel-content ps ps--theme_default container">
                      <div className="clear-filter-div pb-2 mb-2">
                        <button
                          type="button"
                          className="clear-all-filter pe-auto"
                          onClick={() => clearAllFilter()}
                        >
                          Clear All Filter
                        </button>
                        <span
                          className="pe-auto"
                          onClick={() => setOpenNav(false)}
                        >
                          <FaChevronLeft />
                        </span>
                      </div>
                      <ul className="shop-sidebar shop-widget-scroll">
                        <Accordion flush defaultActiveKey="">
                          <Accordion.Item
                            eventKey="0"
                            id="wcapf-category-filter-1"
                            className="woocommerce wcapf-ajax-term-filter widget cf widget_wcapf-category-filter mb-2 pb-2"
                          >
                            <Accordion.Header className="widgettitle">
                              Filter by Category
                            </Accordion.Header>
                            <Accordion.Body className="py-1">
                              <div
                                id="wcapf-category-filter-1"
                                className="woocommerce wcapf-ajax-term-filter widget cf widget_wcapf-category-filter mb-2 pb-2"
                              >
                                {/* <h2 className="widgettitle collapsible d-flex justify-content-between">
                            <span> Filter by Category</span>
                            <span onClick={() => setOpenNav(false)}>
                              <FaChevronLeft />
                            </span>
                          </h2> */}
                                <div className="wcapf-layered-nav ps ps--theme_default">
                                  <ul className="category-filter">
                                    {subCategoryData?.map((item, i) => {
                                      return (
                                        <li
                                          key={i}
                                          className={
                                            subCategoryFilter.indexOf(item.id) >
                                            -1
                                              ? "active"
                                              : "not-active"
                                          }
                                        >
                                          <a
                                            href="#"
                                            data-key="product-cata"
                                            data-value="41"
                                            data-multiple-filter=""
                                          >
                                            <span
                                              className="name"
                                              onClick={() =>
                                                getFilterData(
                                                  item.id,
                                                  "category",
                                                )
                                              }
                                            >
                                              {item.name}
                                            </span>
                                            <span
                                              onClick={() =>
                                                removeFilter(
                                                  item.id,
                                                  "category",
                                                )
                                              }
                                            >
                                              &times;
                                            </span>
                                          </a>
                                          <ul className="children"></ul>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                  <div
                                    className="ps__scrollbar-x-rail"
                                    style={{ left: 0 + "px", bottom: 0 + "px" }}
                                  >
                                    <div
                                      className="ps__scrollbar-x"
                                      tabIndex="0"
                                      style={{
                                        left: 0 + "px",
                                        width: 0 + "px",
                                      }}
                                    ></div>
                                  </div>
                                  <div
                                    className="ps__scrollbar-y-rail"
                                    style={{
                                      top: 0 + "px",
                                      right: 0 + "px",
                                    }}
                                  >
                                    <div
                                      className="ps__scrollbar-y"
                                      tabIndex="0"
                                      style={{
                                        top: 0 + "px",
                                        height: 0 + "px",
                                      }}
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item
                            eventKey="1"
                            id="wcapf-attribute-filter-1"
                            className="woocommerce wcapf-ajax-term-filter widget cf widget_wcapf-attribute-filter mb-2 pb-2"
                          >
                            <Accordion.Header className="widgettitle">
                              Filter by Color
                            </Accordion.Header>
                            <Accordion.Body>
                              <div
                                id="wcapf-attribute-filter-1"
                                className="woocommerce wcapf-ajax-term-filter widget cf widget_wcapf-attribute-filter mb-2 pb-2"
                              >
                                {/* <h2 className="widgettitle collapsible">
                            Filter by Color<span></span>
                          </h2> */}
                                <div className="wcapf-layered-nav et-swatch-wcapf ps ps--theme_default">
                                  <ul className="color-filter">
                                    {color?.map((item, i) => {
                                      return (
                                        <li
                                          key={i}
                                          className={
                                            colorFilter.indexOf(item.id) > -1
                                              ? "active"
                                              : "not-active"
                                          }
                                        >
                                          <a
                                            href="#"
                                            data-key="attra-color"
                                            data-value="81"
                                            data-multiple-filter="1"
                                            className="et-color-swatch"
                                          >
                                            <span className="et-swatch-circle">
                                              <span
                                                onClick={() =>
                                                  getFilterData(
                                                    item.id,
                                                    "color",
                                                  )
                                                }
                                                style={{
                                                  backgroundColor: ` ${item.hex_code}`,
                                                }}
                                              ></span>
                                            </span>
                                            <span
                                              onClick={() =>
                                                removeFilter(item.id, "color")
                                              }
                                            >
                                              &times;
                                            </span>
                                          </a>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                  <div
                                    className="ps__scrollbar-x-rail"
                                    style={{ left: 0 + "px", bottom: 0 + "px" }}
                                  >
                                    <div
                                      className="ps__scrollbar-x"
                                      tabIndex="0"
                                      style={{
                                        left: 0 + "px",
                                        width: 0 + " px",
                                      }}
                                    ></div>
                                  </div>
                                  <div
                                    className="ps__scrollbar-y-rail"
                                    style={{ top: 0 + "px", right: 0 + "px" }}
                                  >
                                    <div
                                      className="ps__scrollbar-y"
                                      tabIndex="0"
                                      style={{
                                        top: 0 + "px",
                                        height: 0 + "px",
                                      }}
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item
                            eventKey="2"
                            id="wcapf-attribute-filter-2"
                            className="woocommerce wcapf-ajax-term-filter widget cf widget_wcapf-attribute-filter mb-2 pb-2"
                          >
                            <Accordion.Header className="widgettitle">
                              Filter by Size
                            </Accordion.Header>
                            <Accordion.Body>
                              <div
                                id="wcapf-attribute-filter-2"
                                className="woocommerce wcapf-ajax-term-filter widget cf widget_wcapf-attribute-filter mb-2 pb-2"
                              >
                                {/* <h2 className="widgettitle collapsible">
                            Filter by Size<span></span>
                          </h2> */}
                                <div className="wcapf-layered-nav et-button-wcapf ps ps--theme_default">
                                  <ul className="size-filter">
                                    {size?.map((item, i) => {
                                      return (
                                        <li
                                          key={i}
                                          className={
                                            sizeFilter.indexOf(item.id) > -1
                                              ? "active"
                                              : "not-active"
                                          }
                                        >
                                          <a
                                            href="#"
                                            data-key="attra-size"
                                            data-value="81"
                                            data-multiple-filter="1"
                                          >
                                            <span className="name">
                                              <span
                                                onClick={() =>
                                                  getFilterData(item.id, "size")
                                                }
                                              >
                                                {item.name}
                                              </span>
                                            </span>
                                            <span
                                              onClick={() =>
                                                removeFilter(item.id, "size")
                                              }
                                            >
                                              &times;
                                            </span>
                                          </a>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                  <div
                                    className="ps__scrollbar-x-rail"
                                    style={{ left: 0 + "px", bottom: 0 + "px" }}
                                  >
                                    <div
                                      className="ps__scrollbar-x"
                                      tabIndex="0"
                                      style={{
                                        left: 0 + "px",
                                        width: 0 + " px",
                                      }}
                                    ></div>
                                  </div>
                                  <div
                                    className="ps__scrollbar-y-rail"
                                    style={{ top: 0 + "px", right: 0 + "px" }}
                                  >
                                    <div
                                      className="ps__scrollbar-y"
                                      tabIndex="0"
                                      style={{
                                        top: 0 + "px",
                                        height: 0 + "px",
                                      }}
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item
                            eventKey="3"
                            id="wcapf-attribute-filter-3"
                            className="woocommerce wcapf-ajax-term-filter widget cf widget_wcapf-attribute-filter mb-2 pb-2"
                          >
                            <Accordion.Header className="widgettitle">
                              Filter by Collection
                            </Accordion.Header>
                            <Accordion.Body>
                              <div
                                id="wcapf-attribute-filter-3"
                                className="woocommerce wcapf-ajax-term-filter widget cf widget_wcapf-attribute-filter mb-2 pb-2"
                              >
                                {/* <h2 className="widgettitle collapsible">
                            Filter by Size<span></span>
                          </h2> */}
                                <div className="wcapf-layered-nav et-button-wcapf ps ps--theme_default">
                                  <ul className="collection-filter">
                                    {collection?.map((item, i) => {
                                      return (
                                        <li
                                          key={i}
                                          className={
                                            collectionFilter.indexOf(item.id) >
                                            -1
                                              ? "active"
                                              : "not-active"
                                          }
                                        >
                                          <a
                                            href="#"
                                            data-key="attra-color"
                                            data-value="81"
                                            data-multiple-filter="1"
                                          >
                                            <span className="name">
                                              <span
                                                onClick={() =>
                                                  getFilterData(
                                                    item.id,
                                                    "collection",
                                                  )
                                                }
                                              >
                                                {item.name}
                                              </span>
                                            </span>
                                            <span
                                              onClick={() =>
                                                removeFilter(
                                                  item.id,
                                                  "collection",
                                                )
                                              }
                                            >
                                              &times;
                                            </span>
                                          </a>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                  <div
                                    className="ps__scrollbar-x-rail"
                                    style={{ left: 0 + "px", bottom: 0 + "px" }}
                                  >
                                    <div
                                      className="ps__scrollbar-x"
                                      tabIndex="0"
                                      style={{
                                        left: 0 + "px",
                                        width: 0 + " px",
                                      }}
                                    ></div>
                                  </div>
                                  <div
                                    className="ps__scrollbar-y-rail"
                                    style={{ top: 0 + "px", right: 0 + "px" }}
                                  >
                                    <div
                                      className="ps__scrollbar-y"
                                      tabIndex="0"
                                      style={{
                                        top: 0 + "px",
                                        height: 0 + "px",
                                      }}
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item
                            eventKey="4"
                            id="wcapf-attribute-filter-3"
                            className="woocommerce wcapf-ajax-term-filter widget cf widget_wcapf-attribute-filter mb-2 pb-2"
                          >
                            <Accordion.Header className="widgettitle">
                              Filter by Brand
                            </Accordion.Header>
                            <Accordion.Body>
                              <div
                                id="wcapf-attribute-filter-4"
                                className="woocommerce wcapf-ajax-term-filter widget cf widget_wcapf-attribute-filter mb-2 pb-2"
                              >
                                {/* <h2 className="widgettitle collapsible">
                            Filter by Size<span></span>
                          </h2> */}
                                <div className="wcapf-layered-nav et-button-wcapf ps ps--theme_default">
                                  <ul className="collection-filter">
                                    {brand?.map((item, i) => {
                                      return (
                                        <li
                                          key={i}
                                          className={
                                            brandFilter.indexOf(item.id) > -1
                                              ? "active"
                                              : "not-active"
                                          }
                                        >
                                          <a
                                            href="#"
                                            data-key="attra-brand"
                                            data-value="81"
                                            data-multiple-filter="1"
                                          >
                                            <span className="name">
                                              <span
                                                onClick={() =>
                                                  getFilterData(
                                                    item.id,
                                                    "brand",
                                                  )
                                                }
                                              >
                                                {item.name}
                                              </span>
                                            </span>
                                            <span
                                              onClick={() =>
                                                removeFilter(item.id, "brand")
                                              }
                                            >
                                              &times;
                                            </span>
                                          </a>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                  <div
                                    className="ps__scrollbar-x-rail"
                                    style={{ left: 0 + "px", bottom: 0 + "px" }}
                                  >
                                    <div
                                      className="ps__scrollbar-x"
                                      tabIndex="0"
                                      style={{
                                        left: 0 + "px",
                                        width: 0 + " px",
                                      }}
                                    ></div>
                                  </div>
                                  <div
                                    className="ps__scrollbar-y-rail"
                                    style={{ top: 0 + "px", right: 0 + "px" }}
                                  >
                                    <div
                                      className="ps__scrollbar-y"
                                      tabIndex="0"
                                      style={{
                                        top: 0 + "px",
                                        height: 0 + "px",
                                      }}
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </ul>
                      <div
                        className="ps__scrollbar-x-rail"
                        style={{ left: 0 + "px", bottom: 0 + "px" }}
                      >
                        <div
                          className="ps__scrollbar-x"
                          tabIndex="0"
                          style={{ left: 0 + "px", width: 0 + " px" }}
                        ></div>
                      </div>
                      <div
                        className="ps__scrollbar-y-rail"
                        style={{ top: 0 + "px", right: 0 + "px" }}
                      >
                        <div
                          className="ps__scrollbar-y"
                          tabIndex="0"
                          style={{ top: 0 + "px", height: 0 + "px" }}
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="col main-content shop-products-col">
                    <div className="header d-flex justify-content-between align-items-center mb-4">
                      <p className="m-0">
                        <a href="/">Home</a> / <a href="/the-shop">Shop</a>
                      </p>
                      <div
                        className="filter align-items-center d-none d-xl-flex"
                        onClick={() => setOpenNav(true)}
                      >
                        <span className="d-flex align-items-center mr-4 pe-auto">
                          <img src={filter} alt="Filter" />
                          <p className="my-0 mx-2 pe-auto">Filter</p>
                        </span>
                        <Sort setFilter={(event) => setFilter(event)} />
                      </div>
                    </div>

                    {product?.length > 0 ? (
                      <>
                        <div className="row">
                          {product?.map((item, i) => {
                            return (
                              <div
                                className={
                                  !openNav
                                    ? "col-xl-3 col-lg-3 col-md-3"
                                    : "col-xl-4 col-lg-4 col-md-4"
                                }
                                key={i}
                              >
                                <div className="img-div">
                                  <img
                                    src={item.thumbnail_image || blankImage}
                                    id={"img" + i}
                                    alt={
                                      item.name +
                                      " | " +
                                      (item.SubCategory?.name || "") +
                                      " | Sugandh"
                                    }
                                    onClick={() => gotoProductInfo(item)}
                                  />
                                  <div
                                    className="d_none"
                                    onClick={() =>
                                      toggleModal(
                                        item?.id,
                                        item?.name,
                                        item?.SubCategory?.name,
                                      )
                                    }
                                  >
                                    <p>Quick View</p>
                                  </div>
                                </div>
                                <div className="options mt-2 d-flex justify-content-between">
                                  <p className="fs-9rem">{item?.name}</p>
                                  <p className="color-grey">
                                    ₹{" "}
                                    {filterPrice(item.ProductVariants) ??
                                      item?.ProductVariants[0]?.mrp}
                                  </p>
                                </div>
                              </div>
                            );
                          })}
                        </div>

                        <br />
                        <div
                          className="load-more"
                          hidden={
                            currentPage === totalPageSize ||
                            product?.length === 0
                          }
                        >
                          <button
                            className="background-transparent"
                            onClick={() => setCurrentPage((prev) => prev + 1)}
                          >
                            Load More
                          </button>
                        </div>
                      </>
                    ) : (
                      <div className="product-not-found">
                        {/* <img src={emptyProduct} /> */}
                        <h5 className="no-result">
                          Sorry, we couldn't find the product you're searching
                          for.
                        </h5>
                        <p>
                          Please check your spelling or browse our other items
                          for similar options.
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="bottom-filter d-flex d-xl-none">
                <button
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasBottom"
                  aria-controls="offcanvasBottom"
                >
                  Filter
                </button>
              </div>
              <div
                className="offcanvas offcanvas-bottom"
                tabIndex="-1"
                id="offcanvasBottom"
                aria-labelledby="offcanvasBottomLabel"
              >
                <div className="offcanvas-header">
                  <button
                    type="button"
                    className="clear-all-filter background-transparent btn-border-none"
                    onClick={() => clearAllFilter()}
                  >
                    {/* Clear All Filter <span>&times;</span> */}
                  </button>
                  <button
                    type="button"
                    className="btn-close text-reset"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  >
                    <BsXLg />
                  </button>
                  {/* <span onClick={() => setOpenNav(false)}>
                      <FaChevronLeft />
                    </span> */}
                </div>
                <div className="offcanvas-body small">
                  <div
                    type="button"
                    className="ButtonFilter"
                    onClick={() => clearAllFilter()}
                  >
                    Clear All Filter
                  </div>
                  <div>
                    <Accordion flush defaultActiveKey="">
                      <Accordion.Item
                        eventKey="0"
                        id="wcapf-category-filter-1"
                        className="woocommerce wcapf-ajax-term-filter widget cf widget_wcapf-category-filter "
                      >
                        <Accordion.Header
                          // className="shadow-none text-secondary border-0 footer-menu-title"
                          className="widgettitle"
                        >
                          Filter By Category
                        </Accordion.Header>
                        <Accordion.Body
                          // className="py-1"
                          className="woocommerce wcapf-ajax-term-filter widget cf widget_wcapf-category-filter "
                          id="wcapf-category-filter-1"
                        >
                          <div
                            id="wcapf-category-filter-1"
                            className="woocommerce wcapf-ajax-term-filter widget cf widget_wcapf-category-filter "
                          >
                            <div className="wcapf-layered-nav ps ps--theme_default">
                              <ul className="category-filter">
                                {subCategoryData?.map((item, i) => {
                                  return (
                                    <li
                                      key={i}
                                      className={
                                        subCategoryFilter.indexOf(item.id) > -1
                                          ? "active"
                                          : "not-active"
                                      }
                                    >
                                      <a
                                        href="#"
                                        data-key="product-cata"
                                        data-value="41"
                                        data-multiple-filter=""
                                      >
                                        <span
                                          className="name"
                                          onClick={() =>
                                            getFilterData(item.id, "category")
                                          }
                                        >
                                          {item.name}
                                        </span>
                                        <span
                                          className="clear-filter"
                                          onClick={() =>
                                            removeFilter(item.id, "category")
                                          }
                                        >
                                          &times;
                                        </span>
                                      </a>
                                      <ul className="children"></ul>
                                    </li>
                                  );
                                })}
                              </ul>
                              <div
                                className="ps__scrollbar-x-rail"
                                style={{ left: 0 + "px", bottom: 0 + "px" }}
                              >
                                <div
                                  className="ps__scrollbar-x"
                                  tabIndex="0"
                                  style={{ left: 0 + "px", width: 0 + "px" }}
                                ></div>
                              </div>
                              <div
                                className="ps__scrollbar-y-rail"
                                style={{
                                  top: 0 + "px",
                                  right: 0 + "px",
                                }}
                              >
                                <div
                                  className="ps__scrollbar-y"
                                  tabIndex="0"
                                  style={{ top: 0 + "px", height: 0 + "px" }}
                                ></div>
                              </div>
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header className="widgettitle">
                          Filter By Color
                        </Accordion.Header>
                        <Accordion.Body>
                          <div
                            id="wcapf-attribute-filter-1"
                            className="woocommerce wcapf-ajax-term-filter widget cf widget_wcapf-attribute-filter mb-2 pb-2"
                          >
                            <div className="wcapf-layered-nav et-swatch-wcapf ps ps--theme_default">
                              <ul className="color-filter">
                                {color?.map((item, i) => {
                                  return (
                                    <li
                                      key={i}
                                      className={
                                        colorFilter.indexOf(item.id) > -1
                                          ? "active"
                                          : "not-active"
                                      }
                                    >
                                      <a
                                        href="#"
                                        data-key="attra-color"
                                        data-value="81"
                                        data-multiple-filter="1"
                                        className="et-color-swatch"
                                      >
                                        <span
                                          onClick={() =>
                                            getFilterData(item.id, "color")
                                          }
                                          className="et-swatch-circle"
                                        >
                                          <span
                                            style={{
                                              backgroundColor: ` ${item.hex_code}`,
                                            }}
                                          ></span>
                                        </span>
                                        <span
                                          className="clear-filter"
                                          onClick={() =>
                                            removeFilter(item.id, "color")
                                          }
                                        >
                                          &times;
                                        </span>
                                      </a>
                                    </li>
                                  );
                                })}
                              </ul>
                              <div
                                className="ps__scrollbar-x-rail"
                                style={{ left: 0 + "px", bottom: 0 + "px" }}
                              >
                                <div
                                  className="ps__scrollbar-x"
                                  tabIndex="0"
                                  style={{ left: 0 + "px", width: 0 + " px" }}
                                ></div>
                              </div>
                              <div
                                className="ps__scrollbar-y-rail"
                                style={{ top: 0 + "px", right: 0 + "px" }}
                              >
                                <div
                                  className="ps__scrollbar-y"
                                  tabIndex="0"
                                  style={{ top: 0 + "px", height: 0 + "px" }}
                                ></div>
                              </div>
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="2">
                        <Accordion.Header className="widgettitle">
                          Filter by Size
                        </Accordion.Header>
                        <Accordion.Body>
                          <div
                            id="wcapf-attribute-filter-2"
                            className="woocommerce wcapf-ajax-term-filter widget cf widget_wcapf-attribute-filter mb-2 pb-2"
                          >
                            <div className="wcapf-layered-nav et-button-wcapf ps ps--theme_default">
                              <ul className="size-filter">
                                {size?.map((item, i) => {
                                  return (
                                    <li
                                      key={i}
                                      className={
                                        sizeFilter.indexOf(item.id) > -1
                                          ? "active"
                                          : "not-active"
                                      }
                                    >
                                      <a
                                        href="#"
                                        data-key="attra-size"
                                        data-value="81"
                                        data-multiple-filter="1"
                                      >
                                        <span className="name">
                                          <span
                                            onClick={() =>
                                              getFilterData(item.id, "size")
                                            }
                                          >
                                            {item.name}
                                          </span>
                                        </span>
                                        <span
                                          onClick={() =>
                                            removeFilter(item.id, "size")
                                          }
                                        >
                                          &times;
                                        </span>
                                      </a>
                                    </li>
                                  );
                                })}
                              </ul>
                              <div
                                className="ps__scrollbar-x-rail"
                                style={{ left: 0 + "px", bottom: 0 + "px" }}
                              >
                                <div
                                  className="ps__scrollbar-x"
                                  tabIndex="0"
                                  style={{ left: 0 + "px", width: 0 + " px" }}
                                ></div>
                              </div>
                              <div
                                className="ps__scrollbar-y-rail"
                                style={{ top: 0 + "px", right: 0 + "px" }}
                              >
                                <div
                                  className="ps__scrollbar-y"
                                  tabIndex="0"
                                  style={{ top: 0 + "px", height: 0 + "px" }}
                                ></div>
                              </div>
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="3">
                        <Accordion.Header className="widgettitle">
                          Filter by Collection
                        </Accordion.Header>
                        <Accordion.Body>
                          <div
                            id="wcapf-category-filter-4"
                            className="woocommerce wcapf-ajax-term-filter widget cf widget_wcapf-category-filter mb-2 pb-2"
                          >
                            <div className="wcapf-layered-nav ps ps--theme_default">
                              <ul className="category-filter">
                                {collection?.map((item, i) => {
                                  return (
                                    <li
                                      key={i}
                                      className={
                                        collectionFilter.indexOf(item.id) > -1
                                          ? "active"
                                          : "not-active"
                                      }
                                    >
                                      <a
                                        href="#"
                                        data-key="product-cata"
                                        data-value="41"
                                        data-multiple-filter=""
                                      >
                                        <span
                                          className="name"
                                          onClick={() =>
                                            getFilterData(item.id, "collection")
                                          }
                                        >
                                          {item.name}
                                        </span>
                                        <span
                                          className="clear-filter"
                                          onClick={() =>
                                            removeFilter(item.id, "collection")
                                          }
                                        >
                                          &times;
                                        </span>
                                      </a>
                                      <ul className="children"></ul>
                                    </li>
                                  );
                                })}
                              </ul>
                              <div
                                className="ps__scrollbar-x-rail"
                                style={{ left: 0 + "px", bottom: 0 + "px" }}
                              >
                                <div
                                  className="ps__scrollbar-x"
                                  tabIndex="0"
                                  style={{ left: 0 + "px", width: 0 + "px" }}
                                ></div>
                              </div>
                              <div
                                className="ps__scrollbar-y-rail"
                                style={{
                                  top: 0 + "px",
                                  right: 0 + "px",
                                }}
                              >
                                <div
                                  className="ps__scrollbar-y"
                                  tabIndex="0"
                                  style={{ top: 0 + "px", height: 0 + "px" }}
                                ></div>
                              </div>
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="4">
                        <Accordion.Header className="widgettitle">
                          Filter by Brand
                        </Accordion.Header>
                        <Accordion.Body>
                          <div
                            id="wcapf-category-filter-5"
                            className="woocommerce wcapf-ajax-term-filter widget cf widget_wcapf-category-filter mb-2 pb-2"
                          >
                            <div className="wcapf-layered-nav ps ps--theme_default">
                              <ul className="size-filter">
                                {brand?.map((item, i) => {
                                  return (
                                    <li
                                      key={i}
                                      className={
                                        brandFilter.indexOf(item.id) > -1
                                          ? "active"
                                          : "not-active"
                                      }
                                    >
                                      <a
                                        className="p-1"
                                        href="#"
                                        data-key="product-cata"
                                        data-value="41"
                                        data-multiple-filter=""
                                      >
                                        <span
                                          className="name"
                                          onClick={() =>
                                            getFilterData(item.id, "brand")
                                          }
                                        >
                                          {item.name}
                                        </span>
                                        <span
                                          className="clear-filter"
                                          onClick={() =>
                                            removeFilter(item.id, "brand")
                                          }
                                        >
                                          &times;
                                        </span>
                                      </a>
                                      <ul className="children"></ul>
                                    </li>
                                  );
                                })}
                              </ul>
                              <div
                                className="ps__scrollbar-x-rail"
                                style={{ left: 0 + "px", bottom: 0 + "px" }}
                              >
                                <div
                                  className="ps__scrollbar-x"
                                  tabIndex="0"
                                  style={{ left: 0 + "px", width: 0 + "px" }}
                                ></div>
                              </div>
                              <div
                                className="ps__scrollbar-y-rail"
                                style={{
                                  top: 0 + "px",
                                  right: 0 + "px",
                                }}
                              >
                                <div
                                  className="ps__scrollbar-y"
                                  tabIndex="0"
                                  style={{ top: 0 + "px", height: 0 + "px" }}
                                ></div>
                              </div>
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                    {/* <div
                        className="load-more"
                        hidden={
                          currentPage === totalPageSize || product?.length === 0
                        }
                      >
                        <button
                          onClick={() => setCurrentPage((prev) => prev + 1)}
                        >
                          Load More
                        </button>
                      </div> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="App">
              <Modal
                isOpen={isOpen}
                onRequestClose={toggleModal}
                contentLabel="My dialog"
                className="mymodal"
                overlayClassName="myoverlay"
                closeTimeoutMS={500}
                shouldCloseOnOverlayClick={false}
              >
                <button
                  onClick={toggleModal}
                  style={{ float: "right", paddingRight: 10 + "px" }}
                >
                  <FaTimes />
                </button>
                <ProductInfo
                  id={productId}
                  isModal={true}
                  productName={productName}
                  // productCategory={productCategory}
                />
              </Modal>
            </div>
          </div>
        </section>
      ) : (
        <div className="spinner-container">
          <BeatLoader
            color="#242323"
            loading={loading}
            // cssOverride={override}
            // size={50}
            aria-label="Loading Spinner"
            data-testid="loader"
            className="loader"
          />
        </div>
      )}
    </>
  );
};

export default TheShop;
