import React, { useEffect, useState } from "react";
import "../css/paymentSuccess.css";
import { TiTick } from "react-icons/ti";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";

const PaymentSuccess = (props) => {
  useEffect(() => {
    const razorpayData = JSON.parse(localStorage.getItem("onComplete"));
  }, []);

  return (
    <>
      <div>
        <div className="Paymentsuccess">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-7">
                <div className="success">
                  <IoIosCheckmarkCircleOutline />
                  <p>Thank You</p>
                  <h3>
                    Your Payment Is Successful And Your Order Is Confirmed
                  </h3>
                  <p>
                    A Confirmation Mail/Message Has Been Sent To Your Registered
                    E-Mail/Phone Number
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentSuccess;
