import React, { useEffect, useState } from "react";
import CollectionService from "../../api-config/collection";
// import { Swiper, SwiperSlide } from "swiper/react";
// import "swiper/css";
// import {Navigation,  Scrollbar,  A11y,  Autoplay,  Pagination,  EffectFade} from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css";
import {
  Navigation,
  Scrollbar,
  A11y,
  Autoplay,
  Pagination,
  EffectFade,
} from "swiper/modules";

import "../Slider/Slider.css";
import CmsService from "../../api-config/cms";
import HomeService from "../../api-config/home";
import blankImage from "../../assets/images/about-team.png";
import { useNavigate, useLocation } from "react-router-dom";
const Slider = () => {
  const [collectionData, setCollectionData] = useState();
  const [sliderData, setSliderData] = useState();
  const [desktopList, setDesktopList] = useState([]);
  const [mobileList, setMobileList] = useState([]);
  const location = useLocation();
  const { id, additionalData } = location.state || {};

  useEffect(() => {
    HomeService.getDesktopSlider()
      .then((res) => {
        if (res.data.success) {
          setDesktopList(res.data.data);

          console.log(res.data.data);
        }
      })
      .catch((e) => console.log(e));

    HomeService.getMobileSlider()
      .then((res) => {
        if (res.data.success) {
          setMobileList(res.data.data);
          console.log(res.data.data);
        }
      })
      .catch((e) => console.log(e));
  }, []);

  useEffect(() => {
    CmsService.getAll().then((res) => {
      setCollectionData(res.data.data);
    });

    CmsService.getAll().then((res) => {
      setSliderData(res.data.data);
    });
  }, []);

  const navigate = useNavigate();

  const gotoProductInfo = (Collection) => {
    console.log(Collection);
    navigate(`/the-shop/collections/${Collection}`, {
      state: { id: Collection },
    });
  };

  return (
    <>
      <div className="herosection-banner">
        <>
          <Swiper
            spaceBetween={10}
            pagination={{
              clickable: true,
            }}
            className="mySwiper d-none d-lg-block"
            modules={[
              Navigation,
              Pagination,
              Scrollbar,
              A11y,
              Autoplay,
              EffectFade,
            ]}
            slidesPerView={1}
            scrollbar={{ draggable: true }}
            loop={true}
            autoplay={{ delay: 5000 }}
          >
            {desktopList?.map((item, i) => {
              return (
                <SwiperSlide key={i}>
                  <img
                    src={item.image || blankImage}
                    alt={item.title}
                    className="img-fluid"
                    onClick={() => gotoProductInfo(item?.Collection.name)}
                  />
                  <div className="bannercontent-btn">
                    <div className="container">
                      <button
                        type="button"
                        className="btn-shop"
                        onClick={() => gotoProductInfo(item?.Collection.name)}
                      >
                        Shop Now
                      </button>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>

          <Swiper
            spaceBetween={10}
            pagination={{
              clickable: true,
            }}
            className="mySwiper d-block d-lg-none"
            modules={[
              Navigation,
              Pagination,
              Scrollbar,
              A11y,
              Autoplay,
              EffectFade,
            ]}
            slidesPerView={1}
            scrollbar={{ draggable: true }}
            loop={true}
            autoplay={{ delay: 5000 }}
          >
            {mobileList?.map((item, i) => {
              return (
                <SwiperSlide key={i}>
                  <img
                    src={item.image || blankImage}
                    alt={item.title}
                    className="img-fluid"
                    onClick={() => gotoProductInfo(item?.Collection.name)}
                  />
                  <div className="bannercontent-btn">
                    <div className="container">
                      <div className="slider-title">
                        <h1>{item.title}</h1>
                      </div>
                      <button
                        type="button"
                        className="btn-shop"
                        onClick={() => gotoProductInfo(item?.Collection.name)}
                      >
                        Shop Now
                      </button>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </>
      </div>
      {/* 
      <div className="main-banner">
        <div className="slider main-slider">
          <Swiper
            spaceBetween={30}
            pagination={{
              clickable: true,
            }}
            className="d-none d-xl-block"
            modules={[
              Navigation,
              Pagination,
              Scrollbar,
              A11y,
              Autoplay,
              EffectFade,
            ]}
            slidesPerView={1}
            scrollbar={{ draggable: true }}
            loop={true}
            autoplay={{ delay: 5000 }}
            onSwiper={(swiper) => console.log(swiper)}
          >
            {desktopList?.map((item, i) => {
              return (
                <SwiperSlide key={i}>
                  <img
                    src={item.image || blankImage}
                    alt="pic"
                    className="img-fluid"
                    onClick={() => gotoProductInfo(item?.Collection.name)}
                  />
                  <div className="bannercontent-1">
                    <div className="container">
                      <button
                        type="button"
                        className="btn"
                        onClick={() => gotoProductInfo(item?.Collection.name)}
                      >
                        Shop Now
                      </button>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>

           <Swiper
            spaceBetween={30}
            pagination={{
              clickable: true,
            }}
            className="d-block d-xl-none"
            modules={[
              Navigation,
              Pagination,
              Scrollbar,
              A11y,
              Autoplay,
              EffectFade,
            ]}
            slidesPerView={1}
            loop={true}
            // navigation
            autoplay
          >


            {mobileList?.map((item, i) => {
              return (
                <SwiperSlide>
                  <img
                    src={item.image || blankImage}
                    alt="pic"
                    className="img-fluid"
                    onClick={() => gotoProductInfo(item?.Collection.name)}
                  />
                  <div className="bannercontent">
                    <div className="container">
                      <div className="slider-text">
                        <h1>{item.title}</h1>
                      </div>
                      <button
                        type="button"
                        className="btn"
                        onClick={() => gotoProductInfo(item?.Collection.name)}
                      >
                        Shop Now
                      </button>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}




            {/* <SwiperSlide>
          <img
            src={mobile_slider_1}
            alt="pic"
            className="img-fluid"
            onClick={() => navigate(`/the-shop/collections/${"Ramo 2023"}`)}
          />
          <div className="bannercontent">
            <div className="container">
              <div className="slider-text">
                <h1>Ramo 2023</h1>
              </div>
              <button
                type="button"
                className="btn"
                onClick={() => navigate(`/the-shop/collections/${"Ramo 2023"}`)}
              >
                Shop Now
              </button>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <img
            src={mobile_slider_2}
            alt="pic"
            className="img-fluid"
            onClick={() => navigate(`/the-shop/collections/${"Masakali"}`)}
          />
          <div className="bannercontent">
            <div className="container">
              <div className="slider-text">
                <h1>Masakali</h1>
              </div>
              <button
                type="button"
                className="btn"
                onClick={() => navigate(`/the-shop/collections/${"Masakali"}`)}
              >
                Shop Now
              </button>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <img
            src={mobile_slider_3}
            alt="pic"
            className="img-fluid"
            onClick={() => navigate(`/the-shop/collections/${"Khayal"}`)}
          />
          <div className="bannercontent">
            <div className="container">
              <div className="slider-text">
                <h1>Khayal</h1>
              </div>
              <button
                type="button"
                className="btn"
                onClick={() => navigate(`/the-shop/collections/${"Khayal"}`)}
              >
                Shop Now
              </button>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <img
            src={mobile_slider_4}
            alt="pic"
            className="img-fluid"
            onClick={() => navigate(`/the-shop/collections/${"am:pm"}`)}
          />
          <div className="bannercontent">
            <div className="container">
              <div className="slider-text">
                <h1>am:pm</h1>
              </div>
              <button
                type="button"
                className="btn"
                onClick={() => navigate(`/the-shop/collections/${"am:pm"}`)}
              >
                Shop Now
              </button>
            </div>
          </div>
        </SwiperSlide> 
          </Swiper>
        </div>
      </div>
       */}
    </>
  );
};

export default Slider;
