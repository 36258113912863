import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Formik, useFormik } from "formik";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import Login from "../../containers/LoginContainer";
import CartService from "../../api-config/cart";
import UtilsService from "../../api-config/utils";
import "./checkout.css";
import logo from "../../assets/images/logo.png";
import { NavLink } from "react-router-dom";

const Checkout = () => {
  const token = localStorage.getItem("auth_key");
  const userData = JSON.parse(localStorage.getItem("user_info"));
  const navigate = useNavigate();
  const [isModalOpen, setModal] = useState(false);
  const [cartData, setCartData] = useState();
  const [productDetails, setProductDetails] = useState();
  const [totalAmount, setAmount] = useState();
  const [mainAmount, setMainAmount] = useState();
  const [country, setCountry] = useState();
  const [province, setProvince] = useState();
  const [city, setCity] = useState();
  const [zipcode, setZipcode] = useState();
  const [btnDisable, setDisable] = useState(false);
  const fulldate = new Date();
  const date = fulldate.getDate();
  const month = fulldate.getMonth() + 1;
  const year = fulldate.getFullYear();
  const initialValues = {
    first_name: "sonam",
  };
  const [isbtnDisable, setBtnDisable] = useState(true);
  const [zipcodeValidateErr, setErrorMsg] = useState(false);

  useEffect(() => {
    if (!token || !userData) {
      navigate("/login");
    }
  }, [token, userData]);

  useEffect(() => {
    CartService.getByUserId(userData?.id)
      .then((res) => {
        let sum = 0;
        let mainSum = 0;
        setCartData(res.data.data);
        setProductDetails(res.data.data.items);
        res.data.data.items.forEach((e) => {
          const price = e.main_mrp || e.mrp
          const price1 =  e.mrp || e.main_mrp
          sum += price1 * e.quantity;
          mainSum += price * e.quantity;
          // sum = e?.coupon == "NEW10" ? sum * 0.9 : sum
        });
        setAmount(sum);
        setMainAmount(mainSum);
      })
      .catch((e) => console.log(e));

    getCountry();

    token ? setModal(false) : setModal(true);
  }, [btnDisable]);

  const getCountry = () => {
    UtilsService.getCountry()
      .then((res) => {
        setCountry(res.data.data);
      })
      .catch((e) => console.log(e));
  };

  const getProvince = (countryId) => {
    UtilsService.getProvince(countryId)
      .then((res) => {
        setProvince(res.data.data);
      })
      .catch((e) => console.log(e));
  };

  const getCity = (provinceId) => {
    UtilsService.getCity(provinceId)
      .then((res) => {
        setCity(res.data.data);
      })
      .catch((e) => console.log(e));
  };

  const getZipcode = (cityId) => {
    UtilsService.getZipcode(cityId)
      .then((res) => {
        setZipcode(res.data.data);
      })
      .catch((e) => console.log(e));
  };

  const billingAddress = {
    country_id: 0,
    zipcode_id: 0,
    city_id: 0,
    province_id: 0,
  };

  const shippingAddress = {
    country_id: 0,
    zipcode_id: 0,
    city_id: 0,
    province_id: 0,
  };

  const formik = useFormik({
    initialValues: {
      first_name: userData?.first_name,
      last_name: userData?.last_name,
      address_line_1: userData?.address_line_1,
      address_line_2: userData?.address_line_2,
      country: userData?.country,
      country_id: userData?.country_id,
      province: userData?.province,
      province_id: userData?.province_id,
      city: userData?.city,
      city_id: userData?.city_id,
      zipcode: userData?.zipcode,
      zipcode_id: userData?.zipcode_id,
      primary_contact_number: userData?.primary_contact_number,
      email_id: userData?.email_id,
      note: "",
      is_shipping_address: false,
      is_online_payment: false,
      // shipping details
      s_first_name: "", // first name for shipping
      s_last_name: "",
      s_address_line_1: "",
      s_address_line_2: "",
      s_country: "",
      s_country_id: 0,
      s_province: "",
      s_province_id: 0,
      s_city: "",
      s_city_id: 0,
      s_zone: "",
      s_zipcode: "",
      s_zipcode_id: 0,
      s_primary_contact_number: "",
      s_email_id: "",
    },
    enableReinitialize: true,
    validateOnChange: false,
    onSubmit: (values) => {
      JSON.parse(values.is_online_payment)
        ? checkoutHandler(values)
        : submit(values);
    },
  });

  const customStyles = {
    content: {
      position: "initial",
      right: "auto",
      bottom: "auto",
      background: "rgba(0,0,0,0.5)",
    },
  };

  const submit = async (value) => {

    const requestData = {
      is_transporter: false,
      is_website_order: true,
      is_online_payment: JSON.parse(value.is_online_payment),
      user_id: userData?.id,
      supplier_id: 1,
      date: month + "-" + date + "-" + year,
      order_status_id: 3,
      note: value.note,
      order_type: true,
      payment_status: true,
      is_shipping_address: value.is_shipping_address,
      billing_address: {
        first_name: value.first_name,
        last_name: value.last_name,
        contact_number: value.primary_contact_number,
        address_line_1: value.address_line_1,
        address_line_2: value.address_line_2,
        zipcode: value.zipcode,
        country: value.country,
        province: value.province,
        city: value.city,
        zipcode_id: value?.zipcode_id,
        country_id: value?.country_id,
        province_id: value?.province_id,
        city_id: value?.city_id,
      },
      invoice_details: productDetails,
    };
    if (requestData.is_shipping_address) {
      const shippingDetails = {
        first_name: value.s_first_name,
        last_name: value.s_last_name,
        contact_number: value.s_primary_contact_number,
        address_line_1: value.s_address_line_1,
        address_line_2: value.s_address_line_2,
        zipcode: value.s_zipcode,
        country: value.s_country,
        province: value.s_province,
        city: value.s_city,
        zipcode_id: value?.s_zipcode_id,
        country_id: value?.s_country_id,
        province_id: value?.s_province_id,
        city_id: value?.s_city_id,
      };

      Object.assign(requestData, { shipping_address: shippingDetails });
    }

    axios
      .post("https://api.sugandh.co/public/api/invoice/save", requestData) //"https://api.sugandh.co/public/api/invoice/save"
      .then((res) => {
        if (res.data.success) {
        }
        deleteCartData();
      })
      .catch((e) => console.log(e));

    setTimeout(() => {
      navigate("/order");
    }, 1000);
  };

  // Razor Pay from backend code
  const checkoutHandler = async (value) => {
    const requestData = {
      user_id: userData?.id,
      is_payment_online: true,
      payment_status: true,
      is_shipping_is_billing: !value.is_shipping_address,
      note: value.note,
      billing_address: {
        first_name: value.first_name,
        last_name: value.last_name,
        email_id: value.email_id,
        contact_number: value.primary_contact_number,
        contact_number_id: 1,
        address_line_1: value.address_line_1,
        address_line_2: value.address_line_2,
        zipcode: value.zipcode,
        country_name: value.country,
        province_name: value.province,
        city_name: value.city,
        city_id: value?.city_id,
        province_id: value?.province_id,
        country_id: value?.country_id,
      },
      order_items: productDetails,
      invoice_details: productDetails,
    };
    if (value.is_shipping_address) {
      const shippingDetails = {
        first_name: value.s_first_name,
        last_name: value.s_last_name,
        email_id: value.s_email_id,
        contact_number: value.s_primary_contact_number,
        contact_number_id: 1,
        address_line_1: value.s_address_line_1,
        address_line_2: value.s_address_line_2,
        city_id: value?.s_city_id,
        city_name: value.s_city,
        province_id: value?.s_province_id,
        province_name: value.s_province,
        country_id: value?.s_country_id,
        country_name: value.s_country,
        zipcode: value.s_zipcode,
      };

      Object.assign(requestData, { shipping_address: shippingDetails });
    } else {
      const shippingDetails = {
        first_name: value.first_name,
        last_name: value.last_name,
        email_id: value.email_id,
        contact_number: value.primary_contact_number,
        contact_number_id: 1,
        address_line_1: value.address_line_1,
        address_line_2: value.address_line_2,
        zipcode: value.zipcode,
        country_name: value.country,
        province_name: value.province,
        city_name: value.city,
        city_id: value?.city_id,
        province_id: value?.province_id,
        country_id: value?.country_id,
      };

      Object.assign(requestData, { shipping_address: shippingDetails });
    }
    axios
      .post(
        "https://api.sugandh.co/public/api/order/save/razorpay/v2",
        requestData,
      )
      .then((res) => {
        console.log("res >>>> ", res);

        if (res.data.success) {
          deleteCartData();
          window.location.replace(res.data?.data?.short_url);

          // axios.post("http://localhost:5021/public/api/invoice/save", requestData)
          //   .then((res) => {
          //     if (res.data.success) {
          //     }
          //     deleteCartData();
          //     setTimeout(() => {
          //       navigate('/order');
          //     }, 1000)
          //   })
          //   .catch((e) => console.log(e));
        }
      })
      .catch((e) => console.log(e));
  };

  // Razor Pay from front end code

  // const checkoutHandler = async (value) => {
  //   let key = "rzp_live_SxsrnKzPtdxYr5";
  //   let secret_key= "GvCsz7P9zP0JalX191QXL4US";
  //   const data = await axios.post(
  //     "https://api.sugandh.co/public/api/payment/order/create",
  //     {
  //       amount: totalAmount,
  //       currency: "INR",
  //     }
  //   );
  //   let order = data.data.data;
  //   const options = {
  //     key: key,
  //     secret_key: secret_key,
  //     amount: 100000,
  //     currency: "INR",
  //     name: "Just a step ahead",
  //     image: logo,
  //     order_id: order.id,
  //     callback_url: "https://api.sugandh.co/public/api/payment/verification",
  //     handler: function (response) {
  //       var values = {
  //         is_transporter: false,
  //         is_website_order: true,
  //         is_online_payment: true,
  //         user_id: userData?.id,
  //         supplier_id: 1,
  //         date: month + "-" + date + "-" + year,
  //         order_status_id: 8,
  //         order_type: true,
  //         payment_details: {
  //           razorpay_signature: response.razorpay_signature,
  //           razorpay_order_id: response.razorpay_order_id,
  //           razorpay_payment_id: response.razorpay_payment_id,
  //         },
  //         is_shipping_address: value.is_shipping_address,
  //         billing_address: {
  //           first_name: value.first_name,
  //           last_name: value.last_name,
  //           contact_number: value.primary_contact_number,
  //           address_line_1: value.address_line_1,
  //           address_line_2: value.address_line_2,
  //           zipcode: value.zipcode,
  //           country: value.country,
  //           province: value.province,
  //           city: value.city,
  //         },
  //         invoice_details: productDetails,
  //       };
  //       if (value.is_shipping_address) {
  //         const shippingDetails = {
  //           first_name: value.s_first_name,
  //           last_name: value.s_last_name,
  //           contact_number: value.s_primary_contact_number,
  //           address_line_1: value.s_address_line_1,
  //           address_line_2: value.s_address_line_2,
  //           zipcode: value.s_zipcode,
  //           country: value.s_country,
  //           province: value.s_province,
  //           city: value.s_city,
  //         };

  //         Object.assign(values, { shipping_address: shippingDetails });
  //       }
  //       axios
  //         .post("https://api.sugandh.co/public/api/invoice/save", values)
  //         .then((res) => {
  //           if (res.data.success) {
  //           }
  //           deleteCartData();
  //           setTimeout(() => {
  //             navigate('/order');
  //           }, 1000)
  //         })
  //         .catch((e) => console.log(e));
  //     },
  //     prefill: {
  //       name: userData?.first_name,
  //       email: userData?.email_id,
  //       contact: userData?.primary_contact_number,
  //     },
  //     notes: {
  //       address: "Razorpay Corporate Office",
  //     },
  //     theme: {
  //       color: "#121212",
  //     },
  //   };
  //   try {
  //     const razor = new window.Razorpay(options);
  //     razor.open();
  //   } catch {
  //     console.log("hello error");
  //   }
  // };

  const deleteCartData = () => {
    const cart_data = {
      id: cartData._id,
      items: [],
      deleted_items: productDetails,
      user_id: cartData.user_id,
    };
    CartService.addToCart(cart_data).then((res) => {
      console.log(res);
    });
  };

  const handleOnSelect = (item, name) => {
    if (name === "country") {
      formik.setFieldValue("country", item.name);
      formik.setFieldValue("country_id", item?.id);
      billingAddress.country_id = item.id;
      getProvince(item.id);
    } else if (name === "province") {
      formik.setFieldValue("province", item.name);
      formik.setFieldValue("province_id", item?.id);
      billingAddress.province_id = item.id;
      getCity(item.id);
    } else if (name === "city") {
      formik.setFieldValue("city", item.name);
      formik.setFieldValue("city_id", item?.id);
      billingAddress.city_id = item.id;
      getZipcode(item.id);
    } else if (name === "zipcode") {
      formik.setFieldValue("zipcode", item.name);
      formik.setFieldValue("zipcode_id", item?.id);
      billingAddress.zipcode_id = item.id;
    } else if (name === "s_country") {
      formik.setFieldValue("s_country", item.name);
      formik.setFieldValue("s_country_id", item.id);
      shippingAddress.country_id = item.id;
    } else if (name === "s_province") {
      formik.setFieldValue("s_province", item.name);
      formik.setFieldValue("s_province_id", item?.id);
      shippingAddress.province_id = item.id;
      getCity(item.id);
    } else if (name === "s_city") {
      formik.setFieldValue("s_city", item.name);
      formik.setFieldValue("s_city_id", item?.id);
      shippingAddress.city_id = item.id;
      getZipcode(item.id);
    } else if (name === "s_zipcode") {
      formik.setFieldValue("s_zipcode", item.name);
      formik.setFieldValue("s_zipcode_id", item?.id);
      shippingAddress.zipcode_id = item.id;
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (
        formik.values.country_id &&
        formik.values.province_id &&
        formik.values.city_id
      ) {
        console.log("all fieldds fill");
        setBtnDisable(false);
      } else {
        setBtnDisable(true);
      }
    }, 3000);
  }, [formik]);

  const setPyamentOption = (event) => {
    setDisable(event.target.value);
  };

  const handleOnClear = (data, name) => {
    if (name === "country") {
      formik.setFieldValue("country_id", null);
      formik.setFieldValue("country", null);
    } else if (name === "province") {
      formik.setFieldValue("province_id", null);
      formik.setFieldValue("province", null);
    } else if (name === "city") {
      formik.setFieldValue("city_id", null);
      formik.setFieldValue("city", null);
    }
    console.log(data);
  };

  const validateZipcode = (event) => {
    console.log(event.target.value);
    formik.setFieldValue("zipcode", event.target.value);
    UtilsService.validateZipcode(event.target.value).then((res) => {
      if (res.data.message === "Please enter valid Zipcode") {
        formik.setFieldValue("zipcode", null);
        setErrorMsg(true);
      } else {
        setErrorMsg(false);
      }
      console.log(res);
    });
  };

  return (
    <>
      <div className="checkout">
        <div className="container my-5">
          {/* <div className="form-distr-free-bg">
            <div className="col-lg-7">
              <div></div>
            </div>
            <div className="col right-bg">
              <div></div>
            </div>
          </div> */}
          <div className="row g-lg-4 gy-5">
            <Formik initialValues={initialValues}>
              <form className="row g-4" onSubmit={formik?.handleSubmit}>
                <div className="col-lg-7 col-md-12 col-sm-12 position-relative left-side">
                  <div className="before-checkout">
                    {/* <div className="checkout-login">
                      <div className="collapse" id="checkout-login-form">
                        <div className="form-login">
                          <p>
                            If you have shopped with us before, please enter
                            your details below. If you are a new customer,
                            please proceed to the Billing section.
                          </p>
                          <p>
                            <div className="form-floating">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Username or email *"
                              />
                              <label>Username or email *</label>
                            </div>
                          </p>
                          <p>
                            <div className="form-floating">
                              <input
                                type="password"
                                className="form-control"
                                placeholder="Password *"
                              />
                              <label>Password *</label>
                            </div>
                          </p>
                          <p>
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value=""
                                  id="flexCheckDefault"
                                />
                                <label
                                  className="form-check-label"
                                  for="flexCheckDefault"
                                >
                                  Remember me
                                </label>
                              </div>
                              <div className="back-to">
                                <a className="button outlined">
                                  Lost your password?
                                </a>
                              </div>
                            </div>
                          </p>
                          <p>
                            <button
                              type="button"
                              className="button w-100 bg-dark"
                            >
                              Login
                            </button>
                           
                          </p>
                        </div>
                      </div>
                    </div> */}
                    {/* <!-- checkout-login --> */}

                    {/* <!-- coupon --> */}
                    {/* <div className="checkout-coupon">
                      <div className="checkout-inner d-flex align-items-center">
                        <i className="bi bi-tag"></i>
                        Have a coupon?
                        <div className="back-to ms-2">
                          <a
                            className="button outlined"
                            data-bs-toggle="collapse"
                            href="#checkout-coupon-form"
                            role="button"
                            aria-expanded="false"
                            aria-controls="checkout-coupon-form"
                          >
                            Click here to enter your code
                            <i className="bi bi-chevron-down"></i>
                          </a>
                        </div>
                      </div>
                      <div className="collapse" id="checkout-coupon-form">
                        <div className="form-coupon">
                          <p>
                            If you have a coupon code, please apply it below.
                          </p>
                          <div className="inner-coupon">
                            <div className="form-floating me-2">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Coupon code"
                              />
                              <label>Coupon code</label>
                            </div>
                            <button type="button" className="button bg-dark">
                              Apply coupon
                            </button>
                          </div>
                        </div>
                      </div>
                    </div> */}
                    {/* <!-- coupon --> */}
                  </div>

                  {/* <!-- billing details start --> */}

                  <div className="billing-details-header mb-4">
                    <div className="tab-pannel-inner m-0 d-flex align-items-center">
                      <h2>Billing details</h2>
                      <span className="title-after"></span>
                    </div>
                    <div className="back-to back-to-shop">
                      <a
                        className="button outlined"
                        href="/cart"
                        title="Back to Shop"
                      >
                        <i className="bi bi-chevron-left"></i>
                        Back to Cart
                      </a>
                    </div>
                  </div>

                  <div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-floating">
                          <input
                            type="text"
                            className="form-control"
                            name="first_name"
                            placeholder="First name *"
                            value={formik?.values?.first_name}
                            onChange={formik?.handleChange}
                          />
                          <label>First name *</label>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-floating">
                          <input
                            type="text"
                            className="form-control"
                            name="last_name"
                            placeholder="Last name *"
                            value={formik?.values?.last_name}
                            onChange={formik?.handleChange}
                          />
                          <label>Last name *</label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          name="address_line_1"
                          placeholder="Street address *"
                          value={formik?.values?.address_line_1}
                          onChange={formik?.handleChange}
                        />
                        <label>Street address *</label>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          name="address_line_2"
                          placeholder="Apartment, suite, unit, etc."
                          value={formik?.values?.address_line_2}
                          onChange={formik?.handleChange}
                        />
                        <label>Apartment, suite, unit, etc.</label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-floating validate-input">
                          {/* <input
                            type="text"
                            className="form-control"
                            name="country"
                            placeholder="Country*"
                            value={formik?.values?.country}
                            onChange={formik?.handleChange}
                          /> */}
                          <label>Country*</label>
                          <ReactSearchAutocomplete
                            placeholder="Select Country*"
                            items={country}
                            inputSearchString={
                              userData.country != null ? userData?.country : ""
                            }
                            onClear={(item) => handleOnClear(item, "country")}
                            onSelect={(item) => handleOnSelect(item, "country")}
                            styling={{ zIndex: 5 }} // To display it on top of the search box below
                            autoFocus
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-floating validate-input">
                          {/* <input
                            type="text"
                            className="form-control"
                            name="province"
                            placeholder="Province*"
                            value={formik?.values?.province}
                            onChange={formik?.handleChange}
                          /> */}
                          <label>Province*</label>
                          <ReactSearchAutocomplete
                            placeholder="Select Province*"
                            items={province}
                            inputSearchString={
                              userData.province != null ? userData?.province : ""
                            }
                            onClear={(item) => handleOnClear(item, "province")}
                            onSelect={(item) =>
                              handleOnSelect(item, "province")
                            }
                            styling={{ zIndex: 5 }} // To display it on top of the search box below
                            autoFocus
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-floating validate-input">
                          <label>Town / City *</label>
                          <ReactSearchAutocomplete
                            placeholder="Select Town/City*"
                            items={city}
                            inputSearchString={
                              userData.city != null ? userData?.city : ""
                            }
                            onClear={(item) => handleOnClear(item, "city")}
                            onSelect={(item) => handleOnSelect(item, "city")}
                            styling={{ zIndex: 4 }} // To display it on top of the search box below
                            autoFocus
                          />
                          {/* <input
                            type="text"
                            className="form-control"
                            name="city"
                            placeholder="Town / City *"
                            value={formik?.values?.city}
                            onChange={formik?.handleChange}
                          /> */}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-floating">
                          <input
                            type="text"
                            className="form-control"
                            name="zipcode"
                            placeholder="ZIP Code *"
                            value={formik?.values?.zipcode}
                            onChange={(event) => validateZipcode(event)}
                          />
                          <label>ZIP Code *</label>

                        </div>
                        {zipcodeValidateErr ? (
                          <p className="text-red">Please Enter Valid Zipcode</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-12" style={{ zIndex: 0 }}>
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          name="primary_contact_number"
                          placeholder="Phone *"
                          value={formik?.values?.primary_contact_number}
                          onChange={formik?.handleChange}
                        />
                        <label>Phone *</label>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          name="email_id"
                          placeholder="Email address *"
                          value={formik?.values?.email_id}
                          onChange={formik?.handleChange}
                        />
                        <label>Email address *</label>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="form-floating">
                        <textarea
                          className="form-control rounded-0"
                          name="note"
                          rows="10"
                          placeholder="Order notes (optional)"
                          onChange={formik?.handleChange}
                        ></textarea>
                        <label>Order notes (optional)</label>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="">
                        <input
                          type="checkbox"
                          name="is_shipping_address"
                          onChange={formik?.handleChange}
                        />
                        <label className="ml-2">
                          Ship to a diferent Address?
                        </label>
                      </div>
                    </div>
                  </div>

                  {/* <!-- billing details start --> */}
                  <div>
                    {formik.values.is_shipping_address ? (
                      <div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-floating">
                              <input
                                type="text"
                                className="form-control"
                                name="s_first_name"
                                placeholder="First name *"
                                onChange={formik?.handleChange}
                              />
                              <label>First name *</label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-floating">
                              <input
                                type="text"
                                className="form-control"
                                name="s_last_name"
                                placeholder="Last name *"
                                onChange={formik?.handleChange}
                              />
                              <label>Last name *</label>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control"
                              name="s_address_line_1"
                              placeholder="Street address *"
                              onChange={formik?.handleChange}
                            />
                            <label>Street address *</label>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control"
                              name="s_address_line_2"
                              placeholder="Apartment, suite, unit, etc."
                              onChange={formik?.handleChange}
                            />
                            <label>Apartment, suite, unit, etc.</label>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-floating">
                              {/* <input
                                type="text"
                                className="form-control"
                                name="s_country"
                                placeholder="Country*"
                                onChange={formik?.handleChange}
                              /> */}
                              <label>Country*</label>
                              <ReactSearchAutocomplete
                                placeholder="Select Country*"
                                items={country}
                                onSelect={(item) =>
                                  handleOnSelect(item, "s_country")
                                }
                                styling={{ zIndex: 5 }} // To display it on top of the search box below
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-floating">
                              {/* <input
                                type="text"
                                className="form-control"
                                name="s_province"
                                placeholder="Province*"
                                onChange={formik?.handleChange}
                              /> */}
                              <label>Province*</label>
                              <ReactSearchAutocomplete
                                placeholder="Select Province*"
                                items={province}
                                onSelect={(item) =>
                                  handleOnSelect(item, "s_province")
                                }
                                styling={{ zIndex: 5 }} // To display it on top of the search box below
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-floating">
                              {/* <input
                              type="text"
                              className="form-control"
                              name="s_city"
                              placeholder="Town / City *"
                              onChange={formik?.handleChange}
                            /> */}
                              <label>Town / City *</label>
                              <ReactSearchAutocomplete
                                placeholder="Select Town/City*"
                                items={city}
                                onSelect={(item) =>
                                  handleOnSelect(item, "s_city")
                                }
                                styling={{ zIndex: 4 }} // To display it on top of the search box below
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-floating">
                              {/* <input
                              type="text"
                              className="form-control"
                              name="s_zipcode"
                              placeholder="ZIP Code *"
                              onChange={formik?.handleChange}
                            /> */}
                              <label>ZIP Code *</label>
                              <ReactSearchAutocomplete
                                placeholder="Select zipcode*"
                                items={zipcode}
                                onSelect={(item) =>
                                  handleOnSelect(item, "s_zipcode")
                                }
                                styling={{ zIndex: 4 }} // To display it on top of the search box below
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control"
                              name="s_primary_contact_number"
                              placeholder="Phone *"
                              onChange={formik?.handleChange}
                            />
                            <label>Phone *</label>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control"
                              name="s_email_id"
                              placeholder="Email address *"
                              onChange={formik?.handleChange}
                            />
                            <label>Email address *</label>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="col-lg-5 col-md-12 col-sm-12 review-order">
                  <div id="order_review">
                    <div className="cart-totals">
                      {productDetails?.map((item) => {
                        return (
                          <div
                            className="cart_item d-flex justify-content-between align-items-center"
                            key={item.id}
                          >
                            <div className="d-flex align-items-center">
                              <div className="product-thumbnail ">
                                <img src={item?.image} alt="" />
                              </div>
                              <div className="product-desc">
                                {item.name}
                                <strong className="product-quantity ms-1">
                                  ×&nbsp; {item.quantity}
                                </strong>
                              </div>
                            </div>
                            <span className="price">
                              <ins className="p-0">
                                <span className="amount">
                                  <bdi>
                                    <span>₹</span>
                                    {item.main_mrp || item.mrp}
                                  </bdi>
                                </span>
                              </ins>
                            </span>
                          </div>
                        );
                      })}

                      <hr />
                      {/* <div className="cart-subtotal d-flex justify-content-between align-items-center">
                                        <p className="text-uppercase m-0">
                                            SUBTOTAL
                                        </p>
                                        <span className="price">
                                            <ins className="p-0">
                                                <span className="amount">
                                                    <bdi>
                                                        <span>$</span>19.00
                                                    </bdi>
                                                </span>
                                            </ins>
                                        </span>
                                    </div> */}
                      <hr />
                      {/* <div className="shipping pb-0 d-flex justify-content-between align-items-center">
                                    <p className="shipping-title m-0">
                                        SHIPPING
                                    </p>
                                </div>
                                <div className="cart-subtotal pb-2 mt-3 d-flex justify-content-between align-items-center">
                                    <p className="m-0">
                                        Flat Rate:
                                    </p>
                                    <span className="price">
                                        <ins className="p-0">
                                            <span className="amount">
                                                <bdi>
                                                    <span>$</span>19.00
                                                </bdi>
                                            </span>
                                        </ins>
                                    </span>
                                </div>

                                <hr /> */}
                      <div className="order-total d-flex justify-content-between align-items-center">
                        <p className="text-uppercase m-0">Discount</p>
                        <span className="price">
                          <ins className="p-0">
                            <span className="amount text-dark fw-semibold">
                              <bdi>
                                <span>₹</span>
                                {console.log(mainAmount , totalAmount)}
                                {mainAmount - totalAmount}
                              </bdi>
                            </span>
                          </ins>
                        </span>
                      </div>
                      <hr />
                      <div className="order-total d-flex justify-content-between align-items-center">
                        <p className="text-uppercase m-0">TOTAL</p>
                        <span className="price">
                          <ins className="p-0">
                            <span className="amount text-dark fw-semibold">
                              <bdi>
                                <span>₹</span>
                                {totalAmount}
                              </bdi>
                            </span>
                          </ins>
                        </span>
                      </div>
                      <div id="payment">
                        <div className="payment_methods">
                          <div className="form-check">
                            <input
                              id="false"
                              type="radio"
                              value={false}
                              name="is_online_payment"
                              onChange={formik.handleChange}
                              defaultChecked={
                                formik.values.is_online_payment === false
                              }
                              className="form-check-input ms-1"
                            />
                            <label
                              className="form-check-label ms-3"
                              for="flexRadioDefault2"
                            >
                              Cash on delivery
                            </label>
                            <div className="collapse" id="collapseExample">
                              <div className="collapse-body py-4">
                                Pay with cash upon delivery.
                              </div>
                            </div>
                          </div>

                          <div className="form-check payment-online border-0 d-flex align-items-center">
                            <input
                              className="form-check-input ms-1 mt-0"
                              id="true"
                              type="radio"
                              value={true}
                              name="is_online_payment"
                              onChange={formik.handleChange}
                              defaultChecked={
                                formik.values.is_online_payment === true
                              }
                            />
                            <label
                              className="form-check-label ms-3 d-flex align-items-center"
                              for="flexRadioDefault1"
                            >
                              Online Payment
                            </label>
                          </div>
                        </div>

                        <div className="privacy-policy-text">
                          <p>
                            Your personal data will be used to process your
                            order, support your experience throughout this
                            website, and for other purposes described in our
                            <a
                              href="#"
                              className="privacy-policy-link"
                              target="_blank"
                            >
                              privacy policy
                            </a>
                            .
                          </p>
                        </div>

                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="flexCheckDefault"
                          />
                          <label
                            className="form-check-label"
                            for="flexCheckDefault"
                          >
                            I have read and agree to the website terms and
                            conditions *
                          </label>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <button
                          type="submit"
                          className="button w-100 bg-dark"
                          disabled={isbtnDisable || zipcodeValidateErr}
                        >
                          Proceed to checkout
                        </button>
                      </div>

                      {/* <div className="col-md-12">
                            <a href="#" className="button w-100 bg-dark">
                              Proceed to checkout
                            </a>
                          </div> */}
                    </div>
                  </div>
                </div>
              </form>
            </Formik>
          </div>
        </div>
      </div>

      {/* <Modal
        isOpen={isModalOpen}
        // onAfterOpen={afterOpenModal}
        onRequestClose={() => setModal(false)}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <button onClick={() => setModal(false)}>×&nbsp;</button>
        <Login />
      </Modal> */}
    </>
  );
};

export default Checkout;
