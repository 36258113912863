import { useFormik } from "formik";
import React, { useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import { Helmet } from "react-helmet";
import "../../css/module.css";
import "./contact-us.css";
import { GoogleMap, LoadScript } from "@react-google-maps/api";
import InquiryService from "../../api-config/inquiry";
import Footer from "../../components/Footer";
import instu from "../../assets/images/instagram.png";
import utube from "../../assets/images/youtube.png";
import twitter from "../../assets/images/twitter.png";
import facebook from "../../assets/images/facebook.png";

const ContactUs = () => {
  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email_id: "",
      primary_contact_number: "",
      message: "",
      status: false,
      is_connect_on_call: false,
    },

    onSubmit: (values, { resetForm }) => {
      InquiryService.saveInquiry(values)
        .then((res) => {
          if (res.data.success) {
            resetForm();
            toast.success(res.data.message, { autoClose: 1000 });
          } else {
            toast.error(res.data.message, { autoClose: 1000 });
          }
        })
        .catch((e) => console.log(e));
    },
  });

  const containerStyle = {
    width: "100%",
    height: "500px",
  };

  const center = {
    lat: 23.0259745001,
    lng: 72.5572554334,
  };

  return (
    <>
      <Helmet>
        <title>
          Contact Sugandh for Enquiries, Feedback, and Support | Sugandh
        </title>
        <meta
          name="description"
          content="Get in touch with Scoopy for any queries, feedback or support related to our ladies undergarments collection. Fill out our contact form, email or call us, and our team will be happy to assist you with your concerns. Shop Scoopy today and experience the best in comfort and quality!"
        />
        <meta
          name="keywords"
          content="Scoopy, ladies undergarments, buy bra online"
        />
      </Helmet>
      <div className="contact-us">
        <div className="container">
          <div className="row">
            <div className="col-xl-6">
              <div className="form">
                <div className="col contact-details">
                  <h2>Get In Touch</h2>
                  <p>Let's Make Some Magic Together</p>
                </div>
                <form onSubmit={formik?.handleSubmit}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-3">
                        <div className="validate-input">
                          <input
                            className="input100 has-val"
                            placeholder="First Name"
                            type="text"
                            name="first_name"
                            value={formik?.values.first_name}
                            onChange={formik?.handleChange}
                          />
                          <span className="focus-input100"></span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <div className="validate-input">
                          <input
                            className="input100 has-val"
                            placeholder="Last Name"
                            type="text"
                            name="last_name"
                            value={formik?.values.last_name}
                            onChange={formik?.handleChange}
                          />
                          <span className="focus-input100"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 field">
                      <div className="mb-3">
                        <div>
                          <div className="validate-input">
                            <input
                              className="input100 has-val"
                              placeholder="Email Id"
                              type="text"
                              name="email_id"
                              value={formik?.values.email_id}
                              onChange={formik?.handleChange}
                            />
                            <span className="focus-input100"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 field">
                      <div className="mb-3">
                        <div>
                          <div className="validate-input">
                            <input
                              className="input100 has-val"
                              placeholder="Contact Number"
                              // type="number"
                              name="primary_contact_number"
                              value={formik?.values.primary_contact_number}
                              onChange={formik?.handleChange}
                            />
                            <span className="focus-input100"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 field">
                      <div className="mb-3">
                        <div className="validate-input">
                          <textarea
                            className="input100 has-val"
                            placeholder="Message"
                            type="text"
                            name="message"
                            value={formik?.values.message}
                            onChange={formik?.handleChange}
                          ></textarea>
                          <span className="focus-input100"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 field">
                      <div className="mb-3">
                        <div className="d-flex align-items-center">
                          <div
                            className="validate-input d-flex align-items-center"
                            style={{ gap: "5px" }}
                          >
                            <input
                              style={{ width: "22px", height: "22px" }}
                              id="checkbox"
                              type="checkbox"
                              name="is_connect_on_call"
                              value={formik?.values.is_connect_on_call}
                              onChange={formik?.handleChange}
                            />{" "}
                            <label for="is_connect_on_call">
                              Want to have a video call with team or desinger?
                            </label>
                            <span className="focus-input100"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <button type="submit" className="btn">
                    Submit
                  </button>
                </form>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="card">
                <div className="row">
                  <h5 className="info">
                    <b> Contact Info</b>
                  </h5>
                  <h5 className="data-name">Sugandh Gupta</h5>
                </div>
                <div className="card-body">
                  <div className="col-lg-3 name">
                    <h6>
                      <b>Mobile Number</b>
                    </h6>
                  </div>
                  <div className="col-9">
                    <h6 className="data name">
                      <a href="tel:+918141133133"> +91 81411 33133</a>
                    </h6>
                  </div>
                </div>
                <div className="card-body">
                  <div className="col-3 name">
                    <h6>
                      <b>Email</b>
                    </h6>
                  </div>
                  <div className="col-9">
                    <h6 className="data name email cursor-pointer">
                      <a
                        href="mailto:worldofsugandh@gmail.com"
                        target="_blank"
                        rel="noreferrer"
                      >
                        worldofsugandh@gmail.com
                      </a>
                    </h6>
                  </div>
                </div>
                <div className="card-body">
                  <div className="col-3 name">
                    <h6>
                      <b>Address</b>
                    </h6>
                  </div>
                  <div className="col-9">
                    <h6 className="data name">
                      Rudra house <br />
                      1st Floor, D-60 Kaliyabid <br />
                      Bhavnagar
                      <br />
                      Gujarat -364001
                    </h6>
                  </div>
                </div>
                {/* <div className="icon p-lg-3">
                    <a><img src={instu} alt="instagram"/></a>
                    <a><img src={utube} alt="youtube"/></a>
                    <a><img src={twitter} alt="twitter"/></a>
                    <a><img src={facebook} alt="facebook"/></a>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <section className="contact-us">
        <div className="container-fluid">
          <div className="row">
            <LoadScript googleMapsApiKey="AIzaSyBsqJFvJbRA0kP8tCezzr47UwkNWFWi6zM">
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={10}
              >
                <></>
              </GoogleMap>
            </LoadScript>
          </div>
        </div>
        <div className="container ">
          <div className="row">
            <div className="col">
              <div className="row">
                <div className="col">
                  <h1>Visit Us</h1>
                </div>
                <div className="col">
                  <h1>Get In Touch</h1>
                  <p>
                    Prism truffaut neutra blue bottle letterpress plaid
                    church-key. hello@example.com
                  </p>
                </div>
              </div>

              <form className="row g-4" onSubmit={handleSubmit}>
                <div className="col-md-6">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="First name *"
                      name="first_name"
                      onChange={handleChange}
                    />
                    <label>First name *</label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Last name *"
                      name="last_name"
                      onChange={handleChange}
                    />
                    <label>Last name *</label>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Email address *"
                      name="email_id"
                      onChange={handleChange}
                    />
                    <label>Email address *</label>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-floating">
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Phone *"
                      name="primary_contact_number"
                      onChange={handleChange}
                    />
                    <label>Contact Number *</label>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-floating">
                    <textarea
                      type="text"
                      className="form-control"
                      placeholder="Message *"
                      name="message"
                      onChange={handleChange}
                    />
                    <label>Message *</label>
                  </div>
                </div>
                <button type="submit" className="sub">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </section> */}
      {/* <Footer /> */}
    </>
  );
};

export default ContactUs;
