import ProductInfo from "../components/ProductInfo";
import { connect } from "react-redux";
import { addToCart, addToWishlist } from '../services/actions/actions';

const mapStateToProps=state=>({
    totalCartData: state?.cartItems,
    totalWishlistData: state.wishlistItems
})
const mapDispatchToProps=dispatch=>({
    addToCartHandler:data=>dispatch(addToCart(data)),
    addToWishlistHandler:data=>dispatch(addToWishlist(data))
})
export default connect(mapStateToProps, mapDispatchToProps)(ProductInfo)
// export default Home;