import React, { useState } from "react";
import LoginService from "../../api-config/login";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const Register = (props) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      email_id: "",
      password: "",
      is_web_signin: true,
      first_name: "",
      last_name: "",
      primary_contact_number: "",
      // dob: "",
      user_role_id: 1,
    },
    validateOnChange: false,
    onSubmit: (values) => {
      signupAPI(values);

      // signupAPI(values);

      // if (!beforeValidate) {
      //   handleBlur(values.email_id);
      // } else {
      //   isLogin ? loginAPI(values) : signupAPI(values);
      // }
    },
  });

  const signupAPI = (values) => {
    const formData = new FormData();
    formData.append("email_id", values.email_id);
    formData.append("first_name", values.first_name);
    formData.append("last_name", values.last_name);
    formData.append("primary_contact_number", values.primary_contact_number);
    formData.append("dob", values.dob);
    formData.append("password", values.password);
    formData.append("is_web_signin", values.is_web_signin);
    formData.append("user_role_id", values.user_role_id);

    // Make a POST request to your backend signup endpoint
    LoginService.userSignUp(formData)
      .then((res) => {
        // Handle successful signup
        // For example, you can redirect the user to the login page
        toast.success(res.data.message);
        console.log("sign up success");
        navigate("/Login");
        // setTabIndex(0); // Redirect to the login tab after signup
      })
      // .catch((error) => {
      //   // Handle signup error
      //   toast.error("Signup failed. Please try again.");
      //   console.error("Signup failed:", error);
      // });
      .catch((error) => {
        // Handle signup error
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          // If the error response contains error details
          const errorObject = error.response.data.error;
          let errorMessage = "Signup failed:";

          // Iterate over each error and concatenate error messages
          Object.keys(errorObject).forEach((key) => {
            errorMessage += ` ${errorObject[key].message}`;
          });

          toast.error(errorMessage);
          console.error(errorMessage);
        } else {
          // If the error response does not contain detailed error information
          toast.error("Signup failed. Please try again.");
          console.error("Signup failed:", error.message);
        }
      });

    // LoginService.userSignUp(formData)
    //   .then((res) => {
    //     window.location.reload();
    //     localStorage.setItem(
    //       "auth_key",
    //       JSON.stringify(res.data.Authorization),
    //     );
    //     localStorage.setItem("user_info", JSON.stringify(res.data.data));
    //     setIsOpen(false);
    //     toast.success(res.data.message);
    //   })
    //   .catch((e) => console.log(e));
  };

  const handleLogin = () => {
    navigate("/Login");
  };

  return (
    <div>
      {/* sign up form  */}

      {/* <TabPanel> */}
      <div className="wrap-login100">
        <div className="login-tab">
          <form onSubmit={formik.handleSubmit}>
            <div className="mb-3">
              <div className="d-flex">
                <div className="wrap-input100 validate-input mr-2">
                  <input
                    className="input100 has-val"
                    placeholder="First Name"
                    type="text"
                    name="first_name"
                    value={formik.values.first_name}
                    onChange={formik.handleChange}
                  />
                </div>

                <div className="wrap-input100 validate-input">
                  <input
                    className="input100 has-val"
                    placeholder="Last Name"
                    type="text"
                    name="last_name"
                    value={formik.values.last_name}
                    onChange={formik.handleChange}
                  />
                </div>
              </div>
            </div>

            <div className="mb-3">
              <div className="wrap-input100 validate-input ">
                <input
                  className="input100 has-val"
                  placeholder="Phone No."
                  type="text"
                  name="primary_contact_number"
                  value={formik.values.primary_contact_number}
                  onChange={formik.handleChange}
                />
              </div>
            </div>

            <div className="mb-3">
              <div className="wrap-input100 validate-input">
                <input
                  className="input100 has-val"
                  placeholder="Email"
                  type="text"
                  autoComplete="Off"
                  name="email_id"
                  value={formik.values.email_id}
                  onChange={formik.handleChange}
                />
              </div>
            </div>

            <div className="mb-3">
              <div
                className="wrap-input100 validate-input"
                data-validate="Enter password"
              >
                <span className="show-eye">
                  {showPassword ? (
                    <FaEyeSlash onClick={() => setShowPassword(false)} />
                  ) : (
                    <FaEye onClick={() => setShowPassword(true)} />
                  )}
                </span>
                <input
                  type={showPassword ? "text" : "password"}
                  className="input100 has-val"
                  placeholder="Password"
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                />
              </div>
            </div>

            <div className="container-login100-form-btn">
              <div className="wrap-login100-form-btn">
                <div className="login100-form-bgbtn"></div>
                <button
                  className="login100-form-btn"
                  type="submit"
                  disabled={
                    !formik.values.first_name ||
                    !formik.values.last_name ||
                    !formik.values.primary_contact_number ||
                    !formik.values.email_id ||
                    !formik.values.password
                  }
                >
                  Sign Up
                </button>
              </div>
            </div>
            <div className="account-text">
              Already have an account?{" "}
              <span onClick={() => handleLogin()}>Login</span>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Register;
