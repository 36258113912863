import http from "../environment";

const getOrder = (userId) => {
  return http.get(`invoice/get?user_id=${userId}`);
};

const getById = (orderID) => {
  return http.get(`invoice/get?id=${orderID}`);
};

const getDataByUserId = (userId) => {
  return http.post(`invoice/getDataByUserId?id=${userId}`);
};

const returnOrder = (data) => {
  return http.post(`invoice/shipping/details/refund`,data);
};

const cancelOrder = (data) => {
  return http.post(`invoice/shipping/details/cancel`,data);
};

const OrderService = {
  getOrder,
  getById,
  getDataByUserId,
  returnOrder,
  cancelOrder
};
export default OrderService;
