import http from "../environment";

const getByUserId = (id) => {
  return http.get(`/cart/get?user_id=${id}`);
};

const addToCart = (data) => {
  return http.post(`/cart/save`, data);
}

const orderTransaction = (data) => {
  return http.post(`order/transaction/save`, data);
}

const CartService = {
  getByUserId,
  addToCart,
  orderTransaction
};

export default CartService;
