import http from "../environment";

const getAll = () => {
  return http.get(`/inquiry/get`);
};

const saveInquiry = (requestData) => {
  return http.post(`inquiry/save`, requestData);
}


const InquiryService = {
  getAll,
  saveInquiry,
};

export default InquiryService;
