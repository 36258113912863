import './sale.css';

const Sale = () => {
    return (
        <div className="sale container">
            <div className="row mb-4">
                        <h1 className="display-6 text-dark mb-4 text-center page-title">
                            Sale
                        </h1>
                    </div>
            <div className="empty-wishlist">
                                    <h5>No product in Sale</h5>
                                    <p>Opps... You are late, there is no product in sale now.</p>
                                    <br/>

                                    <a href="/the-shop" className="cool-link">Continue Shopping</a>
                                </div>
        </div>
    )
}
export default Sale;